import React from "react";
import appService from "../../../services/app.service";
import { DetailsOverview } from "../base/DetailsOverview";
import { useTranslation } from "react-i18next";
import { NoAdminAlert } from "../../NoAdminAlert"

export const ConsultantDepartmentOverview: React.FunctionComponent<{ isAdmin: boolean }> = (props) => {
    const detailsRedirection = "/config/consultantDepartments/details";
    const {t} = useTranslation();
    const { isAdmin } = props;

    const title = t("client:headers:tabConsDepDetails");

    if (isAdmin !== true) { return <NoAdminAlert /> }
    
    return (
        <DetailsOverview detailsRedirection={detailsRedirection} getItems={appService.getConsultantDepartments} entityType="consultantdepartments" title={title}></DetailsOverview>
    );

};