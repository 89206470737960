import React, { useCallback } from 'react';
import { useState } from 'react';
import { IClient } from '../../core/cm.types';
import { TableService } from '../../services/table.service';
import { DocumentsAllClients } from '../views/DocumentsAllClients';
import { Documents } from "./Documents"

export const DocumentsDefaultContainer: React.FunctionComponent<{ clients: IClient[], tableService: TableService, clientDriveId: string, mailMergeDriveId: string, templateDriveId: string, exportAllTable(rows) }> = (props) => {
    const { clientDriveId, mailMergeDriveId, templateDriveId, tableService, clients, exportAllTable } = props;
    const [selectedRows, setSelectedRows] = useState([]);
    const title = "Dokumente"

    return (
        <Documents title={title} clientDriveId={clientDriveId} selectedRows={selectedRows} mailMergeDriveId={mailMergeDriveId} templateDriveId={templateDriveId} setSelectedRows={setSelectedRows}  >
            <DocumentsAllClients setSelectedRows={setSelectedRows} selectedRows={selectedRows} clients={clients} tableService={tableService} exportAllTable={exportAllTable} />
        </Documents>
    )
};
