import React, { useMemo } from 'react';
import { IClient } from '../../core/cm.types';
import 'moment/locale/de';
import { TableService } from '../../services/table.service';
import { Table } from '../table/Table';
import { TableSelectionCheckbox } from '../table/TableSelectionCheckbox';

export const PresenceSelectionTable: React.FunctionComponent<{ clients: IClient[], tableService: TableService, addedClients: IClient[], selectedRows: IClient[], setSelectedRows: any, push: any, setAddedClients: any, exportAllTable(rows) }> = (props) => {
    const { tableService, clients, push, setAddedClients, addedClients, selectedRows, setSelectedRows, exportAllTable } = props;

    const columns = useMemo(() => [
        tableService.getKeyColumn(),
        tableService.getFirstNameColumn(),
        tableService.getLastNameColumn(),
        tableService.getInterviewDateColumn(),
        tableService.getExitDateColumn(),
        tableService.getAmmStatusColumn(),
        tableService.getQualificationColumn(),
        tableService.getEntryLocationColumn(),
        tableService.getEntryAdditionalinfoColumn(),
        tableService.getModifiedDateColumn()
    ], [tableService]);

    const sortBy = [
        { id: 'modified', desc: true }
    ];

    const newSuffix = [
        {
            id: 'selection',
            Header: ({ getToggleAllPageRowsSelectedProps }) => (
                <TableSelectionCheckbox {...getToggleAllPageRowsSelectedProps()} />
            ),
            Cell: ({ row }) => (
                <TableSelectionCheckbox {...row.getToggleRowSelectedProps()} />
            ),
        }] as any;

    return (
        <React.Fragment>
            <button
                type="button"
                className={`button is-link is-small is-fullwidth`}
                onClick={(e) => {
                    e.preventDefault();
                    selectedRows.map((client) => {
                        push({ customerId: client.key, isDirty: true })
                    })
                    const added = [...selectedRows, ...addedClients];
                    setAddedClients(added)
                }}
            >
                Hinzufügen
            </button>
            <Table data={clients} columns={columns} columnssuffix={newSuffix} sortBy={sortBy} setSelectedRows={setSelectedRows} exportAllTable={exportAllTable} />
            <button
                type="button"
                className={`button is-link is-small is-fullwidth`}
                onClick={(e) => {
                    e.preventDefault();
                    selectedRows.map((client) => {
                        push({ customerId: client.key, isDirty: true })
                    })
                    setAddedClients([...selectedRows, ...addedClients])
                }}
            >
                Hinzufügen
            </button>
        </React.Fragment>
    );
};