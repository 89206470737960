import React from "react";
import appService from "../services/app.service";

export const EnsureLocation: React.FunctionComponent<{ location: string }> = (props) => {
    const { location } = props;

    if (location !== undefined) {
        appService.setDefaultLocation(location);
    }

    return (
        <React.Fragment>
            {props.children}
        </React.Fragment>
    );
};
