import React, { useEffect, useState } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";
import "./RichTextBox.css"

import { extendFieldProps } from "./Input";
import { FieldFooter } from "./FieldFooter";
import { FieldLabel } from "./FieldLabel";

export const RichTextBox: React.FunctionComponent<any> = ({
  field,
  form,
  ...props
}) => {
  extendFieldProps(props, field, form);

  const {
    options,
    errorMessage,
    help,
    helpBox,
    label,
    showRequired,
    selectedValue,
    checked,
    value,
    setFieldValue,
    refreshvalue,
    trackState,
    ...componentOptions
  } = props;


  const hasAddons = helpBox !== undefined;
  const mainClassName = hasAddons ? "field has-addons" : "field";
  const resetKeyValue = refreshvalue ?? "myKey";

  const prepareDraft = value => {
    const draft = htmlToDraft(value);
    const contentState = ContentState.createFromBlockArray(draft.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  };

  const [editorState, setEditorState] = useState(value ? prepareDraft(value) : EditorState.createEmpty());
  const [refreshValue, setRefreshValue] = useState(resetKeyValue);

  if (refreshValue !== resetKeyValue) {
    setRefreshValue(resetKeyValue)
    setEditorState(value ? prepareDraft(value) : EditorState.createEmpty())
  }

  const onEditorStateChange = editorState => {
    /*
    const forFormik = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    
    if (setFieldValue !== undefined)
        setFieldValue(forFormik)
    */

    setEditorState(editorState);
  };

  useEffect(() => {
    if (trackState !== undefined) {
      trackState(editorState);
    }
  }, [editorState]);

  return (
    <div className={mainClassName}>
      <FieldLabel label={label} showRequired={showRequired}></FieldLabel>
      <div className="control">
        <Editor
          wrapperClassName={"elrichwrapper"}
          editorState={editorState}
          toolbar={{
            options: ['inline', 'list', 'link', 'emoji', 'image', 'remove'],
            inline: {
              inDropdown: false,
              options: ['bold', 'italic', 'underline'],
            },
            list: {
              inDropdown: true
            },
            link: {
              inDropdown: true
            }
          }}
          toolbarClassName={"elrichtoolbar"}
          editorClassName={"elricheditor"}
          onEditorStateChange={onEditorStateChange}
        />
        <FieldFooter help={help} errorMessage={errorMessage}></FieldFooter>
      </div>
    </div>
  );
};