import React from "react";
import { IClient } from "../core/cm.types"
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { LocationAwareButton } from "./LocationAwareButton";
import { InProgramPictures } from "./views/InProgramPictures"
import { TableService } from "../services/table.service"

export const InProgramPicturesContainer: React.FunctionComponent<{ clients: IClient[], tableService: TableService, onClientDelete: any, exportAllTable(rows) }> = (props) => {
    const { t } = useTranslation();
    const { clients, tableService, onClientDelete, exportAllTable } = props;

    const suffix = useMemo(() => [
        {
            id: 'button',
            Cell: ({ row }) => (
                <LocationAwareButton className="button is-small" relativeLocation={`/details/${row.original.key}`}>
                    <span className="icon is-small">
                        <i className={`fas fa-edit`} />
                    </span>
                </LocationAwareButton>
            ),
        }], []);

    const postfix = useMemo(() => [
        {
            id: 'deletebutton',
            Cell: ({ row }) => (
                <button
                    className="button is-link is-danger is-small"
                    title={t("client:common:deleteButton")}
                    onClick={(e) => {
                        e.preventDefault();
                        onClientDelete(row.original.key);
                    }}
                >
                    <span className="icon is-small">
                        <i className={`fas fa-trash`} />
                    </span>
                </button>
            ),
        }], []);

    return (
        <div className="section defaultSection">
            <h1 className="title is-3">{t("client:headers:tabInProgrammPictures")}</h1>
            <div className="buttons is-right">
                <LocationAwareButton title={t("client:newClient")} className="button is-small" relativeLocation="/details" />
            </div>
            <InProgramPictures postfix={postfix} suffix={suffix} clients={clients} tableService={tableService} exportAllTable={exportAllTable} />
        </div>
    )
};
