import React, { useRef, useState } from "react";
import { IActivity, IEmployee } from "../../../core/cm.types";
import appService from "../../../services/app.service";
import { ActivityDetail } from "./ActivityDetail"
import moment from "moment";
import { useTranslation } from "react-i18next";
import { SmallLoading } from "../../SmallLoading";
import "./Activities.css"
import renderHTML from "react-render-html";
import draftToHtml from "draftjs-to-html";
import { convertToRaw } from "draft-js";

export const Activities: React.FunctionComponent<{ clientId: string | undefined, employee: IEmployee[] }> = (props) => {
    const { t } = useTranslation();
    const { clientId, employee } = props;
    const [descriptionState, setDescriptionState] = useState();
    const isEnabled = clientId !== "" && clientId !== undefined;
    const [activity, setCurrentActivity] = React.useState<{
        isActive: boolean
        activityId: string | undefined
    }>({
        isActive: false,
        activityId: undefined
    });
    const [data, setData] = React.useState<{
        isLoading: boolean;
        activities: IActivity[];
    }>({
        isLoading: true,
        activities: [],
    });

    React.useEffect(() => {
        if (clientId !== undefined)
            fetchData();
    }, [clientId]);

    if (isEnabled === false) {
        return <React.Fragment>
            {t("client:tabActivities:notEnabled")}
        </React.Fragment>
    }

    const fetchData = async () => {
        const activities = await appService.getActivities(clientId);
        setData({
            isLoading: false,
            activities: activities,
        });
    };

    const onDelete = async (id: string) => {
        if (confirm(t("client:common:deleteMessage"))) {
            await appService.delEntityByCustomerId(clientId, "activities", id);
            fetchData();
        }
    }

    const onSubmit = (
        activity: IActivity
    ) => {
        const submit = async () => {
            if (activity !== undefined && activity !== null) {
                activity.description = draftToHtml(
                    convertToRaw((descriptionState as any)?.getCurrentContent())
                );
                appService.saveActivity(clientId, activity).then((modulesResults) => {
                    setCurrentActivity({ isActive: false, activityId: undefined });
                    fetchData();
                }).catch(() => {
                    alert(t("client:common:errorOnSave"));
                });
            }
        };

        submit();
    };

    if (data.isLoading) {
        return <SmallLoading isLoading={data.isLoading}></SmallLoading>
    }

    const createActivity = () => {
        setCurrentActivity({ isActive: true, activityId: undefined });
    }

    const getUserName = (usermail: string) => {
        const currentUser = employee.filter(p => p.username?.toLowerCase() === usermail?.toLocaleLowerCase());
        if (currentUser !== undefined) {
            const name = currentUser[0];
            return `${name.name} ${name.lastName}`
        }
        return usermail;
    }

    return (
        <React.Fragment>
            <ActivityDetail trackState={setDescriptionState} isActive={activity.isActive} clientId={clientId} activityId={activity.activityId}
                onSubmit={onSubmit} onCloseClick={() => setCurrentActivity({
                    isActive: false,
                    activityId: undefined
                })} />

            <div className="columns activitiesbar">
                <div className="column activitiesbar">
                    <button className="button is-small is-pulled-right" type='button' onClick={(e) => {
                        e.preventDefault();
                        createActivity();
                    }}>Neu
                    </button>
                </div>
            </div>
            <div className="table-container table-activities">
                <table className="table is-fullwidth">
                    <tbody>
                        {data.activities.length === 0 ? (
                            <tr>
                                <td colSpan={2}>{t("client:tabActivities:noData")}</td>
                            </tr>
                        ) : (
                            <React.Fragment>
                                {data.activities.map((activity, i) => (
                                    <React.Fragment key={activity.key}>
                                        <tr className={i % 2 === 0 ? "even" : "odd"}>
                                            <td>
                                                <a href="#" onClick={(e) => {
                                                    e.preventDefault();
                                                    setCurrentActivity({ isActive: true, activityId: activity.key })
                                                }}>{moment(activity.activityDate).format(
                                                    "DD.MM.yyyy HH:mm"
                                                )} | <b>{activity.subject}</b> | {getUserName(activity.createdBy)}</a>
                                            </td>
                                            <td className="actions">
                                                <button
                                                    className="button is-link is-danger is-small"
                                                    title={t("client:common:deleteButton")}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        onDelete(activity.key);
                                                    }}
                                                >
                                                    <span className="icon is-small">
                                                        <i className={`fas fa-trash`}></i>
                                                    </span>
                                                </button>
                                            </td>
                                        </tr>
                                        <tr className={i % 2 === 0 ? "even" : "odd"}>
                                            <td colSpan={2} className="bullets">{activity?.description !== undefined && (renderHTML(activity?.description))}</td>

                                        </tr>
                                    </React.Fragment>
                                ))}
                            </React.Fragment>
                        )}
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    );
};
