import React from 'react';

export const FieldLabel: React.FunctionComponent<{
  label?: React.ReactNode;
  showRequired?: boolean;
}> = (props) => {
  return (
    <React.Fragment>
      {props.label !== undefined && (
        <label className="label">
          <InternalFieldLabel
            label={props.label}
            showRequired={props.showRequired}
          ></InternalFieldLabel>
        </label>
      )}
    </React.Fragment>
  );
};

export const InternalFieldLabel: React.FunctionComponent<{
  label?: React.ReactNode;
  showRequired?: boolean;
}> = (props) => {
  return (
    <React.Fragment>
      {props.label !== undefined && (
        <React.Fragment>
          {props.label}
          {props.showRequired === true && (
            <React.Fragment>
              {" "}
              <b className="has-text-danger">*</b>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
