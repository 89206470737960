import { FieldArray, Formik, FormikHelpers } from 'formik';
import React, { useMemo, useState } from 'react';
import { useTranslation } from "react-i18next";
import { IClient, IMonthPresence } from '../../core/cm.types';
import appService from "../../services/app.service";
import { Select } from '../fields/Select';
import moment from 'moment';
import 'moment/locale/de';
import { Loading } from '../Loading';
import "./Presence.css"
import { TableService } from '../../services/table.service';
import { PresenceSelectionTable } from "./PresenceSelectionTable"
import { PresenceInput } from "./PresenceInput"

export interface IDayDefinition {
    isFreeDay: boolean,
    title: number
}

export const Presence: React.FunctionComponent<{ clients: IClient[], tableService: TableService, exportAllTable(rows) }> = (props) => {
    const currentDate = new Date();

    const { t } = useTranslation();
    const { tableService, exportAllTable, clients } = props;
    const [currentMonth, setCurrentMonth] = React.useState<number>(currentDate.getMonth());
    const [currentYear, setCurrentYear] = React.useState<number>(currentDate.getFullYear());
    const [selectedRows, setSelectedRows] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [presence, setPresence] = useState<IMonthPresence>({});
    const [days, setDays] = useState<IDayDefinition[]>([]);
    const [addedClients, setAddedClients] = useState<IClient[]>([]);

    const onSubmit = (
        values: Readonly<IMonthPresence | undefined>,
        formikHelpers: FormikHelpers<Readonly<IMonthPresence>>
    ) => {
        const input = values;

        const submit = async () => {
            if (input !== undefined && input !== null) {
                var valuesToManipulate = { ...input };
                valuesToManipulate.year = currentYear;
                valuesToManipulate.month = currentMonth;
                appService.savePresence(valuesToManipulate).then(async (modulesResults) => {
                    await fetchData();
                    //setPresence(modulesResults)
                }).catch(() => {
                    alert(t("client:common:errorOnSave"));
                });
            }
        };

        submit();
    };

    const fetchData = async () => {
        setIsLoading(true);
        setPresence({});
        setDays([])
        setSelectedRows([])

        try {
            const freedaysResults = await appService.getFreedays();
            const freedays = freedaysResults.map(day => {
                const free = new Date(day.day);
                return new Date(free.getFullYear(), free.getMonth(), free.getDate());
            })
            const m = moment(`${currentYear}-${currentMonth + 1}}`, "YYYY-MM");
            const daysInMonth = m.daysInMonth()
            let days: IDayDefinition[] = [];
            for (let i = 1; i <= daysInMonth; i++) {
                var newDate = new Date(currentYear, currentMonth, i)
                days.push({
                    isFreeDay: newDate.getDay() == 0 || newDate.getDay() == 6 || freedays.some(p => p.getTime() === newDate.getTime()),
                    title: i
                })
            }
            var presence = await appService.getPresence(currentYear, currentMonth);
            let clientsToAdd = [];
            presence.details.forEach(detail => {
                const customer = clients.find(a => a.key === detail.customerId);
                if (customer !== undefined) {
                    detail.customerName = `${customer.lastName} ${customer.firstName}`;
                    clientsToAdd.push(customer);
                }
            });
            presence.details.sort((a, b) => {
                if (a.customerName < b.customerName) {
                    return -1;
                }
                if (a.customerName > b.customerName) {
                    return 1;
                }
                return 0;
            })

            setPresence(presence);
            setDays(days)
            setSelectedRows(clientsToAdd)
            setAddedClients(clientsToAdd)
        }
        catch {
            setPresence({});
            setDays([])
            setSelectedRows([])
        }
        setIsLoading(false);
    };

    React.useEffect(() => {
        fetchData()
    }, [currentYear, currentMonth, clients.length]);

    let years = [];
    for (let currentYear = 2018; currentYear <= currentDate.getFullYear(); currentYear++)
        years.push(currentYear);
    const yearsOption = [...years.map((item) => {
        return {
            key: item,
            text: item
        };
    })];

    moment.locale('de');
    const monthNames = moment.months()
    let monthOptions = [...monthNames.map((month, index) => {
        return {
            key: index,
            text: month
        };
    })]

    const missingClients = useMemo(() => clients.filter(a => !addedClients.map(b => b.key).includes(a.key)), [clients.length, addedClients.length]);

    if (isLoading === true)
        return <Loading isLoading={true}></Loading>

    return (
        <div className="section defaultSection">
            <Formik
                initialValues={presence}
                onSubmit={onSubmit}
                validateOnMount={true}
                validator={() => ({})}
                enableReinitialize
            >
                {({
                    values,
                    handleSubmit,
                    isSubmitting,
                    isValid,
                    dirty,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="columns">
                            <div className="column">
                                <h1 className="title is-3">{t("client:tabPresence:getAMM")}</h1>
                            </div>

                            <div className="column button-header">
                                <button
                                    type="submit"
                                    className={`button is-link is-pulled-right is-small backAndSaveButton ${isSubmitting ? t("client:common:isLoading") : ""
                                        }`}
                                    disabled={isSubmitting || !dirty || !isValid}
                                >
                                    {t("client:common:saveButton")}
                                </button>
                            </div>
                        </div>
                        <div className="field is-horizontal">
                            <Select options={yearsOption} value={currentYear} onChange={(e) => setCurrentYear(parseInt(e.target.value))}></Select>
                            <Select options={monthOptions} value={currentMonth} onChange={(e) => setCurrentMonth(parseInt(e.target.value))}></Select>
                        </div>
                        <FieldArray
                            name="details"
                            render={({ push }) => (
                                <React.Fragment>
                                    <button
                                        type="button"
                                        className={`button is-link is-small is-fullwidth`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            const today = (new Date()).getTime();
                                            const primaryInterviewStatusId = tableService.getPrimaryInterviewStatusId();
                                            const data = missingClients.filter((client) => {
                                                if (client?.entry?.date === undefined || client?.exit?.date === undefined)
                                                    return false;
                                        
                                                const entryDate = Date.parse(client.entry.date);
                                                const exitDate = Date.parse(client.exit.date);
                                        
                                                return client.interview?.statusId === primaryInterviewStatusId &&
                                                    entryDate <= today &&
                                                    exitDate >= today;
                                            });
                                            data.map((client) => {
                                                push({ customerId: client.key, isDirty: true })
                                            })
                                            const added = [...addedClients, ...data];
                                            setAddedClients(added)
                                        }}
                                    >
                                        Alle fehlenden Klienten "Im Program" hinzufügen
                                    </button>
                                    <br />
                                    <PresenceInput clients={clients} details={values.details} days={days} isDirty={dirty} isSubmitting={isSubmitting} isValid={isValid}></PresenceInput>
                                    <br />
                                    <br />
                                    <br />
                                    <hr />
                                    <br />
                                    <br />
                                    <PresenceSelectionTable clients={missingClients} tableService={tableService} selectedRows={selectedRows} setSelectedRows={setSelectedRows} setAddedClients={setAddedClients} addedClients={addedClients} push={push} exportAllTable={exportAllTable}></PresenceSelectionTable>
                                </React.Fragment >
                            )}
                        />
                    </form>
                )}
            </Formik>
        </div>
    );
};