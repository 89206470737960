import React from "react";
import { Route } from "react-router-dom";
import { IOfficeLocation } from "../core/cm.types";

export const LocationList: React.FunctionComponent<{ currentLocation: IOfficeLocation, locations: IOfficeLocation[], changeLocation: (newLocation: string) => void }> = (props) => {
    const { locations, currentLocation, changeLocation } = props;

    const selectedLocation = currentLocation !== undefined ? currentLocation.title : locations[0]?.title

    return (
        <div className="navbar-item has-dropdown is-hoverable">
            <a className="navbar-link">
                {selectedLocation}
            </a>
            <div className="navbar-dropdown">
                {locations.map((loc, index) => (
                    <Route key={index} render={({ history }) => (
                        <a className={`navbar-item ${currentLocation?.path === loc.path ? "is-active" : ""}`} onClick={(e) => {
                            e.preventDefault(); changeLocation(loc.path);
                            history.push(`/${loc.path}`);
                        }}>{loc.title}</a>
                    )} />
                ))}

            </div>
        </div>
    );
};
