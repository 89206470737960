import React from 'react';
import { extendFieldProps } from "./Input";
import { FieldFooter } from "./FieldFooter";
import { InternalFieldLabel } from "./FieldLabel";

export const Checkbox: React.FunctionComponent<any> = ({
  field,
  form,
  ...props
}) => {
  extendFieldProps(props, field, form);

  let {
    options,
    errorMessage,
    help,
    label,
    showRequired,
    selectedValue,
    checked,
    ...componentOptions
  } = props;

  return (
    <div className="field">
      <div className="control">
        <label className="checkbox">
          <input
            type="checkbox"
            className={`${errorMessage !== undefined ? "is-danger" : ""}`}
            {...field}
            {...componentOptions}
            checked={selectedValue === "true" || selectedValue === true}
          />
          <span className="checkboxText">
            <InternalFieldLabel
              label={label}
              showRequired={showRequired}
            ></InternalFieldLabel>
          </span>
        </label>
        <FieldFooter help={help} errorMessage={errorMessage}></FieldFooter>
      </div>
    </div>
  );
};
