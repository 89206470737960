import moment from 'moment';
import React from 'react'

export const ColumnSelectFilter: React.FunctionComponent<{ column: { filterValue, setFilter, preFilteredRows, id  }}> = (props) => {
    const { column } = props;
    const { filterValue, setFilter, preFilteredRows, id  } = column;

    const options = React.useMemo(() => {
        const options = new Set()
        preFilteredRows.forEach(row => {
            options.add(row.values[id])
        })
        let sorted = Array.from(options);
        const dateFormat = "DD.MM.yyyy";

        if (sorted.length > 0) {
            var valueA = moment(sorted[0], dateFormat)
            if (valueA.isValid()) {
                sorted = sorted.sort((a, b) => {
                    var valueA = moment(a, dateFormat)
                    var valueB = moment(b, dateFormat)
            
                    if (valueA.isAfter(valueB))
                        return -1;
                    else if (valueB.isAfter(valueA))
                        return 1;
                    else
                        return 0;
                });
            }
            else {
                sorted = sorted.sort();
            }
        }

        return [...(sorted as any).values()]
    }, [id, preFilteredRows])

    return (
        <div className="field">
            <div className="select is-small is-fullwidth">
                <select
                    value={filterValue}
                    onChange={e => {
                        setFilter(e.target.value)
                    }}
                >
                    <option value="">*</option>
                    {options.map((option, i) => (
                        <option key={i} value={option as string}>
                        {option}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    )
}