import React, { useState } from "react";
import { IApplication, IEmployee } from "../../../core/cm.types";
import appService from "../../../services/app.service";
import { ApplicationDetail } from "./ApplicationDetail"
import moment from "moment";
import { useTranslation } from "react-i18next";
import { SmallLoading } from "../../SmallLoading";
import "./Applications.css"
import renderHTML from "react-render-html";
import draftToHtml from "draftjs-to-html";
import { convertToRaw } from "draft-js";

export const Applications: React.FunctionComponent<{ clientId: string | undefined, employee: IEmployee[] }> = (props) => {
    const { t } = useTranslation();
    const { clientId, employee } = props;
    const isEnabled = clientId !== "" && clientId !== undefined;
    const [descriptionState, setDescriptionState] = useState();
    const [application, setCurrentApplication] = React.useState<{
        isActive: boolean
        applicationId: string | undefined
    }>({
        isActive: false,
        applicationId: undefined
    });
    const [data, setData] = React.useState<{
        isLoading: boolean;
        applications: IApplication[];
    }>({
        isLoading: true,
        applications: [],
    });

    if (isEnabled === false) {
        return <React.Fragment>
            {t("client:tabActivities:notEnabled")}
        </React.Fragment>
    }

    const fetchData = async () => {
        const applications = await appService.getApplications(clientId);
        setData({
            isLoading: false,
            applications: applications,
        });
    };

    const onDelete = async (id: string) => {
        if (confirm(t("client:common:deleteMessage"))) {
            await appService.delEntityByCustomerId(clientId, "applications", id);
            fetchData();
        }
    }

    const getUserName = (usermail: string) => {
        const currentUser = employee.filter(p => p.username?.toLowerCase() === usermail?.toLocaleLowerCase());
        if (currentUser !== undefined) {
            const name = currentUser[0];
            return `${name.name} ${name.lastName}`
        }
        return usermail;
    }

    React.useEffect(() => {
        if (clientId !== undefined)
            fetchData();
    }, [clientId]);

    const onSubmit = (
        application: IApplication
    ) => {
        const submit = async () => {
            if (application !== undefined && application !== null) {
                application.description = draftToHtml(
                    convertToRaw((descriptionState as any)?.getCurrentContent())
                );
                appService.saveApplication(clientId, application).then((modulesResults) => {
                    setCurrentApplication({ isActive: false, applicationId: undefined });
                    fetchData();
                }).catch(() => {
                    alert(t("client:common:errorOnSave"));
                });
            }
        };

        submit();
    };

    if (data.isLoading) {
        return <SmallLoading isLoading={data.isLoading}></SmallLoading>
    }

    const createApplication = () => {
        setCurrentApplication({ isActive: true, applicationId: undefined });
    }

    return (
        <React.Fragment>
            <ApplicationDetail trackState={setDescriptionState} isActive={application.isActive} clientId={clientId} applicationId={application.applicationId}
                onSubmit={onSubmit} onCloseClick={() => setCurrentApplication({
                    isActive: false,
                    applicationId: undefined
                })} />

            <div className="columns activitiesbar">
                <div className="column activitiesbar">
                    <button className="button is-small is-pulled-right" type='button' onClick={(e) => {
                        e.preventDefault();
                        createApplication();
                    }}>Neu
                    </button>
                </div>
            </div>
            <div className="table-container table-applications">
                <table className="table is-fullwidth">
                    <tbody>
                        {data.applications.length === 0 ? (
                            <tr>
                                <td colSpan={2}>Keine Programmeinsätze eingetragen</td>
                            </tr>
                        ) : (
                            <React.Fragment>
                                {data.applications.map((app, i) => (
                                    <React.Fragment key={app.key}>
                                        <tr className={i % 2 === 0 ? "even" : "odd"}>
                                            <td>
                                                <a href="#" onClick={(e) => {
                                                    e.preventDefault();
                                                    setCurrentApplication({ isActive: true, applicationId: app.key })
                                                }}>{moment(app.applicationDate).format(
                                                    "DD.MM.yyyy HH:mm"
                                                )} | {app.subject} | {getUserName(app.createdBy)}</a>
                                            </td>
                                            <td className="actions">
                                                <button
                                                    className="button is-link is-danger is-small"
                                                    title={t("client:common:deleteButton")}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        onDelete(app.key);
                                                    }}
                                                >
                                                    <span className="icon is-small">
                                                        <i className={`fas fa-trash`}></i>
                                                    </span>
                                                </button>
                                            </td>
                                        </tr>
                                        <tr className={i % 2 === 0 ? "even" : "odd"}>
                                            <td colSpan={2}>{renderHTML(app.description)}</td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </React.Fragment>
                        )}
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    );
};
