import { ColumnSelectFilter } from "../components/table/ColumnSelectFilter"
import moment from "moment";
import { IEmployee, IConsultant, IClient, IBaseDetailsItem, ILocationInfo, IActivity } from "../core/cm.types";
import renderHTML from "react-render-html";

export class TableService {
    t: any;
    consultants: IConsultant[];
    employee: IEmployee[];
    qualifications: IBaseDetailsItem[];
    additionalInfo: IBaseDetailsItem[];
    locationInfo: ILocationInfo[];
    interviewState: IBaseDetailsItem[];
    qualificaitons: IBaseDetailsItem[];
    alvStatus: IBaseDetailsItem[];
    etlsolutions: IBaseDetailsItem[];
    primaryInterviewStatusId: string | undefined;
    dateFormat: string = "DD.MM.yyyy"

    //constructor 
    constructor(t: any, consultants: IConsultant[], employee: IEmployee[], qualifications: IBaseDetailsItem[], additionalInfo: IBaseDetailsItem[], locationInfo: ILocationInfo[], interviewState: IBaseDetailsItem[], qualificaitons: IBaseDetailsItem[], alvStatus: IBaseDetailsItem[], etlsolutions: IBaseDetailsItem[]) {
        this.t = t;
        this.consultants = consultants;
        this.employee = employee;
        this.qualifications = qualifications;
        this.additionalInfo = additionalInfo;
        this.locationInfo = locationInfo;
        this.interviewState = interviewState;
        this.qualificaitons = qualifications;
        this.alvStatus = alvStatus;
        this.etlsolutions = etlsolutions;
        const primary = this.interviewState.filter(p => p.title === "Einladen bei nächster Möglichkeit");
        if (primary.length > 0) {
            this.primaryInterviewStatusId = primary[0].key;
        }
    }

    sortDate(rowA: any, rowB: any, columnId: string, desc: boolean): number {
        if (rowA.values[columnId] === "") {
            return -1;
        }
        else if (rowB.values[columnId] === "") {
            return 1
        }
        
        var valueA = moment(rowA.values[columnId], this.dateFormat)
        var valueB = moment(rowB.values[columnId], this.dateFormat)

        if (valueA.isAfter(valueB))
            return 1;
        else if (valueB.isAfter(valueA))
            return -1;
        else
            return 0;
    }

    getPrimaryInterviewStatusId(): string {
        return this.primaryInterviewStatusId;
    }

    getObjective3Column(): any {
        return {
            Header: this.t("client:tabEntry:objective3"), //'Ziel3',
            accessor: 'entry.objective3'
        }
    }
    getObjective1Column(): any {
        return {
            Header: this.t("client:tabEntry:objective1"), //'Ziel1',
            accessor: 'entry.objective1'
        }
    }
    getObjective2Column(): any {
        return {
            Header: this.t("client:tabEntry:objective2"), //'Ziel2',
            accessor: 'entry.objective2'
        }
    }
    getEntryHourColumn(): any {
        return {
            Header: this.t("client:config:columnStartTime"), //'Starttime',
            accessor: 'entry.starttime'
        }
    }
    getPensumColumn(): any {
        return {
            Header: this.t("client:config:columnPensum"), //'Starttime',
            accessor: 'pensum'
        }
    }
    getInterviewState(): any {
        return {
            Header: this.t("client:config:columnInterviewStatus"), //'Interview State',
            id: 'interview.statusId',
            accessor: data => {
                return (this.interviewState.find(p => p.key === (data as IClient)?.interview?.statusId))?.title;
            },
            Filter: ColumnSelectFilter
        }
    }
    getInterviewDateColumn(): any {
        return {
            Header: this.t("client:tabInterview:date"), //'InterviewDate',
            id: 'interview.date',
            accessor: data => {
                const clientData = data as IClient;
                if (clientData?.interview?.date)
                    return moment(clientData.interview.date).format(this.dateFormat)
                return "";
            },
            Filter: ColumnSelectFilter,
            sortType: (rowA: any, rowB: any, columnId: string, desc: boolean) => this.sortDate(rowA, rowB, columnId, desc)

        }
    }

    getInverviewFeedbackColumn(): any {
        return {
            Header: this.t("client:tabInterview:feedback"), //'Anrede',
            accessor: 'interview.feedback'
        }
    }

    getInterviewNextStepsColumn(): any {
        return {
            Header: this.t("client:tabInterview:nextSteps"), //'Anrede',
            accessor: 'interview.nextSteps'
        }
    }

    getTitleColumn(): any {
        return {
            Header: this.t("client:tabMasterData:title"), //'Anrede',
            accessor: 'title'
        }
    }
    getBirthdayColumn(): any {
        return {
            Header: this.t("client:config:columnBirthday"), //'Geburtstag',
            id: 'birthDay',
            accessor: data => {
                const clientData = data as IClient;
                if (clientData?.birthDay)
                    return moment(clientData.birthDay).format(this.dateFormat)
                return "";
            },
            Filter: ColumnSelectFilter,
            sortType: (rowA: any, rowB: any, columnId: string, desc: boolean) => this.sortDate(rowA, rowB, columnId, desc)

        }
    }
    getKeyColumn() {
        return {
            Header: "Key",
            accessor: 'key'
        }
    }
    getFirstNameColumn() {
        return {
            Header: this.t("client:config:columnFirstName"),
            accessor: 'firstName'
        }
    }

    getLastNameColumn() {
        return {
            Header: this.t("client:config:columnLastName"), //'Nachname',
            accessor: 'lastName'
        }
    }

    getCity() {
        return {
            Header: this.t("client:config:columnCity"), // 'Wohnort',
            accessor: 'city'
        }
    }


    getActivityTitle() {
        return {
            Header: this.t("client:tabActivities:columnBetreff"), //'Betreff',
            accessor: 'subject'
        }
    }

    getActivityDescription() {
        return {
            Header: this.t("client:tabActivities:columnDescription"), //'Beschreibung',
            id: "description.html",
            accessor: data => {
                const clientData = data as IActivity;
                return clientData?.description;
            },
            Cell: ({ value }) => {
                if (value !== undefined) {
                    return renderHTML(value)
                }
                return "";
            }
        }
    }

    getActivityDate() {
        return {
            Header: this.t("client:tabActivities:columnDatum"), //'Datum',
            id: "activityDate",
            accessor: data => {
                const clientData = data as IActivity;
                if (clientData?.activityDate)
                    return moment(clientData.activityDate).format(this.dateFormat)
                return "";
            },
            Filter: ColumnSelectFilter,
            sortType: (rowA: any, rowB: any, columnId: string, desc: boolean) => this.sortDate(rowA, rowB, columnId, desc)
        }
    }

    getActivityTest() {
        return {
            Header: this.t("client:tabActivities:columnDatum"), //'Datum',
            id: "activityDate",
            accessor: data => {
                const clientData = data as IActivity;
                if (clientData?.activityDate)
                    return moment(clientData.activityDate).format(this.dateFormat)
                return "";
            },
            Filter: ColumnSelectFilter,
            sortType: (rowA: any, rowB: any, columnId: string, desc: boolean) => this.sortDate(rowA, rowB, columnId, desc)

        }
    }

    getMailColumn() {
        return {
            Header: this.t("client:config:columnEmail"), //'Mail',
            accessor: 'email'
        }
    }

    getActivityAuthorColumn() {
        return {
            Header: this.t("client:config:columnDataCollectedFrom"), //'Erfasst von',
            id: 'createdBy',
            accessor: data => {
                const activity = data as IActivity;

                const user = this.employee.find(p => p.username === activity?.createdBy);
                if (user !== undefined) {
                    return user?.name + " " + user?.lastName;
                }
                return undefined;

            },
            Filter: ColumnSelectFilter
        }
    }

    getActivityEntryDateColumn() {
        return {
            Header: this.t("client:config:columnEntry"), //'Eintritt',
            id: 'client.entry.date',
            accessor: data => {
                const clientData = data;
                if (clientData?.client?.entry?.date) {
                    return moment(clientData.client.entry.date).format(this.dateFormat);
                }
                return "";
            },
            Filter: ColumnSelectFilter,
            sortType: (rowA: any, rowB: any, columnId: string, desc: boolean) => this.sortDate(rowA, rowB, columnId, desc)
        }
    }

    getActivityLeadershipColumn() {
        return {
            Header: this.t("client:config:columnLeadership"), //'Fallführung',
            id: 'leadership',
            accessor: data => {
                const clientData = data;
                if (clientData?.client !== undefined) {
                    const client = clientData.client as IClient;
                    if (client?.entry?.employeeId !== undefined) {
                        const leader = this.employee.find(p => p.key === client?.entry?.employeeId);
                        return leader?.name + " " + leader?.lastName;
                    }
                    return ""
                }
                return ""
            },
            Filter: ColumnSelectFilter
        }
    }

    getActivityadditionalInfoColumn() {
        return {
            Header: this.t("client:tabEntry:additionalInfoId"), //'Zusatzinfos',
            id: 'additionalInfo',
            accessor: data => {
                const clientData = data;
                if (clientData?.client !== undefined) {
                    const client = clientData.client as IClient;
                    if (client?.entry?.additionalInfoId !== undefined) {
                        const info = this.additionalInfo.find(p => p.key === client?.entry?.additionalInfoId);
                        return info?.title;
                    }
                    return ""
                }
                return ""
            },
            Filter: ColumnSelectFilter
        }
    }

    getActivityLocationInfoColumn() {
        return {
            Header: this.t("client:headers:tabLocationInfo"), //'standort Infos',
            id: 'locationInfo',
            accessor: data => {
                const clientData = data;
                if (clientData?.client !== undefined) {
                    const { client } = clientData;
                    if (client?.entry?.locationId !== undefined) {
                        const info = this.locationInfo.find(p => p.key === client?.entry?.locationId);
                        return info?.title;
                    }
                    return ""
                }
                return ""
            },
            Filter: ColumnSelectFilter
        }
    }

    getEntryEmployeeColumn() {
        return {
            Header: this.t("client:config:columnLeadership"), //'Fallführung',
            id: 'entry.employeeId',
            accessor: data => {
                const user = this.employee.find(p => p.key === (data as IClient)?.entry?.employeeId);
                if (user !== undefined) {
                    return user?.name + " " + user?.lastName;
                }
                return undefined;
            },
            Filter: ColumnSelectFilter
        }
    }

    getInterviewFeedbackColumn() {
        return {
            Header: this.t("client:config:columnResultOfInterview"), //'Rückmeldung nach VG',
            accessor: 'interview.feedback'
        }
    }

    getJobLearned() {
        return {
            Header: this.t("client:config:columnJobLearned"),
            accessor: 'jobLearned'
        }
    }
    getLastJob() {
        return {
            Header: this.t("client:config:columnLastJob"),
            accessor: 'lastJob'
        }
    }

    getConsultantColumn() {
        return {
            Header: this.t("client:config:columnAssignedPerson"), //'Zugewiesene Person',
            id: 'consultantId',
            accessor: data => {
                const user = this.consultants.find(p => p.key === (data as IClient)?.consultantId);
                if (user !== undefined) {
                    return user.name + " " + user.lastName;
                }
                return undefined;
            },
            Filter: ColumnSelectFilter
        }
    }

    getInterviewEmployeeColumn() {
        return {
            Header: this.t("client:config:columnInterviewer"), //'MA Vorgespräch',
            id: 'interview.employeeId',
            accessor: data => {
                const user = this.employee.find(p => p.key === (data as IClient)?.interview?.employeeId);
                if (user !== undefined) {
                    return user.name + " " + user.lastName;
                }
            },
            Filter: ColumnSelectFilter
        }
    }

    getEntryDay() {
        return {
            Header: this.t("client:config:columnEntryDay"), //'Eintritt',
            accessor: 'entry.day'
        }
    }

    getEntryDateColumn() {
        return {
            Header: this.t("client:config:columnEntry"), //'Eintritt',
            id: 'entry.date',
            accessor: data => {
                const clientData = data as IClient;
                if (clientData?.entry?.date) {
                    return moment(clientData.entry.date).format(this.dateFormat);
                }
                return "";
            },
            Filter: ColumnSelectFilter,
            sortType: (rowA: any, rowB: any, columnId: string, desc: boolean) => this.sortDate(rowA, rowB, columnId, desc)
        }
    }

    getExitDateColumn() {
        return {
            Header: this.t("client:config:columnExit"), //'Austritt',
            id: 'exit.date',
            accessor: data => {
                const clientData = data as IClient;
                if (clientData?.exit?.date) {
                    return moment(clientData.exit.date).format(this.dateFormat);
                }
                return "";
            },
            Filter: ColumnSelectFilter,
            sortType: (rowA: any, rowB: any, columnId: string, desc: boolean) => this.sortDate(rowA, rowB, columnId, desc)
        }
    }

    getQualificationColumn() {
        return {
            Header: this.t("client:config:columnQualification"), //'Qualification',
            id: "qualification",
            accessor: data => {
                return (this.qualificaitons.find(p => p.key === (data as IClient)?.qualification))?.title;
            },
            Filter: ColumnSelectFilter
        }
    }

    getExitAlvStatusColumn() {
        return {
            Header: this.t("client:config:columnALV"), //'ALV Status',
            id: "exit.alvStatus",
            accessor: data => {
                return (this.alvStatus.find(p => p.key === (data as IClient)?.exit?.alvStatus))?.title;
            },
            Filter: ColumnSelectFilter
        }
    }

    getEtlStatusColumn() {
        return {
            Header: this.t("client:config:columnETL"), //'ETL',
            id: "exit.etl",
            accessor: data => {
                return (this.etlsolutions.find(p => p.key === (data as IClient)?.exit?.etl))?.title;
            },
            Filter: ColumnSelectFilter
        }
    }

    getEntryLocationColumn() {
        return {
            Header: this.t("client:headers:tabLocationInfo"), //'Standortinfos',
            id: 'entry.locationId',
            accessor: data => {
                return (this.locationInfo.find(p => p.key === (data as IClient)?.entry?.locationId))?.title;
            },
            Filter: ColumnSelectFilter
        }
    }



    getAmmStatusColumn() {
        return {
            Header: this.t("client:tabInterview:status"), //'Status',
            id: 'amm.status',
            accessor: data => {
                const clientData = data as IClient;
                return clientData?.amm?.status === true ? 'Aktiv' : 'Passiv';
            },
            Filter: ColumnSelectFilter
        }
    }

    getEntryAdditionalinfoColumn() {
        return {
            Header: this.t("client:tabEntry:additionalInfoId"), //'Zusatzinfos',
            id: 'entry.additionalInfoId',
            accessor: data => {
                return (this.additionalInfo.find(p => p.key === (data as IClient)?.entry?.additionalInfoId))?.title;
            },
            Filter: ColumnSelectFilter
        }
    }

    getModifiedDateColumn() {
        return {
            Header: this.t("client:config:columnChanged"), //'Geändert',
            id: 'modified',
            accessor: data => {
                const clientData = data as IClient;
                if (clientData?.modified) {
                    return moment(clientData.modified).format(this.dateFormat);
                }
                return "";
                
            },
            Filter: ColumnSelectFilter,
            sortType: (rowA: any, rowB: any, columnId: string, desc: boolean) => this.sortDate(rowA, rowB, columnId, desc)

        }
    }
}

