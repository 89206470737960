import { LargeFileUploadTask, ResponseType } from "@microsoft/microsoft-graph-client";
import { DriveItem, Site } from "@microsoft/microsoft-graph-types";
import { Auth } from "./auth/auth.service";
import { GraphBaseItem, GraphItem } from "./graph.types"

interface IFileCopyResponse {
    percentageComplete: number,
    resourceId: string,
    status: string
}

class GraphService {
    async getGroupById(id: string) {
        const client = Auth.getInstance().getGraphClient();

        const path = `/groups/${id}`;
        const item = (await client
            .api(path)
            .get());

        return item
    }

    async getGroupDrives(id: string) {
        const client = Auth.getInstance().getGraphClient();

        const path = `/groups/${id}/drives`;
        const results = (await client
            .api(path)
            .get());

        return results.value
    }

    getTempalteItems = async (site: Site, itemId: string): Promise<GraphItem[]> => {
        const client = Auth.getInstance().getGraphClient();

        const path = `/sites/${site.id}/drive/items/${itemId}/children`;
        const items = (await client
            .api(path)
            .select('id,parentReference,name,webUrl,file,folder,fileSystemInfo')
            .get());

        return items.value.map((item) => {
            return <GraphItem>{
                id: item.id,
                name: item.name,
                webUrl: item.webUrl,
                parentReference: item.parentReference,
                isFolder: item.folder !== undefined,
                fileSystemInfo: item.fileSystemInfo
            }
        })
    }

    getItemsById = async (driveId: string, itemId: string): Promise<GraphItem[]> => {
        const client = Auth.getInstance().getGraphClient();

        const path = `/drives/${driveId}/items/${itemId}/children`;
        const items = (await client
            .api(path)
            .select('id,parentReference,name,webUrl,file,folder,fileSystemInfo')
            .get());

        return items.value.map((item) => {
            return <GraphItem>{
                id: item.id,
                name: item.name,
                webUrl: item.webUrl,
                parentReference: item.parentReference,
                isFolder: item.folder !== undefined,
                fileSystemInfo: item.fileSystemInfo
            }
        })
    }

    getItemsByDriveId = async (itemId: string): Promise<GraphItem[]> => {
        const client = Auth.getInstance().getGraphClient();

        const path = `/drives/${itemId}/items/root/children`;
        const items = (await client
            .api(path)
            .select('id,parentReference,name,webUrl,file,folder')
            .get());

        return items.value.map((item) => {
            return <GraphItem>{
                id: item.id,
                name: item.name,
                webUrl: item.webUrl,
                parentReference: item.parentReference,
                isFolder: item.folder !== undefined,
                fileSystemInfo: item.fileSystemInfo
            }
        })
    }

    getSiteIdByUrl = async (siteUrl: string): Promise<Site> => {
        const client = Auth.getInstance().getGraphClient();
        return (await client
            .api('sites/' + siteUrl)
            .get());
    }

    updateMetadataByPath = async (sourceFilePath: string, body: any): Promise<any> => {
        const client = Auth.getInstance().getGraphClient();

        const path = `${sourceFilePath}/listItem/fields`;
        return await client.api(path).patch(body);
    }

    updateMetadata = async (sourceDriveId: string, sourceFileId: string, body: any): Promise<any> => {
        const client = Auth.getInstance().getGraphClient();

        const path = `/drives/${sourceDriveId}/items/${sourceFileId}/listItem/fields`;
        return await client.api(path).patch(body);
    }

    wait = (ms = 1000) => {
        return new Promise(resolve => {
            setTimeout(resolve, ms);
        });
    }

    pollFile = async (location: string): Promise<IFileCopyResponse> => {
        return await fetch(location, {
            cache: 'no-store'
        }).then(response => response.json())
    }

    copyTemplateToFolderByTargetPath = async (sourceDriveId: string, sourceFileId: string, targetPath: string, fileName: string): Promise<any> => {
        const client = Auth.getInstance().getGraphClient();

        const path = `drives/${sourceDriveId}/items/${sourceFileId}/copy?@microsoft.graph.conflictBehavior=replace`;
        let response = await client.api(path).responseType(ResponseType.RAW).post({
            parentReference: {
                path: targetPath
            },
            name: fileName
        })

        let location = response.headers.get("location");
        let copied = false;
        let resourceId = "";
        do {
            const fileRequest = await this.pollFile(location);
            copied = fileRequest.status === "completed";

            if (fileRequest.status === "failed") {
                console.log(fileRequest)
                throw "Konnte die Datei nicht erstellen"
            }


            if (copied === false) {
                await this.wait(500);
            }
            else {
                resourceId = fileRequest.resourceId;
            }
        } while (copied === false);

        return resourceId;
    }

    copyTemplateToFromFolderToTargetFolder = async (sourcePath: string, targetPath: string, fileName: string): Promise<any> => {
        const client = Auth.getInstance().getGraphClient();

        const path = `${sourcePath}/copy?@microsoft.graph.conflictBehavior=replace`;
        let response = await client.api(path).responseType(ResponseType.RAW).post({
            parentReference: {
                path: targetPath
            },
            name: fileName
        })

        let location = response.headers.get("location");
        let copied = false;
        let resourceId = "";
        do {
            const fileRequest = await this.pollFile(location);
            copied = fileRequest.status === "completed";

            if (fileRequest.status === "failed") {
                console.log(fileRequest)
                throw "Konnte die Datei nicht erstellen"
            }


            if (copied === false) {
                await this.wait(500);
            }
            else {
                resourceId = fileRequest.resourceId;
            }
        } while (copied === false);

        return resourceId;
    }

    copyTemplateToFolder = async (sourceDriveId: string, sourceFileId: string, targetDriveId: string, targetFile: string, fileName: string): Promise<any> => {
        const client = Auth.getInstance().getGraphClient();

        const path = `drives/${sourceDriveId}/items/${sourceFileId}/copy?@microsoft.graph.conflictBehavior=replace`;
        let response = await client.api(path).responseType(ResponseType.RAW).post({
            parentReference: {
                driveId: targetDriveId,
                id: targetFile
            },
            name: fileName
        })

        let location = response.headers.get("location");
        let copied = false;
        let resourceId = "";
        do {
            const fileRequest = await this.pollFile(location);
            copied = fileRequest.status === "completed";

            if (fileRequest.status === "failed") {
                console.log(fileRequest)
                throw "Konnte die Datei nicht erstellen"
            }


            if (copied === false) {
                await this.wait(500);
            }
            else {
                resourceId = fileRequest.resourceId;
            }
        } while (copied === false);

        return resourceId;
    }

    getDriveByRelativeName = async (site: Site | undefined, relativePath: string): Promise<DriveItem> => {
        const client = Auth.getInstance().getGraphClient();

        const path = `/sites/${site?.id}/drives`;
        const driveItems = (await client
            .api(path)
            .get());

        return driveItems.value.find(p => p.webUrl.indexOf(relativePath) > -1)
    }

    getFolderIdByDrivePath = async (driveId: string, relativePath: string): Promise<GraphBaseItem> => {
        const client = Auth.getInstance().getGraphClient();

        const path = `/drives/${driveId}${relativePath}`;
        return <GraphBaseItem>(await client
            .api(path)
            .get());
    }

    getFolderIdByPath = async (relativePath: string): Promise<GraphBaseItem> => {
        const client = Auth.getInstance().getGraphClient();

        const path = `${relativePath}`;
        return <GraphBaseItem>(await client
            .api(path)
            .get());
    }

    deleteFile = async (driveId: string, fileId: string): Promise<boolean> => {
        const client = Auth.getInstance().getGraphClient();

        await client.api(`/drives/${driveId}/items/${fileId}`)
            .delete();

        return true;
    }

    uploadLargeFile = async (driveId: string, folderId: string, fileName: string, fileContent: any): Promise<boolean> => {
        const client = Auth.getInstance().getGraphClient();
        const requestUrl = `https://graph.microsoft.com/v1.0/drives/${driveId}/items/${folderId}:/${fileName}:/createUploadSession`;

        const payload = {
            "@microsoft.graph.conflictBehavior": "replace",
            "fileSize": fileContent.byteLength,
            "name": fileName
        }
        const uploadSession = await LargeFileUploadTask.createUploadSession(client, requestUrl, payload);

        const fileObject = {
            content: fileContent,
            name: fileName,
            size: fileContent.byteLength
        };

        const task = new LargeFileUploadTask(client, fileObject, uploadSession);

        await task.upload();

        return true;
    }
}

export default new GraphService();

