import React from 'react';
import { LocationAwareLink } from "./LocationAwareLink"
import { PeoplePicker } from "./details/PeoplePicker";
import { useTranslation } from "react-i18next";
import Logo from "../img/stellwerk-logo.png"

export const Navigation: React.FunctionComponent<{ color?: string, isAdmin: boolean, isDataAdmin: boolean }> = (props) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const { isAdmin, isDataAdmin, color } = props;
    const toggle = () => setIsOpen(!isOpen);
    const close = () => setIsOpen(false);
    const hasAccess = isAdmin || isDataAdmin;
    const { t } = useTranslation();

    return (
        <nav className="navbar" role="navigation" aria-label="main navigation" style={{
            "borderColor": color
        }}>
            <div className="navbar-brand">
                <a className="navbar-item" >
                    <img src={Logo} height="200px"></img>
                </a>
                <a role="button" className="navbar-burger" aria-label="menu" onClick={toggle} aria-expanded="false"
                    data-target="navbarBasicExample">
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
            </div>
            <div id="mainNavBar" className={`navbar-menu  ${isOpen ? "is-active" : ""}`}>
                <div className="navbar-start" onClick={close}>
                    <div className="navbar-item has-dropdown is-hoverable">

                        <LocationAwareLink title={t("client:headers:tabClients")} relativeLocation=""></LocationAwareLink>

                        <div className="navbar-dropdown">
                            <LocationAwareLink title={t("client:headers:tabInProgramm")} relativeLocation="/program"></LocationAwareLink>
                            <LocationAwareLink title={t("client:headers:tabInProgrammPlus")} relativeLocation="/programplus"></LocationAwareLink>
                            <LocationAwareLink title={t("client:headers:tabInProgrammPictures")} relativeLocation="/programpictures"></LocationAwareLink>
                            <LocationAwareLink title={t("client:headers:tabAllClients")} relativeLocation="/all"></LocationAwareLink>
                            <LocationAwareLink title={t("client:headers:tabEntries")} relativeLocation="/entries"></LocationAwareLink>
                            <LocationAwareLink title={t("client:headers:tabEntriesPlus")} relativeLocation="/entriesplus"></LocationAwareLink>
                            <LocationAwareLink title={t("client:headers:tabExits")} relativeLocation="/exits"></LocationAwareLink>
                            <LocationAwareLink title={t("client:headers:tabFutureExits")} relativeLocation="/upcomingexits"></LocationAwareLink>
                            <LocationAwareLink title={t("client:headers:tabAMMSearch")} relativeLocation="/ammsearch"></LocationAwareLink>
                            <LocationAwareLink title={t("client:headers:tabActivitiesSearch")} relativeLocation="/activities"></LocationAwareLink>
                            <LocationAwareLink title={t("client:headers:tabActivitiesOldClients")} relativeLocation="/oldclients"></LocationAwareLink>
                        </div>
                    </div>

                    <div className="navbar-item has-dropdown is-hoverable">
                        <LocationAwareLink title={t("client:headers:tabDocuments")} relativeLocation="/documents"></LocationAwareLink>

                        <div className="navbar-dropdown">
                            <LocationAwareLink title={t("client:headers:tabDocuments")} relativeLocation="/documents"></LocationAwareLink>
                            <LocationAwareLink title={t("client:headers:tabInterview")} relativeLocation="/documents/interview"></LocationAwareLink>
                        </div>
                    </div>

                    <div className="navbar-item has-dropdown is-hoverable">
                        <LocationAwareLink title={t("client:headers:tabAMMOverview")} relativeLocation="/presence"></LocationAwareLink>

                        <div className="navbar-dropdown">
                            <LocationAwareLink title={t("client:headers:tabAMMCollect")} relativeLocation="/presence"></LocationAwareLink>
                            <LocationAwareLink title={t("client:headers:tabAMMPrint")} relativeLocation="/presence/print"></LocationAwareLink>
                        </div>
                    </div>
                </div>
                <div className="navbar-end">
                    {props.children}
                    {(hasAccess) && (
                        <div className="navbar-item has-dropdown is-hoverable">
                            <a className="navbar-link">
                                {t("client:headers:tabMasterData")}
                            </a>
                            <div className="navbar-dropdown">
                                <LocationAwareLink title={t("client:headers:tabQualification")}
                                    relativeLocation="/config/qualifications"></LocationAwareLink>
                                <LocationAwareLink title={t("client:headers:tabLocationInfo")}
                                    relativeLocation="/config/locationInfo"></LocationAwareLink>
                                <LocationAwareLink title={t("client:headers:tabAdditionalInfos")}
                                    relativeLocation="/config/additionalinfo"></LocationAwareLink>
                                {(isAdmin) && (
                                    <React.Fragment>
                                        <hr className="navbar-divider"></hr>
                                        <LocationAwareLink title={t("client:headers:tabTerminations")}
                                            relativeLocation="/config/terminations"></LocationAwareLink>
                                        <LocationAwareLink title={t("client:headers:tabAlkDetails")}
                                            relativeLocation="/config/alks"></LocationAwareLink>
                                        <LocationAwareLink title={t("client:headers:tabALVStatus")}
                                            relativeLocation="/config/alvstatus"></LocationAwareLink>
                                        <LocationAwareLink title={t("client:headers:tabResidencePermit")}
                                            relativeLocation="/config/residencepermits"></LocationAwareLink>
                                        <LocationAwareLink title={t("client:headers:tabStrategies")}
                                            relativeLocation="/config/strategies"></LocationAwareLink>
                                        <LocationAwareLink title={t("client:headers:tabFreedayDetails")}
                                            relativeLocation="/config/freedays"></LocationAwareLink>
                                        <LocationAwareLink title={t("client:headers:tabEmployee")}
                                            relativeLocation="/config/employee"></LocationAwareLink>
                                        <LocationAwareLink title={t("client:headers:tabEmployeeFunction")}
                                            relativeLocation="/config/employeeFunctions"></LocationAwareLink>
                                        <LocationAwareLink title={t("client:headers:tabNationality")}
                                            relativeLocation="/config/nationalities"></LocationAwareLink>
                                        <LocationAwareLink title={t("client:headers:tabEtlSolution")}
                                            relativeLocation="/config/etlsolution"></LocationAwareLink>
                                        <LocationAwareLink title={t("client:headers:tabExpensesDetails")}
                                            relativeLocation="/config/expenses"></LocationAwareLink>
                                        <LocationAwareLink title={t("client:headers:tabOfficeLocation")}
                                            relativeLocation="/config/officelocations"></LocationAwareLink>
                                        <LocationAwareLink title={t("client:headers:tabInterviewStatus")}
                                            relativeLocation="/config/interviewstatus"></LocationAwareLink>
                                        <LocationAwareLink title={t("client:headers:tabConsultantDetail")}
                                            relativeLocation="/config/consultants"></LocationAwareLink>
                                        <LocationAwareLink title={t("client:headers:tabConsDepLocation")}
                                            relativeLocation="/config/consultantLocations"></LocationAwareLink>
                                        <LocationAwareLink title={t("client:headers:tabConsDepFunction")}
                                            relativeLocation="/config/consultantFunctions"></LocationAwareLink>
                                        <LocationAwareLink title={t("client:headers:tabConsDepDetails")}
                                            relativeLocation="/config/consultantDepartments"></LocationAwareLink>
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                    )}

                    <div className="navbar-item">
                        <PeoplePicker></PeoplePicker>
                    </div>
                </div>
            </div>
        </nav>
    );
};
