import React from 'react';
import { Route } from 'react-router-dom';
import appService from "../services/app.service";

export const LocationAwareLink: React.FunctionComponent<{ title: string, relativeLocation: string }> = (
  props
) => {
  const { title, relativeLocation } = props;

  const changeLocation = (history: any) => {
    const location = appService.getLocation();
    history.push(`/${location}${relativeLocation}`)
  }

  return (
    <Route render={({ history }) => (
      <a className="navbar-item" onClick={(e) => { e.preventDefault(); changeLocation(history); }}>{title}</a>
    )} />
  );
};
