import React from "react";
import appService from "../../../services/app.service";
import { DetailsOverview } from "../base/DetailsOverview";
import { useTranslation } from "react-i18next";
import { NoAdminAlert } from "../../NoAdminAlert"

export const StrategiesOverview: React.FunctionComponent<{ isAdmin: boolean }> = (props) => {
    const { t } = useTranslation();
    const detailsRedirection = "/config/strategies/details";
    const title = t("client:headers:tabStrategies");
    const { isAdmin } = props;

    if (isAdmin !== true) { return <NoAdminAlert /> }
    
    return (
            <React.Fragment>
                <DetailsOverview detailsRedirection={detailsRedirection} getItems={appService.getStrategies} hasNumber={true} entityType="strategies" title={title}></DetailsOverview>
            </React.Fragment>
    );
};
