import React from 'react';
import { useTranslation } from "react-i18next";

export const ModalBox: React.FunctionComponent<{
  title?: string;
  confirmTitle?: string;
  closeTitle?: string;
  isActive: boolean;
  hideConfirm?: boolean;
  onConfirmClick?(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): any;
  onCloseClick?(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): any;
}> = (props) => {
  const isActive = props.isActive === true;
  if (isActive === false) {
    return <React.Fragment></React.Fragment>
  }

  const {t} = useTranslation();
  const showConfirm = props.hideConfirm !== true;
  const modalCss = isActive ? "modal is-active" : "modal";
  const closeTitle =
    props.closeTitle !== undefined ? props.closeTitle : t("client:tabModalBox:close");

  const confirmTitle =
    props.confirmTitle !== undefined ? props.confirmTitle : t("client:tabModalBox:ok");

  return (
    <div className={modalCss}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">{props.title}</p>
          {props.onCloseClick !== undefined && (
            <button
              className="delete"
              aria-label={closeTitle}
              onClick={(e) => {
                e.preventDefault();

                if (props.onCloseClick !== undefined) props.onCloseClick(e);
              }}
            ></button>
          )}
        </header>
        <section className="modal-card-body">{props.children}</section>
        <footer className="modal-card-foot">
          {props.onConfirmClick !== undefined && showConfirm === true && (
            <button
              className="button is-link"
              onClick={(e) => {
                e.preventDefault();
                if (props.onConfirmClick !== undefined) props.onConfirmClick(e);
              }}
            >
              {confirmTitle}
            </button>
          )}
          {props.onCloseClick !== undefined && (
            <button
              className="button"
              onClick={(e) => {
                e.preventDefault();
                if (props.onCloseClick !== undefined) props.onCloseClick(e);
              }}
            >
              {closeTitle}
            </button>
          )}
        </footer>
      </div>
    </div>
  );
};
