import React, { useMemo, useState } from "react";
import { IBaseDetailsItem } from "../../../core/cm.types"
import { LocationAwareButton } from "../../LocationAwareButton";
import appService from "../../../services/app.service";
import { useTranslation } from "react-i18next";
import { Table } from "../../table/Table";
import { TableSelectionCheckbox } from "../../table/TableSelectionCheckbox";

export const DetailsOverview: React.FunctionComponent<{
    title: string,
    detailsRedirection: string, entityType: string,
    getItems(): Promise<IBaseDetailsItem[]>,
    hasNumber?: boolean,
    columns?: any,
    headers?: any,
    sorting?: any
    exportAllTable?(rows: any): any,
}> = (props) => {
    const { t } = useTranslation();

    const { getItems, hasNumber, exportAllTable, headers, columns, sorting } = props;

    const [data, setData] = useState<{
        isLoading: boolean,
        data: IBaseDetailsItem[]
    }>({
        isLoading: true,
        data: []
    });

    const { title, detailsRedirection, entityType } = props;

    const fetchData = async () => {
        setData({ isLoading: true, data: [] })
        setData({ isLoading: false, data: await getItems() });
    };

    const onDelete = async (id: string) => {
        if (confirm(t("client:common:deleteMessage"))) {
            await appService.delEntity(entityType, id);
            fetchData();
        }
    }

    const defaultColumns = useMemo(() => hasNumber === true ? [
        {
            Header: t("client:config:columnNumber"),
            accessor: 'order'
        },
        {
            Header: t("client:common:columnTitle"),
            accessor: 'title'
        }
    ] : [
        {
            Header: t("client:common:columnTitle"),
            accessor: 'title'
        }
    ], []);

    const sortBy = [
        { id: hasNumber === true ? "order" : "title", desc: false }
    ];

    const suffix = useMemo(() => [
        {
            id: 'button',
            Cell: ({ row }) => (
                <LocationAwareButton className="button is-small" relativeLocation={`${detailsRedirection}/${row.original.key}`}>
                    <span className="icon is-small">
                        <i className={`fas fa-edit`} />
                    </span>
                </LocationAwareButton>
            ),
        }], []);

    const postfix = [
        {
            id: 'deletebutton',
            Cell: ({ row }) => (
                <button
                    className="button is-link is-danger is-small"
                    title={t("client:common:deleteButton")}
                    onClick={(e) => {
                        e.preventDefault();
                        onDelete(row.original.key)
                    }}
                >
                    <span className="icon is-small">
                        <i className={`fas fa-trash`} />
                    </span>
                </button>
            ),
        }];

    React.useEffect(() => {
        fetchData()
    }, []);

    /*
    const exportAllTable = async (rows) => {
        return rows.map(row => {
            const item = row.original as IBaseDetailsItem

            let message = {};

            //message[`${t("client:tabMasterData:title")}`] = checkValue(client?.title);
            //message[`${t("client:tabMasterData:firstName")}`] = checkValue(client?.firstName);

            return message;
        })
    };*/

    return (
        <React.Fragment>
            <div className="section defaultSection">
                <h1 className="title is-3">{title}</h1>
                <div className="columns">
                    <div className="column">
                        <LocationAwareButton title={t("client:common:newButton")} className="button is-small is-pulled-right"
                            relativeLocation={`${detailsRedirection}`}></LocationAwareButton></div>
                </div>

                <Table data={data.data} columns={columns ?? defaultColumns} columnssuffix={suffix as any} columnspostfix={postfix as any} sortBy={sorting ?? sortBy} exportAllTable={exportAllTable} />
            </div>
            {/*
                <div className="table-container">
                    <table className="table is-fullwidth">
                        <thead>
                            <tr>
                                <th></th>
                                {hasNumber === true && (
                                    <th><SortButton property="order" title={t("client:config:columnNumber")} sortConfig={sortConfig}
                                        requestSort={requestSort}></SortButton></th>
                                )}
                                <th><SortButton property="title" title={t("client:common:columnTitle")} sortConfig={sortConfig}
                                    requestSort={requestSort}></SortButton></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map((item) => (
                                <tr key={item.key}>
                                    <td className="actions">
                                        <LocationAwareButton className="button is-small is-pulled-left"
                                            relativeLocation={`${detailsRedirection}/${item.key}`}>
                                            <span className="icon is-small">
                                                <i className={`fas fa-edit`} />
                                            </span>
                                        </LocationAwareButton>
                                    </td>
                                    {hasNumber === true && (
                                        <td>{item.order}</td>
                                    )}
                                    <td>{item.title}</td>
                                    <td className="actions">
                                        <button
                                            className="button is-link is-danger is-small is-pulled-right"
                                            title={t("client:common:deleteButton")}
                                            onClick={(e) => { e.preventDefault(); onDelete(item.key); }}
                                        >
                                            <span className="icon is-small">
                                                <i className={`fas fa-trash`}></i>
                                            </span>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                            {items.length === 0 && (
                                <tr>
                                    <td colSpan={3}>{t("client:common:noData")}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
                            */}
        </React.Fragment>
    );
};
