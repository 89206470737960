import React from "react";
import appService from "../../../services/app.service";
import { useTranslation } from "react-i18next";
import { NoAdminAlert } from "../../NoAdminAlert"
import { DetailsOverview } from "../base/DetailsOverview";
import { IExpense } from "../../../core/cm.types";
import { ColumnSelectFilter } from "../../table/ColumnSelectFilter";

export const ExpenseOverview: React.FunctionComponent<{ isAdmin: boolean }> = (props) => {
    const { isAdmin } = props;
    const { t } = useTranslation();
    const title = t("client:headers:tabExpensesDetails");
    const detailsRedirection = "/config/expenses/details";

    if (isAdmin !== true) { return <NoAdminAlert /> }

    const columns = [
        {
            Header: t("client:config:columnExpenseVM"),
            accessor: 'vm'
        },
        {
            Header: t("client:config:columnExpenseNM"),
            accessor: 'nm'
        },
        {
            Header: t("client:config:columnExpenseIsDailyallowance"),
            accessor: 'dailyAllowance'
        },
        {
            Header: t("client:config:columnExpenseIsMealExpense"), //'Status',
            id: 'isMealExpense',
            accessor: data => {
                const clientData = data as IExpense;
                return clientData?.isMealExpense === true ? 'Ja' : 'Nein';
            },
            Filter: ColumnSelectFilter
        }
    ];

    return (
        <DetailsOverview columns={columns} detailsRedirection={detailsRedirection} getItems={appService.getExpenses} entityType="expenses" title={title}></DetailsOverview>
    );
};
