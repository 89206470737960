import React from "react";
import { IClient } from "../../core/cm.types"
import { Table } from "../table/Table"
import { useMemo } from "react";
import { TableService } from "../../services/table.service";
import moment from "moment";

export const InProgramPictures: React.FunctionComponent<{ suffix: any, postfix: any, clients: IClient[], tableService: TableService, exportAllTable(rows) }> = (props) => {
    const { clients, suffix, postfix, tableService, exportAllTable } = props;

    //const today = (new Date()).getTime();
    const thisDay = moment(new Date()).startOf('day');

    const primaryInterviewStatusId = tableService.getPrimaryInterviewStatusId();
    const data = clients.filter((client) => {
        if (client?.entry?.date === undefined || client?.exit?.date === undefined)
            return false;

        //const entryDate = Date.parse(client.entry.date);
        //const exitDate = Date.parse(client.exit.date);
        const entry = moment(client.entry.date).startOf('day');
        const exit = moment(client.exit.date).startOf('day');

        return client.interview?.statusId === primaryInterviewStatusId &&
            entry <= thisDay &&
            exit >= thisDay;
    });

    const columns = useMemo(() => [
        tableService.getKeyColumn(),
        {
            accessor: "photoUrl",
            Filter: <React.Fragment />,
            Cell: ({ value }) => {
              if (value) {
                  return <img src={value} className="previewPicture" alt={value} />;
              }
              return <React.Fragment />
            }
          },
        tableService.getFirstNameColumn(),
        tableService.getLastNameColumn(),
        tableService.getCity(),
        tableService.getBirthdayColumn(),
        tableService.getEntryDateColumn(),
        tableService.getExitDateColumn(),
        tableService.getEntryEmployeeColumn(),
        tableService.getConsultantColumn(),
    ], [tableService]);

    const sortBy = [
        { id: 'entry.date', desc: true }
    ];

    return (
        <Table data={data} columns={columns} columnssuffix={suffix} columnspostfix={postfix} sortBy={sortBy} exportAllTable={exportAllTable} />
    )
};
