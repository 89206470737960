import { Icon } from "@fluentui/react";
import React, { useCallback, useEffect, useState } from "react";
import graphService from "../../services/graph.service";
import { GraphBaseItem, GraphItem } from "../../services/graph.types";
import "./FileExplorer.css"
import { useDropzone } from 'react-dropzone'
import { useTranslation } from "react-i18next";
import { SmallLoading } from "../SmallLoading";

export const FileExplorer: React.FunctionComponent<{ clientDriveId: string, basePath: string | undefined }> = (props) => {
    const { t } = useTranslation();
    const { clientDriveId, basePath } = props;
    const [currentFolder, setCurrentFolder] = useState<GraphBaseItem | null>(null)
    const [rootFolder, setRootFolder] = useState<GraphBaseItem | null>(null)
    const [items, setItems] = useState<GraphItem[]>([])
    const [isLoading, setLoading] = useState<boolean>(true)

    const loadFolder = async (folder: GraphBaseItem) => {
        setLoading(true);
        let items = await graphService.getItemsById(clientDriveId, folder.id);
        setItems(items);
        setCurrentFolder(folder)
        setLoading(false);
    }

    const goUp = async () => {
        if (currentFolder.id !== rootFolder.id) {
            let folder = await graphService.getFolderIdByPath(currentFolder.parentReference.path);
            loadFolder(folder)
        }
    }

    const onDrop = useCallback(async acceptedFiles => {
        setLoading(true);
        for (let i = 0; i < acceptedFiles.length; i++) {
            (function (file) {
                let name = file.name;
                let reader = new FileReader();
                reader.onload = async function (e) {
                    // get file content  
                    let contents = e.target.result;
                    await graphService.uploadLargeFile(clientDriveId, currentFolder.id, name, contents);
                    loadFolder(currentFolder);
                }
                reader.readAsArrayBuffer(file);
            })(acceptedFiles[i]);
        }

    }, [clientDriveId, currentFolder])

    const onDelete = async (fileId: string, fileName: string) => {
        if (confirm(`Soll '${fileName}' wirklich gelöscht werden?`)) {
            graphService.deleteFile(clientDriveId, fileId).then(() => {
                loadFolder(currentFolder)
            })
        }
    }

    useEffect(() => {
        const initialize = async () => {
            try {
                let folder = await graphService.getFolderIdByPath(`/drives/${clientDriveId}/root:/${basePath}`);
                let items = await graphService.getItemsById(clientDriveId, folder.id);

                setRootFolder(folder);
                setItems(items);
                setCurrentFolder(folder)
                setLoading(false)
            } catch {

            }
        }
        initialize();
    }, [basePath])

    if (basePath === undefined || basePath === "") {
        return <React.Fragment>{t("client:tabFileExplorer:saveBefore")}</React.Fragment>
    }

    return (
        <ExplorerTable items={items} isLoading={isLoading} goUp={goUp} onDrop={onDrop} loadFolder={loadFolder}
            onDelete={onDelete}></ExplorerTable>
    );
};

export const ExplorerTable: React.FunctionComponent<{ isLoading: boolean, items: GraphItem[], goUp(), onDrop: (acceptedFiles: any) => void, loadFolder(folder: GraphItem), onDelete: (fileId: string, fileName: string) => void }> = (props) => {
    const { t } = useTranslation();
    const { items, goUp, loadFolder, onDelete, onDrop, isLoading } = props;
    const { getRootProps, getInputProps } = useDropzone({ onDrop })

    if (isLoading)
        return <SmallLoading isLoading={isLoading} />

    items.sort((a, b) => {
        if (a.isFolder && b.isFolder == false) {
            return -1
        }

        if (a.isFolder === false && b.isFolder === false && a.fileSystemInfo?.lastModifiedDateTime !== undefined && b.fileSystemInfo?.lastModifiedDateTime !== undefined) {
            const aDate = new Date(a.fileSystemInfo.lastModifiedDateTime);
            const bDate = new Date(b.fileSystemInfo.lastModifiedDateTime);
            return aDate.getTime() > bDate.getTime() ? -1 : 1;
        }
        return 1
    })

    return (
        <React.Fragment>
            <table>
                <tbody>
                    <tr>
                        <td colSpan={2}>
                            <a href="#" onClick={(e) => {
                                e.preventDefault();
                                goUp()
                            }} style={{ color: 'inherit' }}><span>...</span> </a>
                        </td>
                    </tr>
                    {items?.length == 0 && (
                        <tr>
                            <td colSpan={2}>
                                {t("client:tabFileExplorer:noData")}
                            </td>
                        </tr>
                    )}
                    {items?.map((item) => {
                        return (
                            <tr key={item.id}>
                                <td className="iconColumn">
                                    {renderFileIcon(item.name)}
                                </td>
                                <td className="tdfullwidth">
                                    {item.isFolder ? (
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault();
                                            loadFolder(item)
                                        }} style={{ color: 'inherit' }}><span>&nbsp;{item.name}</span> </a>
                                    ) : (
                                        <a target="_blank" href={item.webUrl}
                                            style={{ color: 'inherit' }}><span>&nbsp;{item.name}</span> </a>
                                    )}
                                </td>
                                <td>
                                    <a href='#' style={{ color: 'inherit' }} onClick={(e) => {
                                        e.preventDefault();
                                        onDelete(item.id, item.name);
                                    }}><Icon iconName="Delete" /></a>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>

            <div className="dropzone" {...getRootProps()}>
                <input {...getInputProps()} />
                {
                    <p>{t("client:tabFileExplorer:dropzoneMessage")}</p>
                }
            </div>
        </React.Fragment>
    );
};

const renderFileIcon = (name: string) => {
    const ext = /\.([a-z]+)/.exec(name)
    if (ext == null || ext.length == 0) return <Icon iconName="FabricFolder" />
    return (
        <img
            src={`https://static2.sharepointonline.com/files/fabric/assets/item-types/16/${ext[1]}.svg`}
            className="fabricIcon"
        />
    )
}

