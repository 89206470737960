import React, { useState } from "react";
import { IFreeday } from "../../../core/cm.types"
import appService from "../../../services/app.service";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { NoAdminAlert } from "../../NoAdminAlert"
import { DetailsOverview } from "../base/DetailsOverview";
import { ColumnSelectFilter } from "../../table/ColumnSelectFilter";

export const FreedayOverview: React.FunctionComponent<{ isAdmin: boolean }> = (props) => {
    const { isAdmin } = props;
    const { t } = useTranslation();
    const title = t("client:headers:tabFreedayDetails");
    const detailsRedirection = "/config/freedays/details";

    if (isAdmin !== true) { return <NoAdminAlert /> }

    const dateFormat = "DD.MM.YYYY";
    const sortDate = (rowA: any, rowB: any, columnId: string, desc: boolean) => {
        var valueA = moment(rowA.values[columnId], dateFormat)
        var valueB = moment(rowB.values[columnId], dateFormat)

        if (valueA.isAfter(valueB))
            return 1;
        else if (valueB.isAfter(valueA))
            return -1;
        else
            return 0;
    }

    const columns = [
        {
            Header: t("client:common:columnTitle"),
            accessor: 'title'
        },
        {
            Header: t("client:config:columnFreedayDay"), //'InterviewDate',
            id: 'day',
            accessor: data => {
                const clientData = data as IFreeday;
                return moment(clientData?.day).format(dateFormat)
            },
            Filter: ColumnSelectFilter,
            sortType: (rowA: any, rowB: any, columnId: string, desc: boolean) => sortDate

        }
    ];

    const sortBy = [
        { id: "day", desc: false }
    ];

    return (
        <DetailsOverview columns={columns} detailsRedirection={detailsRedirection} getItems={appService.getFreedays} entityType="freedays" title={title} sorting={sortBy}></DetailsOverview>
    );
};
