import React from "react";
import { IClient } from "../../core/cm.types"
import { Table } from "../table/Table"
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TableService } from "../../services/table.service"
import { LocationAwareButton } from "../LocationAwareButton";

export const PresenceAmmClients: React.FunctionComponent<{ clients: IClient[], tableService: TableService, onClientDelete: any, exportAllTable(rows) }> = (props) => {
    const { t } = useTranslation();
    const { clients, tableService, onClientDelete, exportAllTable } = props;


    const columns = useMemo(() => [
        {
            id: 'button',
            Cell: ({ row }) => (
                <LocationAwareButton className="button is-small" relativeLocation={`/details/${row.original.key}`}>
                    <span className="icon is-small">
                        <i className={`fas fa-edit`} />
                    </span>
                </LocationAwareButton>
            ),
        },
        tableService.getKeyColumn(),
        tableService.getFirstNameColumn(),
        tableService.getLastNameColumn(),
        tableService.getInterviewDateColumn(),
        tableService.getExitDateColumn(),
        tableService.getAmmStatusColumn(),
        tableService.getQualificationColumn(),
        tableService.getEntryLocationColumn(),
        tableService.getEntryAdditionalinfoColumn(),
        tableService.getModifiedDateColumn(),
        {
            id: 'deletebutton',
            Cell: ({ row }) => (
                <button
                    className="button is-link is-danger is-small"
                    title={t("client:common:deleteButton")}
                    onClick={(e) => {
                        e.preventDefault();
                        onClientDelete(row.original.key);
                    }}
                >
                    <span className="icon is-small">
                        <i className={`fas fa-trash`} />
                    </span>
                </button>
            ),
        }
    ], [onClientDelete, t, tableService]);

    const sortBy = [
        { id: 'modified', desc: true }
    ];

    return (
        <Table data={clients} columns={columns} sortBy={sortBy} exportAllTable={exportAllTable} />
    )
};
