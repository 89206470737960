import React, { useRef, useState } from 'react';
import appService from "../../services/app.service";
import { IClient } from "../../core/cm.types"
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';
import { SmallLoading } from "../SmallLoading";

export const PeoplePicker: React.FunctionComponent = (props) => {
  const filterRef = useRef();
  const [searchTerm, setSearchTerm] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [triggerSearch, setTriggerSearch] = React.useState(false);
  const [clients, setClients] = useState<IClient[]>([]);
  const { t } = useTranslation();
  const history = useHistory();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  React.useEffect(() => {
    let delayTimeOutFunction: any;

    if (!filterRef.current) {
      (filterRef as any).current = true;

    } else { // componentDidMount equivalent
      delayTimeOutFunction = setTimeout(() => {
        if (searchTerm === "" || searchTerm === undefined) {
          setClients([]);
          setTriggerSearch(false);
          return;
        }

        setIsLoading(true);
        setTriggerSearch(true);

        appService.searchClients(searchTerm).then((client) => {
          setClients(client);
        })
          .catch(() => setClients([]))
          .finally(() => setIsLoading(false));
      }, 250); // denounce delay
    }
    return () => clearTimeout(delayTimeOutFunction);
  }, [searchTerm]);

  return (
    <div
      className="picker-container"
      onFocus={() => setTriggerSearch(false)}
      onBlur={() => {
        setClients([]);
        setSearchTerm("");
        setTriggerSearch(false);
      }}
    >
      <div className="field">
        <div className="control">
          <input
            type="text"
            className="input"
            placeholder={t("client:common:placeholder")}
            value={searchTerm}
            onChange={handleChange}
          />
        </div>
      </div>
      {triggerSearch && (
        <ul className="picker-container-list">
          {clients.map((item, i) => (
            <li
              key={i}
              className="picker-container-list-item"
              onMouseDown={() => {
                setClients([]);
                setSearchTerm("");
                setTriggerSearch(false);

                const location = appService.getLocation();
                history.push(`/${location}/details/${item.key}`)
              }}
            >
              <div className="picker-container-list-text">
                <p className="picker-container-list-text-primary">
                  {item.lastName}{" "}{item.firstName}
                </p>
                <p className="picker-container-secondary">
                  {item.email}{item.phone !== undefined && (<React.Fragment><br />Telefon: {item.phone}</React.Fragment>)} {item.mobile !== undefined && (<React.Fragment><br />Mobil: {item.mobile}</React.Fragment>)}
                </p>
              </div>
              <hr />
            </li>
          ))}
          <SmallLoading isLoading={isLoading}></SmallLoading>
          {clients.length === 0 && isLoading === false && (
            <li className="picker-container-list-noItems">
              {t("client:common:noresults")}
            </li>
          )}
        </ul>
      )}
    </div>
  );
};
