import React from "react";

export const ColumnDefaultFilter: React.FunctionComponent<{ column: { filterValue, setFilter }}> = (props) => {
  const { column } = props;
  const { filterValue, setFilter } = column;

  return (
    <div className="field">
      <p className="control has-icons-right is-small">
        <input className="input is-small" value={filterValue || ''} onChange={e => {
          setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
        }} />
        <span className="icon is-small is-right">
          <i className="fas fa-search"></i>
        </span>
      </p>
    </div>
  )
}
