import React from "react";
import { IApplication } from "../../../core/cm.types";
import { Input } from "../../fields/Input";
import { ModalBox } from "../../fields/ModalBox";
import appService from "../../../services/app.service";
import DatePicker from "react-datepicker";

import { RichTextBox } from "../../fields/RichTextBox";
import { useTranslation } from "react-i18next";

export const ApplicationDetail: React.FunctionComponent<{ isActive: boolean, trackState: any, clientId: string, applicationId: string | undefined, onSubmit(application: IApplication): void, onCloseClick?(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): any }> = (props) => {
    const { t } = useTranslation();
    const { isActive, onCloseClick, onSubmit, applicationId, clientId, trackState } = props;
    const isNew = applicationId === undefined;
    const [application, setCurrentApplication] = React.useState<IApplication>({
        applicationDate: new Date().toISOString()
    });

    const initializeApplication = () => {
        setCurrentApplication({
            applicationDate: new Date().toISOString()
        })
    }

    React.useEffect(() => {
        const fetchData = async () => {
            const activity = await appService.getApplication(clientId, applicationId);
            setCurrentApplication(activity);
        };
        if (!isNew)
            fetchData();
        else {
            initializeApplication();
        }
    }, [applicationId]);

    return (
        <ModalBox title="Programmeinsatz" onConfirmClick={() => {
            onSubmit(application);
            initializeApplication();
        }} onCloseClick={onCloseClick} isActive={isActive}>
            <DatePicker className={`input date-activities`} dateFormat="dd.MM.yyyy HH:mm"
                locale={"de"}
                showTimeSelect
                timeFormat="HH:mm"
                selected={application.applicationDate !== undefined ? new Date(application.applicationDate) : new Date()}
                onChange={val => setCurrentApplication({
                    ...application,
                    applicationDate: val
                })} />

            <Input value={application.subject} onChange={(event) => {
                setCurrentApplication({
                    ...application,
                    subject: event.target.value
                })
            }} />

            <RichTextBox trackState={trackState} refreshvalue={application.key} value={application?.description} setFieldValue={(contents) => {
                setCurrentApplication({
                    ...application,
                    description: contents
                })
            }} />
        </ModalBox>
    );
};
