import React from "react";
import { IClient } from "../../core/cm.types"
import { Table } from "../table/Table"
import { useMemo } from "react";
import { TableService } from "../../services/table.service"

export const PresenceAllClients: React.FunctionComponent<{ clients: IClient[], tableService: TableService, selectedRows: any[], setSelectedRows, exportAllTable(rows) }> = (props) => {
    const { clients, tableService, selectedRows, setSelectedRows, exportAllTable } = props;

    const columns = useMemo(() => [
        {
            id: 'selection',
            Header: ( {filteredRows} ) => (
                <input type="checkbox" checked={selectedRows.length === filteredRows.length} onChange={(e) => {
                    console.log(props)
                    if (e.target.checked) {
                        setSelectedRows([...filteredRows.map((row) => row.original)])
                    }
                    else {
                        setSelectedRows([])
                    }
                }} />
            ),
            Cell: ({ row }) => (
                <input type="checkbox" checked={selectedRows.some((client) => client.key === row.original.key)} onChange={(e) => {
                    if (e.target.checked) {
                        if (!selectedRows.some((p) => p.key === row.original.key)) {
                            selectedRows.push(row.original);
                            setSelectedRows([...selectedRows])
                        }
                    }
                    else {
                        const newSelected = selectedRows.filter((p) => p.key !== row.original.key);
                        setSelectedRows([...newSelected])
                    }
                }} />
            ),
        },
        tableService.getKeyColumn(),
        tableService.getFirstNameColumn(),
        tableService.getLastNameColumn(),
        tableService.getInterviewDateColumn(),
        tableService.getExitDateColumn(),
        tableService.getAmmStatusColumn(),
        tableService.getQualificationColumn(),
        tableService.getEntryLocationColumn(),
        tableService.getEntryAdditionalinfoColumn(),
        tableService.getModifiedDateColumn()
    ], [props, selectedRows, setSelectedRows, tableService]);

    const sortBy = [
        { id: 'modified', desc: true }
    ];

    return (
        <Table data={clients} columns={columns} sortBy={sortBy} exportAllTable={exportAllTable} />
    )
};
