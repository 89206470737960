import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSortableData } from "../../../services/sortableData.service"
import { SortButton } from "../../SortButton"
import { IConsultantFunction } from "../../../core/cm.types"
import appService from "../../../services/app.service";
import { LocationAwareButton } from "../../LocationAwareButton";
import { useTranslation } from "react-i18next";
import { NoAdminAlert } from "../../NoAdminAlert"
import { DetailsOverview } from "../base/DetailsOverview";

export const ConsultantFunctionOverview: React.FunctionComponent<{ isAdmin: boolean }> = (props) => {
    const { isAdmin } = props;
    const { t } = useTranslation();
    const title = t("client:headers:tabConsDepFunction");
    const detailsRedirection = "/config/consultantFunctions/details";

    if (isAdmin !== true) { return <NoAdminAlert /> }

    return (
        <DetailsOverview detailsRedirection={detailsRedirection} getItems={appService.getConsultantFunctions} entityType="consultantFunctions" title={title}></DetailsOverview>
    );
};
