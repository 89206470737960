
import { Auth } from "./auth/auth.service";
import {
  IClient,
  IAlk,
  IEmployeeFunction,
  IConsultant,
  IConsultantFunction,
  IBaseDetailsItem,
  IOfficeLocation,
  IActivity,
  IImageResponse,
  IEmployee,
  IConsultantLocation,
  IConsultantDepartment,
  ILocationInfo,
  IFolderStructure,
  IMonthPresence,
  IFreeday,
  IApplication,
  IExpense
} from "../core/cm.types";

interface IAppEnabledResponse {
  enabled: boolean,
  errorCode: string,
}

class AppService {
  private location: string | undefined = undefined;

  setDefaultLocation = (location: string) => {
    this.location = location
  }

  getLocation = () => {
    return this.location;
  }

  /*
  setUserPermissions = (id: string, currentLocation: string, locationList: IOfficeLocation[], employees: IEmployee[]) => {
    this.userKey = id;
  }

  getUserKey = () => {
    return this.userKey;
  }

  setUserIsAdmin = (admin: boolean) => {
    this.userIsAdmin = admin;
  }

  getUserIsAdmin = () => {
    return this.userIsAdmin;
  }

  setUserIsMemberOfTheseLocations = (userIsMemberOf: string) => {
    this.usersLocations = userIsMemberOf;
  }

  getUserIsMemberOfTheseLocations = () => {
    return this.usersLocations;
  }

  setDataAdminOfLocation = (dataAdminOfLoc: string) => {
    this.usersDataAdminOfLocation = dataAdminOfLoc;
  }

  getDataAdminOfLocation = () => {
    return this.usersDataAdminOfLocation;
  }
  */

  /*
  userisMember = () => {
    if (this.location !== undefined && this.location !== "" && this.usersLocations !== undefined && this.usersLocations !== "") {
      return this.usersLocations.includes(this.location);
    }
    return false;
  }

  userisDataAdmin = () => {
    if (this.location !== undefined && this.location !== "" && this.usersDataAdminOfLocation !== undefined && this.usersDataAdminOfLocation !== "") {
      return this.usersDataAdminOfLocation.includes(this.location);
    }
    return false;
  }
*/


  fetchWithHeaders = async (
    url: string,
    options?: RequestInit
  ): Promise<Response> => {
    options = options || {};
    options.headers = options.headers || new Headers();

    let header = options.headers as Headers;
    if (header) {
      header.append("stw-location", this.location);
    }
    const authService = Auth.getInstance();
    const config = authService.getConfig();
    let token = await authService.getToken([config.app.appScope]);
    const route = config.app.apiEndPoint + `/${url}`;

    return authService.fetchWithToken(route, token, options);
  };


  isAppEnabled = async (): Promise<IAppEnabledResponse> => {
    return new Promise<IAppEnabledResponse>(async (resolve, reject) => {
      const noAccess: IAppEnabledResponse = {
        enabled: false,
        errorCode: "APP-NOAccess-10000",
      };

      try {
        return this.fetchWithHeaders("isAppEnabled", {
          method: "GET"
        })
          .then((response) => {
            if (response.ok) {
              response.json().then((jsonResult: IAppEnabledResponse) => {
                resolve(jsonResult);
              });
            } else {
              reject(noAccess);
            }
          })
          .catch((error) => {
            reject(noAccess);
          });
      }
      catch (ex) {
        reject(noAccess);
      }
    });
  };

  public delEntity = async (entity: string, key: string): Promise<null> => {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await this.fetchWithHeaders(`${entity}`, {
          method: "DELETE",
          body: JSON.stringify({
            id: key
          }),
        });
        resolve(null);
      } catch (exception) {
        console.error(exception);
        reject();
      }
    });
  };

  public delEntityByCustomerId = async (clientId: string, entity: string, key: string): Promise<null> => {
    return new Promise(async (resolve, reject) => {
      try {
        var headers = new Headers();
        headers.append("stw-customer-id", clientId)

        let result = await this.fetchWithHeaders(`${entity}`, {
          method: "DELETE",
          headers: headers,
          body: JSON.stringify({
            id: key
          }),
        });
        resolve(null);
      } catch (exception) {
        console.error(exception);
        reject();
      }
    });
  };


  getSharePointFolderStructure = async (): Promise<IFolderStructure> => {
    return new Promise<IFolderStructure>(async (resolve, reject) => {
      const nolocations: IFolderStructure = null

      try {
        return this.fetchWithHeaders("sharepointfolderstructure", {
          method: "GET"
        })
          .then((response) => {
            if (response.ok) {
              response.json().then((jsonResult: IFolderStructure) => {
                resolve(jsonResult);
              });
            } else {
              reject(nolocations);
            }
          })
          .catch((error) => {
            reject(nolocations);
          });
      }
      catch (ex) {
        reject(nolocations);
      }
    });
  };

  getOfficeLocations = async (): Promise<IOfficeLocation[]> => {
    return new Promise<IOfficeLocation[]>(async (resolve, reject) => {
      const nolocations: IOfficeLocation[] = []

      try {
        return this.fetchWithHeaders("officeLocations", {
          method: "GET"
        })
          .then((response) => {
            if (response.ok) {
              response.json().then((jsonResult: IOfficeLocation[]) => {
                resolve(jsonResult);
              });
            } else {
              reject(nolocations);
            }
          })
          .catch((error) => {
            reject(nolocations);
          });
      }
      catch (ex) {
        reject(nolocations);
      }
    });
  };

  public getOfficeLocation = async (key: string): Promise<IOfficeLocation> => {
    return new Promise<IOfficeLocation>(async (resolve, reject) => {
      try {
        let results = await this.fetchWithHeaders(`officeLocations/${key}`, {
          method: "GET",
        });
        resolve((await results.json()) as IOfficeLocation);
      } catch (exception) {
        console.error(exception);
        reject(null);
      }
    });
  };

  public saveOfficeLocation = async (
    client: IOfficeLocation
  ): Promise<IOfficeLocation | null> => {
    return new Promise<IOfficeLocation | null>(async (resolve, reject) => {
      try {
        let response = await this.fetchWithHeaders("officeLocations", {
          method: "POST",
          body: JSON.stringify(client),
        });

        var results = await response.json();

        if (response.ok) {
          resolve(results);
        } else {
          reject(results);
        }
      } catch (exception) {
        console.error(exception);
        reject(null);
      }
    });
  };

  getPresences = async (): Promise<IMonthPresence[]> => {
    return new Promise<IMonthPresence[]>(async (resolve, reject) => {
      const nolocations: IMonthPresence[] = []

      try {
        return this.fetchWithHeaders("presences", {
          method: "GET"
        })
          .then((response) => {
            if (response.ok) {
              response.json().then((jsonResult: IMonthPresence[]) => {
                resolve(jsonResult);
              });
            } else {
              reject(nolocations);
            }
          })
          .catch((error) => {
            reject(nolocations);
          });
      }
      catch (ex) {
        reject(nolocations);
      }
    });
  };

  public getPresence = async (year: number, month: number): Promise<IMonthPresence> => {
    return new Promise<IMonthPresence>(async (resolve, reject) => {
      try {
        let results = await this.fetchWithHeaders(`presences/${year}/${month}`, {
          method: "GET",
        });
        resolve((await results.json()) as IMonthPresence);
      } catch (exception) {
        console.error(exception);
        reject(null);
      }
    });
  };

  public savePresence = async (
    client: IMonthPresence
  ): Promise<IMonthPresence | null> => {
    return new Promise<IMonthPresence | null>(async (resolve, reject) => {
      try {
        let response = await this.fetchWithHeaders("presences", {
          method: "POST",
          body: JSON.stringify(client),
        });

        var results = await response.json();

        if (response.ok) {
          resolve(results);
        } else {
          reject(results);
        }
      } catch (exception) {
        console.error(exception);
        reject(null);
      }
    });
  };

  getConsultantLocations = async (): Promise<IConsultantLocation[]> => {
    return new Promise<IConsultantLocation[]>(async (resolve, reject) => {
      const nolocations: IConsultantLocation[] = []

      try {
        return this.fetchWithHeaders("consultantLocations", {
          method: "GET"
        })
          .then((response) => {
            if (response.ok) {
              response.json().then((jsonResult: IConsultantLocation[]) => {
                resolve(jsonResult);
              });
            } else {
              reject(nolocations);
            }
          })
          .catch((error) => {
            reject(nolocations);
          });
      }
      catch (ex) {
        reject(nolocations);
      }
    });
  };

  public getConsultantLocation = async (key: string): Promise<IConsultantLocation> => {
    return new Promise<IConsultantLocation>(async (resolve, reject) => {
      try {
        let results = await this.fetchWithHeaders(`consultantLocations/${key}`, {
          method: "GET",
        });
        resolve((await results.json()) as IConsultantLocation);
      } catch (exception) {
        console.error(exception);
        reject(null);
      }
    });
  };

  public saveConsultantLocation = async (
    client: IConsultantLocation
  ): Promise<IConsultantLocation | null> => {
    return new Promise<IConsultantLocation | null>(async (resolve, reject) => {
      try {
        let response = await this.fetchWithHeaders("consultantLocations", {
          method: "POST",
          body: JSON.stringify(client),
        });

        var results = await response.json();

        if (response.ok) {
          resolve(results);
        } else {
          reject(results);
        }
      } catch (exception) {
        console.error(exception);
        reject(null);
      }
    });
  };

  getEmployees = async (): Promise<IEmployee[]> => {
    return new Promise<IEmployee[]>(async (resolve, reject) => {
      const nolocations: IEmployee[] = []

      try {
        return this.fetchWithHeaders("employee", {
          method: "GET"
        })
          .then((response) => {
            if (response.ok) {
              response.json().then((jsonResult: IEmployee[]) => {
                resolve(jsonResult);
              });
            } else {
              reject(nolocations);
            }
          })
          .catch((error) => {
            reject(nolocations);
          });
      }
      catch (ex) {
        console.error(ex);
        reject(nolocations);
      }
    });
  };

  public getEmployee = async (key: string): Promise<IEmployee> => {
    return new Promise<IEmployee>(async (resolve, reject) => {
      try {
        let results = await this.fetchWithHeaders(`employee/${key}`, {
          method: "GET",
        });
        resolve((await results.json()) as IEmployee);
      } catch (exception) {
        console.error(exception);
        reject(null);
      }
    });
  };

  public saveEmployee = async (
    client: IEmployee
  ): Promise<IEmployee | null> => {
    return new Promise<IEmployee | null>(async (resolve, reject) => {
      try {
        let response = await this.fetchWithHeaders("employee", {
          method: "POST",
          body: JSON.stringify(client),
        });

        var results = await response.json();

        if (response.ok) {
          resolve(results);
        } else {
          reject(results);
        }
      } catch (exception) {
        console.error(exception);
        reject(null);
      }
    });
  };


  getConsultants = async (): Promise<IConsultant[]> => {
    return new Promise<IConsultant[]>(async (resolve, reject) => {
      const nolocations: IConsultant[] = []

      try {
        return this.fetchWithHeaders("consultants", {
          method: "GET"
        })
          .then((response) => {
            if (response.ok) {
              response.json().then((jsonResult: IConsultant[]) => {
                resolve(jsonResult);
              });
            } else {
              reject(nolocations);
            }
          })
          .catch((error) => {
            reject(nolocations);
          });
      }
      catch (ex) {
        reject(nolocations);
      }
    });
  };

  public getConsultant = async (key: string): Promise<IConsultant> => {
    return new Promise<IConsultant>(async (resolve, reject) => {
      try {
        let results = await this.fetchWithHeaders(`consultants/${key}`, {
          method: "GET",
        });
        resolve((await results.json()) as IConsultant);
      } catch (exception) {
        console.error(exception);
        reject(null);
      }
    });
  };

  getApplications = async (clientId: string): Promise<IApplication[]> => {
    return new Promise<IApplication[]>(async (resolve, reject) => {
      const nolocations: IApplication[] = []

      try {
        var headers = new Headers();
        headers.append("stw-customer-id", clientId)

        return this.fetchWithHeaders("applications", {
          method: "GET",
          headers: headers
        })
          .then((response) => {
            if (response.ok) {
              response.json().then((jsonResult: IApplication[]) => {
                resolve(jsonResult);
              });
            } else {
              reject(nolocations);
            }
          })
          .catch((error) => {
            reject(nolocations);
          });
      }
      catch (ex) {
        reject(nolocations);
      }
    });
  };

  public getApplication = async (clientId: string, key: string): Promise<IApplication> => {
    return new Promise<IApplication>(async (resolve, reject) => {
      try {
        var headers = new Headers();
        headers.append("stw-customer-id", clientId)

        let results = await this.fetchWithHeaders(`applications/${key}`, {
          method: "GET",
          headers: headers
        });
        resolve((await results.json()) as IApplication);
      } catch (exception) {
        console.error(exception);
        reject(null);
      }
    });
  };

  public saveApplication = async (
    clientId: string, client: IApplication
  ): Promise<IApplication | null> => {
    return new Promise<IApplication | null>(async (resolve, reject) => {
      try {
        var headers = new Headers();
        headers.append("stw-customer-id", clientId)

        let response = await this.fetchWithHeaders("applications", {
          method: "POST",
          body: JSON.stringify(client),
          headers: headers
        });

        var results = await response.json();

        if (response.ok) {
          resolve(results);
        } else {
          reject(results);
        }
      } catch (exception) {
        console.error(exception);
        reject(null);
      }
    });
  };

  getLocationWideActivities = async (): Promise<IActivity[]> => {
    return new Promise<IActivity[]>(async (resolve, reject) => {
      const nolocations: IActivity[] = []

      try {
        return this.fetchWithHeaders("activities/EnumerateActivities", {
          method: "GET"
        })
          .then((response) => {
            if (response.ok) {
              response.json().then((jsonResult: IActivity[]) => {
                resolve(jsonResult);
              });
            } else {
              reject(nolocations);
            }
          })
          .catch((error) => {
            reject(nolocations);
          });
      }
      catch (ex) {
        reject(nolocations);
      }
    });
  };

  getActivities = async (clientId: string): Promise<IActivity[]> => {
    return new Promise<IActivity[]>(async (resolve, reject) => {
      const nolocations: IActivity[] = []

      try {
        var headers = new Headers();
        headers.append("stw-customer-id", clientId)

        return this.fetchWithHeaders("activities", {
          method: "GET",
          headers: headers
        })
          .then((response) => {
            if (response.ok) {
              response.json().then((jsonResult: IActivity[]) => {
                resolve(jsonResult);
              });
            } else {
              reject(nolocations);
            }
          })
          .catch((error) => {
            reject(nolocations);
          });
      }
      catch (ex) {
        reject(nolocations);
      }
    });
  };

  public getActivity = async (clientId: string, key: string): Promise<IActivity> => {
    return new Promise<IActivity>(async (resolve, reject) => {
      try {
        var headers = new Headers();
        headers.append("stw-customer-id", clientId)

        let results = await this.fetchWithHeaders(`activities/${key}`, {
          method: "GET",
          headers: headers
        });
        resolve((await results.json()) as IConsultant);
      } catch (exception) {
        console.error(exception);
        reject(null);
      }
    });
  };

  public saveActivity = async (
    clientId: string, client: IActivity
  ): Promise<IActivity | null> => {
    return new Promise<IActivity | null>(async (resolve, reject) => {
      try {
        var headers = new Headers();
        headers.append("stw-customer-id", clientId)

        let response = await this.fetchWithHeaders("activities", {
          method: "POST",
          body: JSON.stringify(client),
          headers: headers
        });

        var results = await response.json();

        if (response.ok) {
          resolve(results);
        } else {
          reject(results);
        }
      } catch (exception) {
        console.error(exception);
        reject(null);
      }
    });
  };

  public saveConsultant = async (
    client: IConsultant
  ): Promise<IConsultant | null> => {
    return new Promise<IConsultant | null>(async (resolve, reject) => {
      try {
        let response = await this.fetchWithHeaders("consultants", {
          method: "POST",
          body: JSON.stringify(client),
        });

        var results = await response.json();

        if (response.ok) {
          resolve(results);
        } else {
          reject(results);
        }
      } catch (exception) {
        console.error(exception);
        reject(null);
      }
    });
  };

  getResidencePermits = async (): Promise<IBaseDetailsItem[]> => {
    return new Promise<IBaseDetailsItem[]>(async (resolve, reject) => {
      resolve(await this.getBaseItems("residencepermits"));
    });
  };

  getResidencePermitsById = async (key: string): Promise<IBaseDetailsItem> => {
    return new Promise<IBaseDetailsItem>(async (resolve, reject) => {
      resolve(await this.getBaseItem("residencepermits", key));
    });
  };

  saveResidencePermits = async (client: IBaseDetailsItem): Promise<IBaseDetailsItem> => {
    return new Promise<IBaseDetailsItem>(async (resolve, reject) => {
      resolve(await this.saveBaseItem("residencepermits", client));
    });
  };

  getAdditionalInfo = async (): Promise<IBaseDetailsItem[]> => {
    return new Promise<IBaseDetailsItem[]>(async (resolve, reject) => {
      resolve(await this.getBaseItems("additionalinfo"));
    });
  };

  getAdditionalInfoById = async (key: string): Promise<IBaseDetailsItem> => {
    return new Promise<IBaseDetailsItem>(async (resolve, reject) => {
      resolve(await this.getBaseItem("additionalinfo", key));
    });
  };

  saveAdditionalInfo = async (client: IBaseDetailsItem): Promise<IBaseDetailsItem> => {
    return new Promise<IBaseDetailsItem>(async (resolve, reject) => {
      resolve(await this.saveBaseItem("additionalinfo", client));
    });
  };

  getEtlSolutions = async (): Promise<IBaseDetailsItem[]> => {
    return new Promise<IBaseDetailsItem[]>(async (resolve, reject) => {
      resolve(await this.getBaseItems("etlsolutions"));
    });
  };

  getEtlSolutionsById = async (key: string): Promise<IBaseDetailsItem> => {
    return new Promise<IBaseDetailsItem>(async (resolve, reject) => {
      resolve(await this.getBaseItem("etlsolutions", key));
    });
  };

  saveEtlSolutions = async (client: IBaseDetailsItem): Promise<IBaseDetailsItem> => {
    return new Promise<IBaseDetailsItem>(async (resolve, reject) => {
      resolve(await this.saveBaseItem("etlsolutions", client));
    });
  };

  getQualification = async (): Promise<IBaseDetailsItem[]> => {
    return new Promise<IBaseDetailsItem[]>(async (resolve, reject) => {
      resolve(await this.getBaseItems("qualifications"));
    });
  };

  getQualificationById = async (key: string): Promise<IBaseDetailsItem> => {
    return new Promise<IBaseDetailsItem>(async (resolve, reject) => {
      resolve(await this.getBaseItem("qualifications", key));
    });
  };

  saveQualification = async (client: IBaseDetailsItem): Promise<IBaseDetailsItem> => {
    return new Promise<IBaseDetailsItem>(async (resolve, reject) => {
      resolve(await this.saveBaseItem("qualifications", client));
    });
  };

  getNationality = async (): Promise<IBaseDetailsItem[]> => {
    return new Promise<IBaseDetailsItem[]>(async (resolve, reject) => {
      resolve(await this.getBaseItems("nationalities"));
    });
  };

  getNationalityById = async (key: string): Promise<IBaseDetailsItem> => {
    return new Promise<IBaseDetailsItem>(async (resolve, reject) => {
      resolve(await this.getBaseItem("nationalities", key));
    });
  };

  saveNationality = async (client: IBaseDetailsItem): Promise<IBaseDetailsItem> => {
    return new Promise<IBaseDetailsItem>(async (resolve, reject) => {
      resolve(await this.saveBaseItem("nationalities", client));
    });
  };

  getStrategies = async (): Promise<IBaseDetailsItem[]> => {
    return new Promise<IBaseDetailsItem[]>(async (resolve, reject) => {
      resolve(await this.getBaseItems("strategies"));
    });
  };

  getStrategiesById = async (key: string): Promise<IBaseDetailsItem> => {
    return new Promise<IBaseDetailsItem>(async (resolve, reject) => {
      resolve(await this.getBaseItem("strategies", key));
    });
  };

  saveStrategies = async (client: IBaseDetailsItem): Promise<IBaseDetailsItem> => {
    return new Promise<IBaseDetailsItem>(async (resolve, reject) => {
      resolve(await this.saveBaseItem("strategies", client));
    });
  };

  getInterviewStatus = async (): Promise<IBaseDetailsItem[]> => {
    return new Promise<IBaseDetailsItem[]>(async (resolve, reject) => {
      resolve(await this.getBaseItems("interviewstatus"));
    });
  };

  getInterviewStatusById = async (key: string): Promise<IBaseDetailsItem> => {
    return new Promise<IBaseDetailsItem>(async (resolve, reject) => {
      resolve(await this.getBaseItem("interviewstatus", key));
    });
  };

  saveInterviewStatus = async (client: IBaseDetailsItem): Promise<IBaseDetailsItem> => {
    return new Promise<IBaseDetailsItem>(async (resolve, reject) => {
      resolve(await this.saveBaseItem("interviewstatus", client));
    });
  };

  getAlvStatus = async (): Promise<IBaseDetailsItem[]> => {
    return new Promise<IBaseDetailsItem[]>(async (resolve, reject) => {
      resolve(await this.getBaseItems("alvStatus"));
    });
  };

  getAlvStatusById = async (key: string): Promise<IBaseDetailsItem> => {
    return new Promise<IBaseDetailsItem>(async (resolve, reject) => {
      resolve(await this.getBaseItem("alvStatus", key));
    });
  };

  saveAlvStatus = async (client: IBaseDetailsItem): Promise<IBaseDetailsItem> => {
    return new Promise<IBaseDetailsItem>(async (resolve, reject) => {
      resolve(await this.saveBaseItem("alvStatus", client));
    });
  };

  getTerminations = async (): Promise<IBaseDetailsItem[]> => {
    return new Promise<IBaseDetailsItem[]>(async (resolve, reject) => {
      resolve(await this.getBaseItems("terminations"));
    });
  };

  getTerminationById = async (key: string): Promise<IBaseDetailsItem> => {
    return new Promise<IBaseDetailsItem>(async (resolve, reject) => {
      resolve(await this.getBaseItem("terminations", key));
    });
  };

  saveTermination = async (client: IBaseDetailsItem): Promise<IBaseDetailsItem> => {
    return new Promise<IBaseDetailsItem>(async (resolve, reject) => {
      resolve(await this.saveBaseItem("terminations", client));
    });
  };

  getBaseItems = async (location: string): Promise<IBaseDetailsItem[]> => {
    return new Promise<IBaseDetailsItem[]>(async (resolve, reject) => {
      const nolocations: IBaseDetailsItem[] = []

      try {
        return this.fetchWithHeaders(location, {
          method: "GET"
        })
          .then((response) => {
            if (response.ok) {
              response.json().then((jsonResult: IBaseDetailsItem[]) => {
                resolve(jsonResult);
              });
            } else {
              reject(nolocations);
            }
          })
          .catch((error) => {
            reject(nolocations);
          });
      }
      catch (ex) {
        reject(nolocations);
      }
    });
  };

  public saveBaseItem = async (
    location: string,
    client: IBaseDetailsItem
  ): Promise<IBaseDetailsItem | null> => {
    return new Promise<IBaseDetailsItem | null>(async (resolve, reject) => {
      try {
        let response = await this.fetchWithHeaders(location, {
          method: "POST",
          body: JSON.stringify(client),
        });

        var results = await response.json();

        if (response.ok) {
          resolve(results);
        } else {
          reject(results);
        }
      } catch (exception) {
        console.error(exception);
        reject(null);
      }
    });
  };

  public getBaseItem = async (location: string, key: string): Promise<IBaseDetailsItem> => {
    return new Promise<IBaseDetailsItem>(async (resolve, reject) => {
      try {
        let results = await this.fetchWithHeaders(`${location}/${key}`, {
          method: "GET",
        });
        resolve((await results.json()) as IBaseDetailsItem);
      } catch (exception) {
        console.error(exception);
        reject(null);
      }
    });
  };

  getFreedays = async (): Promise<IFreeday[]> => {
    return new Promise<IFreeday[]>(async (resolve, reject) => {
      const nolocations: IFreeday[] = []

      try {
        return this.fetchWithHeaders("freedays", {
          method: "GET"
        })
          .then((response) => {
            if (response.ok) {
              response.json().then((jsonResult: IFreeday[]) => {
                resolve(jsonResult);
              });
            } else {
              reject(nolocations);
            }
          })
          .catch((error) => {
            reject(nolocations);
          });
      }
      catch (ex) {
        reject(nolocations);
      }
    });
  };

  public getLocationInfo = async (key: string): Promise<ILocationInfo> => {
    return new Promise<ILocationInfo>(async (resolve, reject) => {
      try {
        let results = await this.fetchWithHeaders(`locationInfo/${key}`, {
          method: "GET",
        });
        resolve((await results.json()) as ILocationInfo);
      } catch (exception) {
        console.error(exception);
        reject(null);
      }
    });
  };

  public saveLocationInfo = async (
    client: ILocationInfo
  ): Promise<ILocationInfo | null> => {
    return new Promise<ILocationInfo | null>(async (resolve, reject) => {
      try {
        let response = await this.fetchWithHeaders("locationInfo", {
          method: "POST",
          body: JSON.stringify(client),
        });

        var results = await response.json();

        if (response.ok) {
          resolve(results);
        } else {
          reject(results);
        }
      } catch (exception) {
        console.error(exception);
        reject(null);
      }
    });
  };

  getLocationInfos = async (): Promise<ILocationInfo[]> => {
    return new Promise<ILocationInfo[]>(async (resolve, reject) => {
      const nolocations: ILocationInfo[] = []

      try {
        return this.fetchWithHeaders("locationInfo", {
          method: "GET"
        })
          .then((response) => {
            if (response.ok) {
              response.json().then((jsonResult: IAlk[]) => {
                resolve(jsonResult);
              });
            } else {
              reject(nolocations);
            }
          })
          .catch((error) => {
            reject(nolocations);
          });
      }
      catch (ex) {
        reject(nolocations);
      }
    });
  };

  getAlks = async (): Promise<IAlk[]> => {
    return new Promise<IAlk[]>(async (resolve, reject) => {
      const nolocations: IAlk[] = []

      try {
        return this.fetchWithHeaders("alks", {
          method: "GET"
        })
          .then((response) => {
            if (response.ok) {
              response.json().then((jsonResult: IAlk[]) => {
                resolve(jsonResult);
              });
            } else {
              reject(nolocations);
            }
          })
          .catch((error) => {
            reject(nolocations);
          });
      }
      catch (ex) {
        reject(nolocations);
      }
    });
  };

  public getAlk = async (key: string): Promise<IAlk> => {
    return new Promise<IAlk>(async (resolve, reject) => {
      try {
        let results = await this.fetchWithHeaders(`alks/${key}`, {
          method: "GET",
        });
        resolve((await results.json()) as IAlk);
      } catch (exception) {
        console.error(exception);
        reject(null);
      }
    });
  };

  public saveAlk = async (
    client: IAlk
  ): Promise<IAlk | null> => {
    return new Promise<IAlk | null>(async (resolve, reject) => {
      try {
        let response = await this.fetchWithHeaders("alks", {
          method: "POST",
          body: JSON.stringify(client),
        });

        var results = await response.json();

        if (response.ok) {
          resolve(results);
        } else {
          reject(results);
        }
      } catch (exception) {
        console.error(exception);
        reject(null);
      }
    });
  };

  getExpenses = async (): Promise<IExpense[]> => {
    return new Promise<IExpense[]>(async (resolve, reject) => {
      const nolocations: IExpense[] = []

      try {
        return this.fetchWithHeaders("expenses", {
          method: "GET"
        })
          .then((response) => {
            if (response.ok) {
              response.json().then((jsonResult: IExpense[]) => {
                resolve(jsonResult);
              });
            } else {
              reject(nolocations);
            }
          })
          .catch((error) => {
            reject(nolocations);
          });
      }
      catch (ex) {
        reject(nolocations);
      }
    });
  };

  public getExpense = async (key: string): Promise<IExpense> => {
    return new Promise<IExpense>(async (resolve, reject) => {
      try {
        let results = await this.fetchWithHeaders(`expenses/${key}`, {
          method: "GET",
        });
        resolve((await results.json()) as IExpense);
      } catch (exception) {
        console.error(exception);
        reject(null);
      }
    });
  };

  public saveExpense = async (
    client: IExpense
  ): Promise<IExpense | null> => {
    return new Promise<IExpense | null>(async (resolve, reject) => {
      try {
        let response = await this.fetchWithHeaders("expenses", {
          method: "POST",
          body: JSON.stringify(client),
        });

        var results = await response.json();

        if (response.ok) {
          resolve(results);
        } else {
          reject(results);
        }
      } catch (exception) {
        console.error(exception);
        reject(null);
      }
    });
  };

  public getFreeday = async (key: string): Promise<IFreeday> => {
    return new Promise<IFreeday>(async (resolve, reject) => {
      try {
        let results = await this.fetchWithHeaders(`freedays/${key}`, {
          method: "GET",
        });
        resolve((await results.json()) as IFreeday);
      } catch (exception) {
        console.error(exception);
        reject(null);
      }
    });
  };

  public saveFreeday = async (
    client: IFreeday
  ): Promise<IFreeday | null> => {
    return new Promise<IFreeday | null>(async (resolve, reject) => {
      try {
        let response = await this.fetchWithHeaders("freedays", {
          method: "POST",
          body: JSON.stringify(client),
        });

        var results = await response.json();

        if (response.ok) {
          resolve(results);
        } else {
          reject(results);
        }
      } catch (exception) {
        console.error(exception);
        reject(null);
      }
    });
  };


  getEmployeeFunctions = async (): Promise<IEmployeeFunction[]> => {
    return new Promise<IEmployeeFunction[]>(async (resolve, reject) => {
      const nolocations: IEmployeeFunction[] = []

      try {
        return this.fetchWithHeaders("employeeFunctions", {
          method: "GET"
        })
          .then((response) => {
            if (response.ok) {
              response.json().then((jsonResult: IEmployeeFunction[]) => {
                resolve(jsonResult);
              });
            } else {
              reject(nolocations);
            }
          })
          .catch((error) => {
            reject(nolocations);
          });
      }
      catch (ex) {
        reject(nolocations);
      }
    });
  };

  public getEmployeeFunction = async (key: string): Promise<IEmployeeFunction> => {
    return new Promise<IEmployeeFunction>(async (resolve, reject) => {
      try {
        let results = await this.fetchWithHeaders(`employeeFunctions/${key}`, {
          method: "GET",
        });
        resolve((await results.json()) as IEmployeeFunction);
      } catch (exception) {
        console.error(exception);
        reject(null);
      }
    });
  };

  public saveEmployeeFunction = async (
    client: IEmployeeFunction
  ): Promise<IEmployeeFunction | null> => {
    return new Promise<IEmployeeFunction | null>(async (resolve, reject) => {
      try {
        let response = await this.fetchWithHeaders("employeeFunctions", {
          method: "POST",
          body: JSON.stringify(client),
        });

        var results = await response.json();

        if (response.ok) {
          resolve(results);
        } else {
          reject(results);
        }
      } catch (exception) {
        console.error(exception);
        reject(null);
      }
    });
  };

  getConsultantDepartments = async (): Promise<IConsultantDepartment[]> => {
    return new Promise<IConsultantDepartment[]>(async (resolve, reject) => {
      const nolocations: IConsultantDepartment[] = []

      try {
        return this.fetchWithHeaders("consultantdepartments", {
          method: "GET"
        })
          .then((response) => {
            if (response.ok) {
              response.json().then((jsonResult: IConsultantDepartment[]) => {
                resolve(jsonResult);
              });
            } else {
              reject(nolocations);
            }
          })
          .catch((error) => {
            reject(nolocations);
          });
      }
      catch (ex) {
        reject(nolocations);
      }
    });
  };

  public getConsultantDepartment = async (key: string): Promise<IConsultantDepartment> => {
    return new Promise<IConsultantDepartment>(async (resolve, reject) => {
      try {
        let results = await this.fetchWithHeaders(`consultantdepartments/${key}`, {
          method: "GET",
        });
        resolve((await results.json()) as IConsultantDepartment);
      } catch (exception) {
        console.error(exception);
        reject(null);
      }
    });
  };

  public saveConsultantDepartments = async (
    client: IConsultantDepartment
  ): Promise<IConsultantDepartment | null> => {
    return new Promise<IConsultantDepartment | null>(async (resolve, reject) => {
      try {
        let response = await this.fetchWithHeaders("consultantdepartments", {
          method: "POST",
          body: JSON.stringify(client),
        });

        var results = await response.json();

        if (response.ok) {
          resolve(results);
        } else {
          reject(results);
        }
      } catch (exception) {
        console.error(exception);
        reject(null);
      }
    });
  };


  getConsultantFunctions = async (): Promise<IConsultantFunction[]> => {
    return new Promise<IConsultantFunction[]>(async (resolve, reject) => {
      const nolocations: IConsultantFunction[] = []

      try {
        return this.fetchWithHeaders("consultantfunctions", {
          method: "GET"
        })
          .then((response) => {
            if (response.ok) {
              response.json().then((jsonResult: IConsultantFunction[]) => {
                resolve(jsonResult);
              });
            } else {
              reject(nolocations);
            }
          })
          .catch((error) => {
            reject(nolocations);
          });
      }
      catch (ex) {
        reject(nolocations);
      }
    });
  };

  public getConsultantFunction = async (key: string): Promise<IConsultantFunction> => {
    return new Promise<IConsultantFunction>(async (resolve, reject) => {
      try {
        let results = await this.fetchWithHeaders(`consultantfunctions/${key}`, {
          method: "GET",
        });
        resolve((await results.json()) as IConsultantFunction);
      } catch (exception) {
        console.error(exception);
        reject(null);
      }
    });
  };

  public saveConsultantFunction = async (
    client: IConsultantFunction
  ): Promise<IConsultantFunction | null> => {
    return new Promise<IConsultantFunction | null>(async (resolve, reject) => {
      try {
        let response = await this.fetchWithHeaders("consultantfunctions", {
          method: "POST",
          body: JSON.stringify(client),
        });

        var results = await response.json();

        if (response.ok) {
          resolve(results);
        } else {
          reject(results);
        }
      } catch (exception) {
        console.error(exception);
        reject(null);
      }
    });
  };

  searchClients = async (search: string): Promise<IClient[]> => {
    return new Promise<IClient[]>(async (resolve, reject) => {
      const nolocations: IClient[] = []

      try {
        return this.fetchWithHeaders(`clients/search?query=${search}`, {
          method: "GET"
        })
          .then((response) => {
            if (response.ok) {
              response.json().then((jsonResult: IClient[]) => {
                resolve(jsonResult);
              });
            } else {
              reject(nolocations);
            }
          })
          .catch((error) => {
            reject(nolocations);
          });
      }
      catch (ex) {
        reject(nolocations);
      }
    });
  };

  getClients = async (): Promise<IClient[]> => {
    return new Promise<IClient[]>(async (resolve, reject) => {
      const nolocations: IClient[] = []

      try {
        return this.fetchWithHeaders("clients", {
          method: "GET"
        })
          .then((response) => {
            if (response.ok) {
              response.json().then((jsonResult: IClient[]) => {
                resolve(jsonResult);
              });
            } else {
              reject(nolocations);
            }
          })
          .catch((error) => {
            reject(nolocations);
          });
      }
      catch (ex) {
        reject(nolocations);
      }
    });
  };

  public getClient = async (key: string): Promise<IClient> => {
    return new Promise<IClient>(async (resolve, reject) => {
      try {
        let results = await this.fetchWithHeaders(`clients/${key}`, {
          method: "GET",
        });
        resolve((await results.json()) as IClient);
      } catch (exception) {
        console.error(exception);
        reject(null);
      }
    });
  };

  public saveClient = async (
    client: IClient
  ): Promise<IClient | null> => {
    return new Promise<IClient | null>(async (resolve, reject) => {
      try {
        let response = await this.fetchWithHeaders("clients", {
          method: "POST",
          body: JSON.stringify(client),
        });

        var results = await response.json();

        if (response.ok) {
          resolve(results);
        } else {
          reject(results);
        }
      } catch (exception) {
        console.error(exception);
        reject(null);
      }
    });
  };

  public uploadImage = async (file: any,): Promise<IImageResponse | null> => {
    return new Promise<IImageResponse>(async (resolve, reject) => {
      try {
        const auth = Auth.getInstance();
        const config = auth.getConfig();
        let token = await auth.getToken([config.app.appScope]);
        const endPointUrl = config.app.apiEndPoint + "/images";

        const formData = new FormData();
        formData.append("file", file as string | Blob);

        let options: RequestInit = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          method: "POST",
          body: formData,
        };

        let results = await fetch(endPointUrl, options);
        let result = (await results.json()) as IImageResponse;

        resolve(result);
      } catch (exception) {
        console.error(exception);
        reject(null);
      }
    });
  };






}

export default new AppService();
