import React from "react";
import { IClient } from "../../core/cm.types"
import { Table } from "../table/Table"
import { useMemo } from "react";
import { TableService } from "../../services/table.service";
import moment from "moment";

export const InProgram: React.FunctionComponent<{ suffix: any, postfix: any, clients: IClient[], tableService: TableService, exportAllTable(rows) }> = (props) => {
    const { clients, suffix, postfix, tableService, exportAllTable } = props;

    //const today = (new Date()).getTime();
    const thisDay = moment(new Date()).startOf('day');

    const primaryInterviewStatusId = tableService.getPrimaryInterviewStatusId();
    const data = clients.filter((client) => {
        if (client?.entry?.date === undefined || client?.exit?.date === undefined)
            return false;

        const entryDate = Date.parse(client.entry.date);
        //const exitDate = Date.parse(client.exit.date);
        const entry = moment(client.entry.date).startOf('day');
        const exit = moment(client.exit.date).startOf('day');
        if (client?.firstName ==="Daniel" && client?.lastName === "Marti") {
            console.log(`${client.firstName}` + ' ' + `${client.lastName}`);
            console.log('entryDate' + ` ${entry}`);
            console.log('exitDate' + ` ${exit}`);
            console.log('today' + ` ${thisDay}`);
            console.log('result' + ` ${(entry <= thisDay && exit >= thisDay)}`);
        }
        return client.interview?.statusId === primaryInterviewStatusId &&
            entry <= thisDay &&
            exit >= thisDay;
    });

    const columns = useMemo(() => [
        tableService.getKeyColumn(),
        tableService.getFirstNameColumn(),
        tableService.getLastNameColumn(),
        tableService.getCity(),
        tableService.getBirthdayColumn(),
        tableService.getPensumColumn(),
        tableService.getEntryEmployeeColumn(),
        tableService.getConsultantColumn(),
        tableService.getEntryDateColumn(),
        tableService.getExitDateColumn(),
        tableService.getQualificationColumn(),
        tableService.getEntryLocationColumn(),
        tableService.getAmmStatusColumn(),
        tableService.getEntryAdditionalinfoColumn(),
    ], [tableService]);

    const sortBy = [
        { id: 'entry.date', desc: true }
    ];

    return (
        <Table data={data} columns={columns} columnssuffix={suffix} columnspostfix={postfix} sortBy={sortBy} exportAllTable={exportAllTable} />
    )
};
