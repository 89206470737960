import React from "react";
import { useAsyncDebounce } from 'react-table'

export const TableGlobalFilter: React.FunctionComponent<{ globalFilter, setGlobalFilter }> = (props) => {
  const { globalFilter, setGlobalFilter } = props;

  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <input className="input is-small" value={value || ""}
      onChange={e => {
        setValue(e.target.value);
        onChange(e.target.value);
      }} />
  )
}
