import React from 'react';
import { useHistory } from 'react-router-dom';
import appService from "../services/app.service";
import { NoAdminAlert } from "./NoAdminAlert"

export const NoAdminCheck: React.FunctionComponent<{ isAdmin?: boolean }> = ( props ) => {
  const { isAdmin } = props;

  if (isAdmin !== true) { return <NoAdminAlert /> }

  return ( <div>{props.children}</div> );
};
