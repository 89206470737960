import React from "react";
import { Formik } from "formik";
import appService from "../../services/app.service";
import { Loading } from "../Loading";
import * as Yup from "yup";
import { IAlk, IBaseDetailsItem, IClient, IConsultant, IEmployee } from "../../core/cm.types";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import 'react-tabs/style/react-tabs.css';
import { MasterData } from "./MasterData"
import { Interview } from "./Interview"
import { Entry } from "./Entry"
import { Exit } from "./Exit"
import { AMMData } from "./AMMData"
import { Start } from "./Start"
import { FileExplorer } from "../FileExplorer/FileExplorer";
import { Activities } from "./activities/Activities"
import { UploadModalBox } from "../images/UploadModalBox"
import { useTranslation } from "react-i18next";
import { Applications } from "./applications/Applications"
import { LocationAwareButton } from "../LocationAwareButton";

const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("*"),
    lastName: Yup.string().required("*"),
    //personId: Yup.string().required("*").max(8, 'Maximal 8 Ziffern'), //.transform(value => value.replace(/[^\d]/g, '')),
    pensum: Yup.number().max(100, 'Die Zahl darf nicht größer als 100 sein').min(0, 'Die Zahl darf nicht kleiner als 0 sein').typeError("Bitte eine gültige Zahl angeben").nullable(),
    entry: Yup.object().shape({
        date: Yup.date().typeError("Bitte ein gültiges Datum angeben").required("*"),
    }).required(),
    // exit date must be mandatory - Daniel Marti in Call on 05.06.2023
    exit: Yup.object().shape({
        date: Yup.date().typeError("Bitte ein gültiges Datum angeben").required("*"),
    }).required(),
    personId: Yup.string().required("*").max(8, 'Maximal 8 Ziffern').test("valid", "Ungültige Sozialversicherungsnummer",
        (val) => {
            if (val === undefined || val === "") return false;
            const len = val.replace(".", "").length;
            return len === 8;
        }
    )
});

export const ClientDetails: React.FunctionComponent<{ onSubmit: any, clientDriveId: string, detailId: string | undefined; locationInfo: IBaseDetailsItem[], strategy: IBaseDetailsItem[], termination: IBaseDetailsItem[], etlSolutions: IBaseDetailsItem[], alvStatus: IBaseDetailsItem[], additionalInfo: IBaseDetailsItem[], interviewStatus: IBaseDetailsItem[], qualifications: IBaseDetailsItem[], residencePermit: IBaseDetailsItem[], alk: IAlk[], consultants: IConsultant[], employee: IEmployee[], countries: IBaseDetailsItem[], clients: IClient[] }> = (props) => {
    const { t } = useTranslation();

    const { onSubmit, clients, clientDriveId, detailId, employee, countries, residencePermit, consultants, alk, interviewStatus, additionalInfo, qualifications, termination, etlSolutions, alvStatus, strategy, locationInfo } = props;
    const isNew = detailId === undefined || detailId === null;
    // const selectedTab = isNew === true ? 1 : 0;
    const [selectedTab, setSelectedTab] = React.useState<number>(1);
    const [data, setData] = React.useState<{
        isLoading: boolean;
        client: IClient;
        isEditing: boolean
    }>({
        isLoading: true,
        client: {},
        isEditing: isNew === false
    });

    const isReadOnly = false;

    React.useEffect(() => {
        setData({
            isLoading: true,
            client: {},
            isEditing: false
        })
        const fetchData = async () => {
            const client = await appService.getClient(detailId);
            setData({
                isLoading: false,
                client: client,
                isEditing: true
            });
            setSelectedTab(isNew === true ? 1 : 0);
        };

        if (detailId !== undefined) {
            fetchData();
        }
        else {
            //const status = interviewStatus.filter(p => p.title === "Einladen bei nächster Möglichkeit")
            setData({
                isLoading: false,
                client: {
                    interview: {
                        //statusId: (status.length > 0) ? status[0].key : undefined
                    }
                },
                isEditing: false
            })
            setSelectedTab(isNew === true ? 1 : 0);
        }

    }, [isNew, detailId]);

    const validate = async (values: IClient): Promise<any> => {
        const errors: any = {};

        const usesPersonId = clients.some((p) => p.personId === values.personId && p.key !== values.key);
        if (usesPersonId) {
            errors["personId"] = "Nummer wird bereits genutzt"
        }
        return errors;
      };

    if (data.isLoading)
        return <Loading isLoading={true}></Loading>

    return (
        <React.Fragment>
            <div className="section defaultSection">
                <Formik key={detailId}
                    initialValues={data.client}
                    onSubmit={onSubmit}
                    validateOnMount={true}
                    validationSchema={validationSchema}
                    validate={validate}
                    enableReinitialize={true}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        isValid,
                        dirty,
                        setFieldValue,
                    }) => (
                        <React.Fragment>
                            <form onSubmit={handleSubmit} className="form">
                                <div className="columns">
                                    <div className="column"><h1 className="title is-3">{data.isEditing ? values.firstName + ' ' + values.lastName : t("client:newClient")}</h1></div>
                                    <div className="column button-header">
                                        <button
                                            type="submit"
                                            className={`button is-link is-pulled-right is-small backAndSaveButton ${isSubmitting ? t("client:common:isLoading") : ""
                                                }`}
                                            disabled={isSubmitting || !dirty || !isValid}
                                        >
                                            {t("client:common:saveButton")}
                                        </button>
                                        <LocationAwareButton title={t("client:common:backButton")} className="button is-pulled-right is-small backAndSaveButton" relativeLocation={"/"}></LocationAwareButton>
                                    </div>
                                </div>
                                <div className="columns">
                                    <div className="column">
                                        <Tabs selectedIndex={selectedTab} onSelect={tabIndex => setSelectedTab(tabIndex)}>
                                            <TabList>
                                                <Tab>{t("client:headers:tabStart")}</Tab>
                                                <Tab>{t("client:headers:tabMaster")}</Tab>
                                                <Tab>{t("client:headers:tabInterview")}</Tab>
                                                <Tab>{t("client:headers:tabEntry")}</Tab>
                                                <Tab>{t("client:headers:tabExit")}</Tab>
                                                <Tab>{t("client:headers:tabAMM")}</Tab>
                                            </TabList>
                                            <TabPanel>
                                                <div className="photoBox">
                                                    {(values?.photoUrl === undefined) &&
                                                        <img src="/img/placeholder.jpg" className="clientImage" alt="" />
                                                    }
                                                    <img src={values?.photoUrl} className="clientImage" alt="" />
                                                    <div>
                                                        <UploadModalBox label="Image" field="photoUrl" width={400} height={400} setFieldValue={setFieldValue} />
                                                    </div>
                                                </div>
                                                <Start employees={employee} consultants={consultants} isReadOnly={true} client={values} clientDriveId={clientDriveId} />
                                            </TabPanel>
                                            <TabPanel>
                                                <MasterData setFieldValue={setFieldValue} alk={alk} consultants={consultants} residencePermits={residencePermit} qualifications={qualifications} countries={countries} isReadOnly={isReadOnly} client={values} />
                                            </TabPanel>
                                            <TabPanel>
                                                <Interview employee={employee} isReadOnly={isReadOnly} interviewStatus={interviewStatus} client={values} />
                                            </TabPanel>
                                            <TabPanel>
                                                <Entry employee={employee} additionalInfo={additionalInfo} isReadOnly={isReadOnly} client={values} locationInfo={locationInfo} />
                                            </TabPanel>
                                            <TabPanel>
                                                <Exit strategies={strategy} etlSolutions={etlSolutions} alvStatus={alvStatus} isReadOnly={isReadOnly} client={values} />
                                            </TabPanel>
                                            <TabPanel>
                                                <AMMData termination={termination} isReadOnly={isReadOnly} client={values} />
                                            </TabPanel>
                                        </Tabs>
                                    </div>
                                    <div className="column is-two-thirds">
                                        <Tabs>
                                            <TabList>
                                                <Tab>{t("client:headers:tabActivities")}</Tab>
                                                <Tab>PE</Tab>
                                                <Tab>{t("client:headers:tabData")}</Tab>
                                            </TabList>
                                            <TabPanel>
                                                <Activities clientId={data.client.key} employee={employee} />
                                            </TabPanel>
                                            <TabPanel>
                                                <Applications clientId={data.client.key} employee={employee} />
                                            </TabPanel>
                                            <TabPanel>
                                                <FileExplorer clientDriveId={clientDriveId} basePath={data.client.sharePointFolder} />
                                            </TabPanel>
                                        </Tabs>
                                    </div>
                                </div>
                                <div className="button-footer">
                                    <button
                                        type="submit"
                                        className={`button is-link is-pulled-right is-small backAndSaveButton ${isSubmitting ? t("client:common:isLoading") : ""
                                            }`}
                                        disabled={isSubmitting || !dirty || !isValid}
                                    >
                                        {t("client:common:saveButton")}
                                    </button>
                                    <LocationAwareButton title={t("client:common:backButton")} className="button is-small is-pulled-right backAndSaveButton" relativeLocation={"/"}></LocationAwareButton>
                                </div>
                            </form>
                        </React.Fragment>
                    )}
                </Formik>
            </div>
        </React.Fragment>
    );
};
