import React from 'react';
import { useTranslation } from "react-i18next";

export const AccessDenied: React.FunctionComponent<{isAccessDenied: boolean}> = (props) => {
  const { isAccessDenied } = props;
  const { t } = useTranslation();
  if (isAccessDenied === false) {
    return <React.Fragment></React.Fragment>;
  }
  return (
    <article className="message is-danger">
      <div className="message-header">
        <p>Access Denied</p>
        <button className="delete" aria-label="delete"></button>
      </div>
      <div className="message-body">
        {t("client:common:AccessDenied1")}
      </div>
    </article>
  );
};
