import React from "react";
import { IActivity, IClient } from "../../core/cm.types"
import { Table } from "../table/Table"
import { useMemo } from "react";
import { TableService } from "../../services/table.service";

export const Activities: React.FunctionComponent<{ suffix: any, postfix: any, clients: IClient[], activities: IActivity[], tableService: TableService, exportAllTable(rows) }> = (props) => {
    const { clients, suffix, postfix, tableService, activities, exportAllTable } = props;

    const columns = useMemo(() => [
        tableService.getKeyColumn(),
        tableService.getFirstNameColumn(),
        tableService.getLastNameColumn(),
        tableService.getActivityDate(),
        tableService.getActivityTitle(),
        tableService.getActivityDescription(),
        tableService.getActivityAuthorColumn(),
        tableService.getActivityEntryDateColumn(),
        tableService.getActivityLeadershipColumn(),
        tableService.getActivityadditionalInfoColumn(),
        tableService.getActivityLocationInfoColumn()
    ], [tableService]);

    const data = useMemo(() => activities.map((activity) => {
        const clientIndex = clients.findIndex((client) => client.key === activity.location);
        let client = undefined;
        if (clientIndex > 0)
            client = clients[clientIndex];

        return {
            ...activity,
            firstName: client?.firstName,
            lastName: client?.lastName,
            client: client,
            key: client?.key
        }
    }), [clients.length, activities.length]);

    console.log(data)

    const sortBy = [];

    return (
        <Table data={data} columns={columns} columnssuffix={suffix} columnspostfix={postfix} sortBy={sortBy} exportAllTable={exportAllTable} />
    )
};
