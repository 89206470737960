import React from 'react';
import { Ellipsis } from 'react-spinners-css';

export const Loading: React.FunctionComponent<{ isLoading: boolean }> = (props) => {
    const { isLoading } = props;

    if (isLoading === false) {
        return <React.Fragment></React.Fragment>
    }
    return (
        <React.Fragment>
            <div
                style={{
                    position: 'absolute', left: '50%', top: '50%',
                    transform: 'translate(-50%, -50%)'
                }}
            >
                <Ellipsis color="black" size={100} />
            </div>
        </React.Fragment>
    );
};
