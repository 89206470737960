import React, { useState } from "react";
import { useSortableData } from "../../../services/sortableData.service"
import { SortButton } from "../../SortButton"
import { IOfficeLocation } from "../../../core/cm.types"
import appService from "../../../services/app.service";
import { LocationAwareButton } from "../../LocationAwareButton";
import { useTranslation } from "react-i18next";
import { NoAdminAlert } from "../../NoAdminAlert"
import { DetailsOverview } from "../base/DetailsOverview";

export const OfficelocationsOverview: React.FunctionComponent<{ isAdmin: boolean }> = (props) => {
    const { isAdmin } = props;
    const { t } = useTranslation();
    const title = t("client:headers:tabOfficeLocation");
    const detailsRedirection = "/config/officelocations/details";

    if (isAdmin !== true) { return <NoAdminAlert /> }
    const columns = [
        {
            Header: t("client:common:columnTitle"),
            accessor: 'title'
        }
    ];

    return (
        <DetailsOverview columns={columns} detailsRedirection={detailsRedirection} getItems={appService.getOfficeLocations} entityType="officelocations" title={title}></DetailsOverview>
    );
};
