import React from "react";
import appService from "../../../services/app.service";
import { DetailsOverview } from "../base/DetailsOverview";
import { useTranslation } from "react-i18next";
import { NoDataAdminAlert } from "../../NoDataAdminAlert"

export const QualificationOverview: React.FunctionComponent<{ isDataAdmin: boolean }> = (props) => {
  const { t } = useTranslation();
  const detailsRedirection = "/config/qualifications/details";
  const title = t("client:headers:tabQualification");
  const { isDataAdmin } = props;

  if (isDataAdmin !== true) {
    return <NoDataAdminAlert />
}

  return (
    <DetailsOverview detailsRedirection={detailsRedirection} getItems={appService.getQualification} entityType="qualifications" title={title}></DetailsOverview>
  );
};