import React, { useState } from "react";
import { useSortableData } from "../../../services/sortableData.service"
import { IResidencePermit } from "../../../core/cm.types"
import appService from "../../../services/app.service";
import { LocationAwareButton } from "../../LocationAwareButton";
import { SortButton } from "../../SortButton"
import { DetailsOverview } from "../base/DetailsOverview";
import { useTranslation } from "react-i18next";
import { NoAdminAlert } from "../../NoAdminAlert"

export const ResidencePermitOverview: React.FunctionComponent<{ isAdmin: boolean }> = (props) => {
    const { isAdmin } = props;
    const { t } = useTranslation();
    const title = t("client:headers:tabResidencePermit");
    const detailsRedirection = "/config/residencepermits/details";

    if (isAdmin !== true) { return <NoAdminAlert /> }

    const columns = [
        {
            Header: t("client:common:columnTitle"),
            accessor: 'title'
        },
        {
            Header: t("client:config:columnDescription"),
            accessor: 'description'
        }
    ];

    return (
        <DetailsOverview columns={columns} detailsRedirection={detailsRedirection} getItems={appService.getResidencePermits} entityType="residencepermits" title={title}></DetailsOverview>
    );
};