import React from 'react';
import { extendFieldProps } from "./Input";
import { FieldFooter } from "./FieldFooter";
import { FieldLabel } from "./FieldLabel";
import DatePicker from "react-datepicker";
import InputMask from 'react-input-mask';


export const DateField: React.FunctionComponent<any> = ({
  field,
  form,
  ...props
}) => {
  extendFieldProps(props, field, form);

  const {
    options,
    errorMessage,
    help,
    label,
    showRequired,
    selectedValue,
    checked,
    prefix,
    suffix,
    suffixControl,
    ...componentOptions
  } = props;

  const hasAddons = prefix !== undefined || suffix !== undefined || suffixControl !== undefined;
  const mainClassName = hasAddons ? "field has-addons" : "field";
  const expandedControl = hasAddons ? "control is-expanded" : "control";

  return (
    <React.Fragment>
      {hasAddons && (
        <FieldLabel label={label} showRequired={showRequired}></FieldLabel>
      )}
      <div className={mainClassName}>
        {!hasAddons && (
          <FieldLabel label={label} showRequired={showRequired}></FieldLabel>
        )}
        {prefix !== undefined && (
          <p className="control">
            <a className="button is-static">{prefix}</a>
          </p>
        )}

        <div className={expandedControl}>
          <DatePicker
            locale={"de"} 
            customInput={<InputMask mask="99.99.9999" />}
            className={`input ${errorMessage !== undefined ? "is-danger" : ""}`}
            preventOpenOnFocus={true}
            {...props} selected={(field?.value && new Date(field?.value)) || null}
            onChange={val => { 
              form?.setFieldValue(field?.name, val, true); 
              //form?.setFieldValue("dirty", true, true);       // we need to set the date twice with dirty - why did we set this?
              if (props.onChangeExecuted !== undefined) { 
                props.onChangeExecuted(val);
              } }
            }
          />
          <FieldFooter help={help} errorMessage={errorMessage}></FieldFooter>
        </div>

        {suffixControl !== undefined && (
          <React.Fragment>
            {suffixControl}
          </React.Fragment>
        )}

        {suffix !== undefined && (
          <p className="control">
            <a className="button is-static">{suffix}</a>
          </p>
        )}
      </div>
    </React.Fragment>
  );
};
