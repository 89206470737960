import React from "react";
import { Field, Formik, FormikHelpers } from "formik";
import { Input } from "../../fields/Input";
import { Loading } from "../../Loading";
import * as Yup from "yup";

import { useHistory } from "react-router-dom";
import { IAlk, IBaseDetailsItem } from "../../../core/cm.types";
import { LocationAwareButton } from "../../LocationAwareButton";
import appService from "../../../services/app.service";
import { useTranslation } from "react-i18next";

const validationSchema = Yup.object().shape({
    title: Yup.string().required("*")
});

export const DetailsBase: React.FunctionComponent<{
    id: string | undefined;
    inputTitle: string,
    backButton: string,
    getById(id: string): Promise<IBaseDetailsItem | null>,
    saveItem(id: IBaseDetailsItem): Promise<IBaseDetailsItem | null>,
    hasNumber?: boolean
}> = (props) => {
    const { t } = useTranslation();
    const { id, hasNumber } = props;
    const { backButton, inputTitle, getById, saveItem } = props;
    const isNew = id === undefined || id === null;
    const title = <h1 className="title is-3">{inputTitle}</h1>
    const history = useHistory();
    const [data, setData] = React.useState<{
        isLoading: boolean;
        data: IBaseDetailsItem;
    }>({
        isLoading: true,
        data: {},
    });

    React.useEffect(() => {
        const fetchData = async () => {
            const data: IBaseDetailsItem = await getById(id);

            setData({
                isLoading: false,
                data: data,
            });
        };

        if (isNew) {
            setData({
                isLoading: false,
                data: {},
            });
        } else {
            fetchData();
        }
    }, []);

    const onSubmit = (
        values: Readonly<IAlk | undefined>,
        formikHelpers: FormikHelpers<Readonly<IAlk>>
    ) => {
        const data = values;

        const submit = async () => {
            if (data !== undefined && data !== null) {
                saveItem(data).then((modulesResults) => {
                    var location = appService.getLocation();
                    history.push(`/${location}${backButton}`)
                }).catch(() => {
                    alert(t("client:common:errorOnSave"));
                });
            }
        };

        submit();
    };

    return (
        <div className="section defaultSection">
            <Loading isLoading={data.isLoading}></Loading>
            {data.isLoading === false && (
                <Formik
                    initialValues={data.data}
                    onSubmit={onSubmit}
                    validateOnMount={true}
                    validationSchema={validationSchema}
                    enableReinitialize
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        isValid,
                        dirty,
                        setFieldValue,
                    }) => (
                        <React.Fragment>
                            <form onSubmit={handleSubmit} className="form">
                                <div className="columns">
                                    <div className="column">{title}</div>
                                    <div className="column button-header">
                                        <button
                                            type="submit"
                                            className={`button is-link is-pulled-right is-small backAndSaveButton ${isSubmitting ? t("client:common:isLoading") : ""
                                                }`}
                                            disabled={isSubmitting || !dirty || !isValid}
                                        >
                                            {t("client:common:saveButton")}
                                        </button>
                                        <LocationAwareButton title={t("client:common:backButton")} className="button is-pulled-right is-small backAndSaveButton" relativeLocation={backButton}></LocationAwareButton>
                                    </div>
                                </div>
                                <Field
                                    name="title"
                                    label={t("client:common:columnTitle")}
                                    component={Input}
                                />
                                {hasNumber === true && (
                                    <Field
                                        name="order"
                                        label={t("client:config:columnNumber")}
                                        component={Input}
                                    />
                                )}
                                <div className="button-footer">
                                    <button
                                        type="submit"
                                        className={`button is-link is-pulled-right is-small backAndSaveButton ${isSubmitting ? t("client:common:isLoading") : ""
                                            }`}
                                        disabled={isSubmitting || !dirty || !isValid}
                                    >
                                        {t("client:common:saveButton")}
                                    </button>
                                    <LocationAwareButton title={t("client:common:backButton")} className="button is-small is-pulled-right backAndSaveButton" relativeLocation={backButton}></LocationAwareButton>
                                </div>
                            </form>
                        </React.Fragment>
                    )}
                </Formik>
            )}
        </div>
    );
};
