import React from 'react';
import { useState } from 'react';
import { IClient } from '../../core/cm.types';
import { TableService } from '../../services/table.service';
import { DocumentsInterview } from '../views/DocumentsInterview';
import { Documents } from "./Documents"

export const DocumentsInterviewContainer: React.FunctionComponent<{ clients: IClient[], tableService: TableService, clientDriveId: string, mailMergeDriveId: string, templateDriveId: string, exportAllTable(rows) }> = (props) => {
    const { clientDriveId, mailMergeDriveId, templateDriveId, tableService, clients, exportAllTable } = props;
    const title = "Dokumente - Vorgespräch"

    const [selectedRows, setSelectedRows] = useState([]);
    

    return (
        <Documents title={title} clientDriveId={clientDriveId} selectedRows={selectedRows} mailMergeDriveId={mailMergeDriveId} templateDriveId={templateDriveId} setSelectedRows={setSelectedRows}  >
            <DocumentsInterview key={selectedRows.length} setSelectedRows={setSelectedRows} selectedRows={selectedRows} clients={clients} tableService={tableService} exportAllTable={exportAllTable} />
        </Documents>
    )
};
