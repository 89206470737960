import React, { useState } from "react";
import { IConsultant } from "../../../core/cm.types"
import appService from "../../../services/app.service";
import { useTranslation } from "react-i18next";
import { NoAdminAlert } from "../../NoAdminAlert"
import { DetailsOverview } from "../base/DetailsOverview";

export const ConsultantOverview: React.FunctionComponent<{ isAdmin: boolean }> = (props) => {
    const { isAdmin } = props;
    const { t } = useTranslation();
    const title = t("client:headers:tabConsultantDetail");
    const detailsRedirection = "/config/consultants/details";

    if (isAdmin !== true) { return <NoAdminAlert /> }

    const columns = [
        {
            Header: t("client:config:columnName"),
            accessor: 'name'
        },
        {
            Header: t("client:config:columnLastName"),
            accessor: 'lastName'
        },
        {
            Header: t("client:config:columnEmail"),
            accessor: 'email'
        }
    ];

    const sortBy = [
        { id: "name", desc: false }
    ];

    const exportAllTable = async (rows) => {
        const functions = await appService.getConsultantFunctions();
        const departments = await appService.getConsultantDepartments();
        const locations = await appService.getConsultantLocations();
        return rows.map(row => {
            const item = row.original as IConsultant

            let message = {};

            message[`${t("client:config:columnFirstName")}`] = item.name
            message[`${t("client:config:columnLastName")}`] = item.lastName
            message[`${t("client:config:columnEmail")}`] = item.email
            message[`${t("client:tabMasterData:phone")}`] = item.phone
            message[`${t("client:tabConsultantDetail:function")}`] = functions.find(p => p.key === item.functionId)?.title
            message[`${t("client:config:columnDepartment")}`] = departments.find(p => p.key === item.departmentId)?.title
            message[`${t("client:tabConsDepLocation:locationName")}`] = locations.find(p => p.key === item.locationId)?.title

            return message;
        })
    };

    return (
        <DetailsOverview columns={columns} sorting={sortBy} detailsRedirection={detailsRedirection} getItems={appService.getConsultants} entityType="consultants" title={title} exportAllTable={exportAllTable}></DetailsOverview>
    );
};
