import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useSortableData } from "../../../services/sortableData.service"
import { SortButton } from "../../SortButton"
import { IAlk } from "../../../core/cm.types"
import appService from "../../../services/app.service";
import { LocationAwareButton } from "../../LocationAwareButton";
import { useTranslation } from "react-i18next";
import { NoAdminAlert } from "../../NoAdminAlert"
import { DetailsOverview } from "../base/DetailsOverview";

export const AlkOverview: React.FunctionComponent<{ isAdmin: boolean }> = (props) => {
    const { isAdmin } = props;
    const { t } = useTranslation();
    const title = t("client:headers:tabAlkDetails");
    const detailsRedirection = "/config/alks/details";

    if (isAdmin !== true) { return <NoAdminAlert /> }

    const columns = [
        {
            Header: t("client:common:columnTitle"),
            accessor: 'title'
        },
        {
            Header: t("client:config:columnAlkNummer"),
            accessor: 'alkNumber'
        }
    ];

    return (
        <DetailsOverview columns={columns} detailsRedirection={detailsRedirection} getItems={appService.getAlks} entityType="alks" title={title}></DetailsOverview>
    );
};
