import React from 'react';
import { useTranslation } from "react-i18next";

export const NoAdminAlert: React.FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <article className="message is-danger">
        <div className="message-header">
            <p>{t("client:tabAccess:noAccess")}</p>
        </div>
        <div className="message-body">
            {t("client:tabAccess:noAdmin")}
        </div>
    </article>
    );
};
