import React, { useEffect } from "react";
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination, useRowSelect } from 'react-table'
import { ColumnDefaultFilter } from "./ColumnDefaultFilter"
import { ColumnSortContainer } from "./ColumnSortContainer"
import { TableGlobalFilter } from "./TableGlobalFilter"
import { matchSorter } from 'match-sorter'
import "./Table.css"
import { useTranslation } from "react-i18next";
import CsvDownload from "react-csv-downloader";

export const Table: React.FunctionComponent<{ columns, columnssuffix?: [], columnspostfix?: [], sortBy?: { id: string, desc?: boolean }[], data, exportAllTable(rows), setSelectedRows? }> = (props) => {
  const { columns, data, columnssuffix, columnspostfix, sortBy, exportAllTable, setSelectedRows } = props;
  const suffix = columnssuffix ?? [];
  const postfix = columnspostfix ?? [];
  const { t } = useTranslation();


  if (columns === undefined || data === undefined)
    return <React.Fragment></React.Fragment>

  const fuzzyTextFilterFn = (rows, id, filterValue) => {
    return matchSorter(rows, filterValue, { keys: [row => (row as any).values[id]] })
  }


  const filterTypes = React.useMemo(
    () => ({
      // "startWith"
      fuzzyText: fuzzyTextFilterFn,
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true
        })
      }
    }),
    [])

  const defaultColumn = React.useMemo(
    () => ({
      Filter: ColumnDefaultFilter,
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    rows,
    canPreviousPage,
    visibleColumns,
    setGlobalFilter,
    canNextPage,
    pageOptions,
    state,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: {
        hiddenColumns: ["key"],
        sortBy: sortBy ?? [],
        pageSize: 50
      }
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        ...suffix,
        ...columns,
        ...postfix
      ])
    }
  )
  
  useEffect(() => {
    if (setSelectedRows !== undefined) {
      setSelectedRows(selectedFlatRows.map(row => row.original));
    }
  }, [setSelectedRows, selectedFlatRows]);
  
  const exportAllTablePromise = async () => {
    return Promise.resolve<[]>(
      await exportAllTable(rows)
    );
  };

  const exportTable = () => {
    return Promise.resolve<[]>(
      rows.map(row => {
        prepareRow(row);

        let curRow = {};
        row.cells.map(cell => {
          const header = cell.column.Header;
          //debugger
          if (header !== undefined && header.length > 0 && !(header instanceof Function)) {
            curRow[header] = (cell.value === undefined ? "" : cell.value.toString().replaceAll(",", "").replaceAll(";", "").replace(/[\n\r]+/g, ' '));
          }
        });
        return curRow;
      })
    );
  };

  return (
    <React.Fragment>
      <table {...getTableProps()} className="table is-fullwidth">
        <thead>
          <tr>
            <th colSpan={visibleColumns.length} className="noPadding">
              <div className="columns is-gapless">
                <div className="column"></div>
                <div className="column is-pulled-right">
                  <div className="field is-grouped is-pulled-right">
                    <p className="control has-icons-right is-small is-full-width">
                      <TableGlobalFilter globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter} />
                      <span className="icon is-small is-right">
                        <i className="fas fa-search"></i>
                      </span>
                    </p>
                    {(exportAllTable !== undefined && rows.length > 0) && (
                      <p className="control is-small">
                        <div className="dropdown is-hoverable is-right ">
                          <div className="dropdown-trigger">
                            <button className="button is-small" aria-haspopup="true" aria-controls="dropdown-menu4">
                              <span>{t("client:tabTable:downloadButton")}</span>
                              <span className="icon is-small">
                                <i className="fas fa-angle-down" aria-hidden="true"></i>
                              </span>
                            </button>
                          </div>
                          <div className="dropdown-menu" id="dropdown-menu4" role="menu">
                            <div className="dropdown-content">
                              <div className="dropdown-item">
                                <CsvDownload separator={";"} filename="export.csv" datas={exportTable}>
                                  <a href="#" onClick={(e) => { e.preventDefault() }} className="dropdown-item">
                                    {t("client:tabTableExport:exportTable")}
                                  </a>
                                </CsvDownload>
                              </div>
                              <div className="dropdown-item">
                                <CsvDownload separator={";"} filename="export.csv" datas={exportAllTablePromise}>
                                  <a href="#" onClick={(e) => { e.preventDefault() }} className="dropdown-item">
                                    {t("client:tabTableExport:exportAll")}
                                  </a>
                                </CsvDownload>
                              </div>
                            </div>
                          </div>
                        </div>
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </th>
          </tr>
          <tr className="pagingtop">
            <th colSpan={visibleColumns.length}>
              <div className="pagination">
                <span className="goButtons has-text-left">
                  <button className={"button is-small"} onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    {'<<'}
                  </button>
                  <button className={"button is-small"} onClick={() => previousPage()} disabled={!canPreviousPage}>
                    {'<'}
                  </button>
                  <span>&nbsp;&nbsp;<strong>{pageIndex + 1}/{pageOptions.length}</strong>&nbsp;&nbsp;</span>
                  <button className={"button is-small"} onClick={() => nextPage()} disabled={!canNextPage}>
                    {'>'}
                  </button>
                  <button className={"button is-small"} onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                    {'>>'}
                  </button>
                </span>
                <div>
                  {rows.length} Element(e)
                </div>

                <div className="select is-small">
                  <select
                    value={pageSize}
                    onChange={e => {
                      setPageSize(Number(e.target.value))
                    }}
                  >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        {pageSize}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </th>
          </tr>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>
                  <span {...column.getSortByToggleProps()}>

                    {column.render('Header')}<ColumnSortContainer column={column}></ColumnSortContainer>
                  </span>
                  <br /><br />
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={visibleColumns.length}>
              <div className="pagination">
                <span className="goButtons has-text-left">
                  <button className={"button is-small"} onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    {'<<'}
                  </button>
                  <button className={"button is-small"} onClick={() => previousPage()} disabled={!canPreviousPage}>
                    {'<'}
                  </button>
                  <span>&nbsp;&nbsp;<strong>{pageIndex + 1}/{pageOptions.length}</strong>&nbsp;&nbsp;</span>
                  <button className={"button is-small"} onClick={() => nextPage()} disabled={!canNextPage}>
                    {'>'}
                  </button>
                  <button className={"button is-small"} onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                    {'>>'}
                  </button>
                </span>
                <div>
                  {rows.length} Element(e)
                </div>

                <div className="select is-small">
                  <select
                    value={pageSize}
                    onChange={e => {
                      setPageSize(Number(e.target.value))
                    }}
                  >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        {pageSize}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </React.Fragment >
  )
}
