import React from 'react';
import { extendFieldProps } from "./Input";
import { FieldFooter } from "./FieldFooter";
import { FieldLabel } from "./FieldLabel";

export const Select: React.FunctionComponent<any> = ({
  field,
  form,
  ...props
}) => {
  extendFieldProps(props, field, form);

  const {
    options,
    errorMessage,
    help,
    label,
    showRequired,
    selectedValue,
    checked,
    prefix,
    suffix,
    suffixControl,
    ...componentOptions
  } = props;

  const hasAddons = prefix !== undefined || suffix !== undefined || suffixControl !== undefined;
  const mainClassName = hasAddons ? "field has-addons" : "field";

  return (
    <React.Fragment>
      {hasAddons && (
        <FieldLabel label={label} showRequired={showRequired}></FieldLabel>
      )}
      <div className={mainClassName}>
        {!hasAddons && (
          <FieldLabel label={label} showRequired={showRequired}></FieldLabel>
        )}
        <div className={"control"}>
          <div className={`select ${errorMessage !== undefined ? "is-danger" : ""}`}>
            <select {...field} {...componentOptions}>
              {options !== undefined && (
                <React.Fragment>
                  {options.map(
                    (option: {
                      key: string | number | string[] | undefined;
                      text: React.ReactNode;
                      disabled: boolean
                    }) => (
                      <option key={"option" + option.key} value={option.key} disabled={option.disabled === true}>
                        {option.text}
                      </option>
                    )
                  )}
                </React.Fragment>
              )}
            </select>
          </div>

          <FieldFooter help={help} errorMessage={errorMessage}></FieldFooter>
        </div>

        {suffixControl !== undefined && (
          <React.Fragment>
            {suffixControl}
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};
