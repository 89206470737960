import React, { useState } from "react";
import { Field } from "formik";
import { IClient, IConsultant, IEmployee } from "../../core/cm.types";
import 'react-tabs/style/react-tabs.css';
import { Input } from "../fields/Input";
import { useTranslation } from "react-i18next";
import { Checkbox } from "../fields/Checkbox";
import graphService from "../../services/graph.service";
import { ClientConsultantDropdown } from "../fields/ClientConsultantDropdown"

export const Start: React.FunctionComponent<{ employees: IEmployee[], consultants: IConsultant[], isReadOnly: boolean, client: IClient, clientDriveId: string }> = (props) => {
    const { t } = useTranslation();
    const { employees, isReadOnly, client, consultants, clientDriveId } = props;
    const [sharePointLink, setSharePointLink] = useState<string>(undefined)

    const updateSharePointLink = async () => {
        if (client.sharePointFolder !== undefined) {
            let folder = await graphService.getFolderIdByPath(`/drives/${clientDriveId}/root:/${client.sharePointFolder}`);
            setSharePointLink((folder as any)?.webUrl)
        }
    }

    updateSharePointLink();

    return (
        <React.Fragment>
            <Field
                name="personId"
                label={t("client:tabStart:personId")}
                type="number"
                placeholder={t("client:tabStart:personIdPlaceholder")}
                disabled={isReadOnly}
                component={Input}
                className="input"
            />

            {client?.entry?.employeeId !== undefined && client?.entry?.employeeId !== null && (
                <ClientConsultantDropdown name="entry.employeeId" optionlabel={t("client:tabStart:collaboratorOptions")} itemlabel={t("client:tabStart:collaboratorId")} items={employees} isDisabled={isReadOnly} />
            )}

            {client?.phone !== undefined && client?.phone !== null && (
                <Field
                    name="phone"
                    label={t("client:config:columnPhone")}
                    suffixControl={
                        <div className="control">
                            <a className="button" href={`tel:${client?.phone}`}>
                                <i className={`fas fa-phone`} /></a>
                        </div>
                    }
                    component={Input}
                    disabled={true}
                />
            )}

            {client?.mobile !== undefined && client?.mobile !== null && (
                <Field
                    name="mobile"
                    label={t("client:config:columnMobile")}
                    component={Input}
                    suffixControl={
                        <div className="control">
                            <a className="button" href={`tel:${client?.mobile}`}>
                                <i className={`fas fa-phone`} /></a>
                        </div>
                    }
                    disabled={true}
                />
            )}

            {client?.email !== undefined && client?.email !== null && (
                <Field
                    name="email"
                    label={t("client:tabStart:mail")}
                    disabled={true}
                    suffixControl={
                        <div className="control">
                            <a className="button" href={`mailto:${client?.email}`}>
                                <i className={`fas fa-envelope`} /></a>
                        </div>
                    }
                    component={Input}
                />
            )}

            {client?.consultantId !== undefined && client?.consultantId !== null && (
                <ClientConsultantDropdown name="consultantId" optionlabel={t("client:tabStart:consultantOptions")} itemlabel={t("client:tabMasterData:consultantId")} items={consultants} isDisabled={isReadOnly} />
            )}

            <Field
                name="amm.status"
                label={t("client:tabAMM:status")}
                component={Checkbox}
            />
            <Field
                name="sharePointFolder"
                label={t("client:tabStart:sharePointFolder")}
                placeholder={t("client:tabStart:sharePointFolderPlaceholder")}
                suffixControl={
                    <div className="control">
                        <a className="button" href={sharePointLink} target="_blank">
                            <i className={`fas fa-link`} /></a>
                    </div>
                }
                component={Input}
            />
        </React.Fragment>
    );
};
