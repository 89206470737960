import React from "react";
import appService from "../../../services/app.service";
import { DetailsBase } from "../base/DetailsBase";
import { useTranslation } from "react-i18next";
import { NoAdminAlert } from "../../NoAdminAlert"

export const EtlSolutionDetails: React.FunctionComponent<{
    id: string | undefined;
    isAdmin:boolean;
}> = (props) => {
    const { id,isAdmin } = props;
    const {t} = useTranslation();
    const backButton = '/config/etlsolution';
    const title = t("client:headers:tabEtlSolution");

    if (isAdmin !== true) { return <NoAdminAlert /> }
    
    return (
            <DetailsBase id={id} backButton={backButton} getById={appService.getEtlSolutionsById} hasNumber={true}  saveItem={appService.saveEtlSolutions} inputTitle={title}></DetailsBase>
    );
};
