import React from 'react';
import { extendFieldProps, Input } from "./Input";
import MaskedInput from "react-text-mask";
import { Field } from "formik";
import { useTranslation } from "react-i18next";
import { values } from '@fluentui/react';


export const PhoneField: React.FunctionComponent<any> = ({
    field,
    form,
    ...props
}) => {
    extendFieldProps(props, field, form);

    const { t } = useTranslation();

    const {
        options,
        errorMessage,
        help,
        label,
        showRequired,
        selectedValue,
        checked,
        prefix,
        suffix,
        suffixControl,
        ...componentOptions
    } = props;

    const phoneNumberMask = [
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
    ];

    const defaultPlaceHolder = "xxx xxx xx xx";

    return (
        <React.Fragment>

            <div className="field">
                <label htmlFor={field?.name} className="label" style={{ display: "block" }}>
                    {label}
                </label>
                <Field
                    {...props}
                    render={({ field }) => (
                        <div className="field has-addons">
                            <MaskedInput
                                {...field}
                                mask={phoneNumberMask}
                                placeholder={props?.placeholder !== undefined && props?.placeholder !== "" ? props?.placeholder : defaultPlaceHolder}
                                type="text"
                                className="input"
                            />
                            <React.Fragment>
                                <div className="control">
                                    <a className="button" href={`tel:${field?.value}`}>
                                        <i className={`fas fa-phone`} /></a>
                                </div>
                            </React.Fragment>
                        </div>
                    )}
                />
            </div>

        </React.Fragment>
    );
};