import React from "react";
import appService from "../../../services/app.service";
import { DetailsBase } from "../base/DetailsBase";
import { useTranslation } from "react-i18next";
import { NoDataAdminAlert } from "../../NoDataAdminAlert"

export const AdditionalInfoDetails: React.FunctionComponent<{
    id: string | undefined;
    isDataAdmin: boolean;
}> = (props) => {
    const { id, isDataAdmin } = props;
    const { t } = useTranslation();
    const backButton = '/config/additionalinfo';
    const title = t("client:headers:tabAdditionalInfos");


    if (isDataAdmin !== true) { return <NoDataAdminAlert /> }

    return (
            <DetailsBase id={id} backButton={backButton} getById={appService.getAdditionalInfoById} saveItem={appService.saveAdditionalInfo} inputTitle={title}></DetailsBase>
        );
};
