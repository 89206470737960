import "isomorphic-fetch";

import {
  AuthenticationProvider,
  AuthenticationProviderOptions,
} from "@microsoft/microsoft-graph-client";
import { MSALAuthenticationProviderOptions } from "@microsoft/microsoft-graph-client/lib/es/MSALAuthenticationProviderOptions";
import { Auth } from "./auth.service";

export class ImplicitAuthenticationProvider implements AuthenticationProvider {
  private options: MSALAuthenticationProviderOptions;

  constructor(options: MSALAuthenticationProviderOptions) {
    this.options = options;
  }

  public async getAccessToken(
    authenticationProviderOptions?: AuthenticationProviderOptions
  ): Promise<string> {
    let options: MSALAuthenticationProviderOptions = authenticationProviderOptions as MSALAuthenticationProviderOptions;
    if (this.options !== null) {
      options = this.options;
    }
    let scopes: string[] = [];
    if (options !== undefined) {
      scopes = options.scopes;
    }
    try {
      const auth = Auth.getInstance();
      const token = await auth.getToken(scopes);
      return token as string;
    } catch (e) {
      console.error(e);
      return "";
    }
  }
}
