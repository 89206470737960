import React from "react";
import { IClient } from "../../core/cm.types"
import { Table } from "../table/Table"
import { useMemo } from "react";
import { TableService } from "../../services/table.service";
import moment from "moment";

export const EntriesPlus: React.FunctionComponent<{ suffix: any, postfix: any, clients: IClient[], tableService: TableService, exportAllTable(rows) }> = (props) => {
    const { clients, suffix, postfix, tableService, exportAllTable } = props;

    const thisDay = moment(new Date()).startOf('day');

    const data = clients.filter((client) => {
        //client?.exit?.date === undefined
        if (client?.entry?.date === undefined)
            return false;

        //const entryDate = Date.parse(client.entry.date);
        const entry = moment(client.entry.date).startOf('day');

        return entry >= thisDay;
    });

    const columns = useMemo(() => [
        tableService.getKeyColumn(),
        tableService.getFirstNameColumn(),
        tableService.getLastNameColumn(),
        tableService.getInterviewDateColumn(),
        tableService.getInterviewState(),
        tableService.getInverviewFeedbackColumn(),
        tableService.getInterviewNextStepsColumn(),
        tableService.getEntryDateColumn(),
        tableService.getEntryDay(),
        tableService.getEntryHourColumn(),
        tableService.getExitDateColumn(),
        tableService.getConsultantColumn(),
        tableService.getEntryEmployeeColumn(),
        tableService.getPensumColumn(),
        tableService.getQualificationColumn(),
        tableService.getEntryLocationColumn(),
        tableService.getEntryAdditionalinfoColumn(),
    ], [tableService]);

    //qualifications.length, locationInfo.length
    const sortBy = [
        { id: 'interview.date', desc: false }
    ];

    return (
        <Table data={data} columns={columns} columnssuffix={suffix} columnspostfix={postfix} sortBy={sortBy} exportAllTable={exportAllTable} />
    )
};
