import {
  AuthenticationProvider,
  Client,
} from "@microsoft/microsoft-graph-client";
import {
  IAuth,
  IAuthLoginResponse,
  IClientTokens,
  IConfig,
  ITokenRequest,
  IUserAccountInfo,
} from "./auth.types";

import * as microsoftTeams from '@microsoft/teams-js';

import { QueryStringService } from "../querystring.service";
import { MSALAuthenticationProviderOptions } from "@microsoft/microsoft-graph-client/lib/src/MSALAuthenticationProviderOptions";
import { ImplicitAuthenticationProvider } from "./ImplicitAuthenticationProvider";

export class TeamsAuth implements IAuth {
  private _config: IConfig;
  private _graphClient: Client;
  private _authProvider: AuthenticationProvider;
  private _tokens: IClientTokens | undefined;
  private _userAccountInfo: IUserAccountInfo;

  public constructor(config: IConfig) {
    this._config = config;

    const graphScope = config.app.graphScope.split(",");
    this._authProvider = new ImplicitAuthenticationProvider(
      new MSALAuthenticationProviderOptions(graphScope)
    );
    const authProvider = this._authProvider;
    this._graphClient = Client.initWithMiddleware({
      authProvider,
      fetchOptions: { headers: { ConsistencyLevel: 'eventual' } }
    });

    this._userAccountInfo = {
      id: QueryStringService.getInstance().getUrlParameterByName(
        "userObjectId"
      ) as string,
      username: QueryStringService.getInstance().getUrlParameterByName(
        "userPrincipalName"
      ) as string,
      displayName: undefined
    }
  }

  logout(): void {
    // not implemented in teams
  }

  getGraphClient(): Client {
    return this._graphClient;
  }

  getUserAccount = (): IUserAccountInfo | undefined => {
    return this._userAccountInfo;
  };

  fetchWithToken = (
    url: string,
    token: string | undefined,
    options?: RequestInit
  ): Promise<Response> => {
    options = options || {};
    options.headers = options.headers || new Headers();

    let header = options.headers as Headers;
    if (header) {
      header.append("Accept", "application/json");
      header.append("Content-Type", "application/json");
      //header.append("pragma", "no-cache");
      //header.append("cache-control", "no-cache");
      if (token) header.append("Authorization", `Bearer ${token}`);
    }
    return fetch(url, options);
  };

  login(): Promise<IAuthLoginResponse> {
    // Call the initialize API first
    microsoftTeams.initialize();

    return new Promise<IAuthLoginResponse>(async (resolve, reject) => {
      let feedback: IAuthLoginResponse = {
        account: null,
        errorCode: undefined,
        accessDenied: false,
      };
      var authTokenRequest: any = {
        successCallback: (result: any) => {
          let config = this.getConfig();
          
          if (config !== undefined) {
            const route: string = config.teams.tokenEndPoint;
            const body: ITokenRequest = {
              graphScope: config.app.graphScope,
              appScope: config.app.appScope,
            };
            this.fetchWithToken(route, result, {
              method: "POST",
              body: JSON.stringify(body),
            })
              .then((result: any) => {
                if (result.ok) {
                  result.json().then((tokens: IClientTokens) => {
                    this._tokens = tokens;
                    resolve(feedback);
                  });
                } else {
                  feedback.accessDenied = true;
                  feedback.errorCode = "token_not_retrievable";
                  reject(feedback);
                }
              })
              .catch((ex) => {
                feedback.accessDenied = true;
                feedback.errorCode = ex?.errorCode;
                reject(feedback);
              });
          } else {
            feedback.accessDenied = true;
            feedback.errorCode = "token_not_retrievable";
            reject(feedback);
          }
        },
        failureCallback: function () {
          feedback.accessDenied = true;
          feedback.errorCode = "sso_token_not_retrievable";
          reject(feedback);
        },
      };
      microsoftTeams.authentication.getAuthToken(authTokenRequest);
    });
  }

  getToken = async (scopes: string[]): Promise<string | undefined> => {
    // todo improve handling
    return new Promise<string | undefined>(async (resolve) => {
      const easyScope = this.getConfig().app.appScope.split(",");
      if (JSON.stringify(scopes) === JSON.stringify(easyScope)) {
        resolve(this._tokens?.appToken);
      }
      else {
        resolve(this._tokens?.graphToken);
      }
    });
  };

  getConfig(): IConfig {
    return this._config;
  }
}
