import React from "react";
import { IClient } from "../../core/cm.types"
import { Table } from "../table/Table"
import { useMemo } from "react";
import { TableService } from "../../services/table.service"

export const AllClients: React.FunctionComponent<{ suffix: any, postfix: any, clients: IClient[], tableService: TableService, exportAllTable(rows) }> = (props) => {
    const { clients, suffix, postfix, tableService, exportAllTable } = props;

    const columns = useMemo(() => [
        tableService.getKeyColumn(),
        tableService.getFirstNameColumn(),
        tableService.getLastNameColumn(),
        tableService.getEntryEmployeeColumn(),
        tableService.getConsultantColumn(),
        tableService.getInterviewState(),
        tableService.getEntryDateColumn(),
        tableService.getExitDateColumn(),
        tableService.getPensumColumn(),
        tableService.getQualificationColumn(),
        tableService.getEntryLocationColumn(),
        tableService.getAmmStatusColumn(),
        tableService.getEntryAdditionalinfoColumn(),
    ], [tableService]);

    const sortBy = [
        { id: 'lastName', desc: false }
    ];

    return (
        <Table data={clients} columns={columns} columnssuffix={suffix} columnspostfix={postfix} sortBy={sortBy} exportAllTable={exportAllTable} />
    )
};
