import React, { useCallback } from "react";
import { IClient} from "../../core/cm.types"
import { Table } from "../table/Table"
import { useMemo } from "react";
import { TableService } from "../../services/table.service"

export const DocumentsInterview: React.FunctionComponent<{ clients: IClient[], selectedRows: IClient[], setSelectedRows: React.Dispatch<React.SetStateAction<any[]>>, tableService: TableService, exportAllTable(rows) }> = (props) => {
    const { clients, selectedRows, tableService, exportAllTable, setSelectedRows } = props;

    const updateSelectedRow  = useCallback((row) => {
        if (!selectedRows.some((p) => p.key === row.original.key)) {
            selectedRows.push(row.original);
            setSelectedRows([...selectedRows.sort((a, b) => {
                if (a.firstName < b.firstName) {
                    return -1;
                }
                if (a.firstName > b.firstName) {
                    return 1;
                }
                return 0;
            })])
        }
    }, [selectedRows, setSelectedRows]);

    const columns = useMemo(() => [
        {
            id: 'selection',
            Header: ({ filteredRows }) => (
                <button
                    className="button is-link is-small"
                    title="Hinzufügen"
                    onClick={(e) => {
                        e.preventDefault();
                        filteredRows.map((row) => {
                            updateSelectedRow(row);
                        });
                    }}
                >
                    <span className="icon is-small">
                        <i className={`fas fa-plus`} />
                    </span>
                </button>
            ),
            Cell: ({ row }) => (
                <button
                    className="button is-link is-small"
                    title="Hinzufügen"
                    onClick={(e) => {
                        e.preventDefault();
                        updateSelectedRow(row);
                    }}
                >
                    <span className="icon is-small">
                        <i className={`fas fa-plus`} />
                    </span>
                </button>
            ),
        },
        tableService.getKeyColumn(),
        tableService.getFirstNameColumn(),
        tableService.getLastNameColumn(),
        tableService.getInterviewEmployeeColumn(),
        tableService.getConsultantColumn(),
        tableService.getInterviewDateColumn(),
        tableService.getInterviewState(),
        tableService.getInterviewFeedbackColumn(),
        tableService.getInterviewNextStepsColumn(),
        tableService.getEntryDateColumn(),
        tableService.getMailColumn(),
        tableService.getAmmStatusColumn(),
        tableService.getQualificationColumn(),
        tableService.getEntryLocationColumn(),
        tableService.getEntryAdditionalinfoColumn(),
    ], [tableService, updateSelectedRow]);

    const sortBy = [
        { id: 'interview.date', desc: true }
    ];

    return (
        <Table data={clients} columns={columns} sortBy={sortBy} exportAllTable={exportAllTable} />
    )
};
