import React from "react";
import appService from "../../../services/app.service";
import { DetailsBase } from "../base/DetailsBase";
import { useTranslation } from "react-i18next";
import { NoDataAdminAlert } from "../../NoDataAdminAlert"

export const LocationInfoDetails: React.FunctionComponent<{
    id: string | undefined;
    isDataAdmin: boolean;
}> = (props) => {
    const { id, isDataAdmin } = props;
    const { t } = useTranslation();
    const backButton = "/config/locationinfo";
    const title = t("client:headers:tabLocationInfo");

    if (isDataAdmin !== true) { return <NoDataAdminAlert /> }

    return (
        <DetailsBase id={id} backButton={backButton} getById={appService.getLocationInfo} hasNumber={true} saveItem={appService.saveLocationInfo} inputTitle={title}></DetailsBase>
    );
};