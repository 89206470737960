import React from "react";
import appService from "../../../services/app.service";
import { DetailsOverview } from "../base/DetailsOverview";
import { useTranslation } from "react-i18next";
import { NoAdminAlert } from "../../NoAdminAlert"

export const AlvstatusOverview: React.FunctionComponent<{ isAdmin: boolean }> = (props) => {
    const detailsRedirection = "/config/alvstatus/details";
    const { t } = useTranslation();
    const title = t("client:headers:tabALVStatus");
    const { isAdmin } = props;

    if (isAdmin !== true) { return <NoAdminAlert /> }

    return (
            <React.Fragment>
                <DetailsOverview detailsRedirection={detailsRedirection} getItems={appService.getAlvStatus} hasNumber={true} entityType="alvstatus" title={title}></DetailsOverview>
            </React.Fragment>
        );
};
