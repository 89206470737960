import React from 'react';
import { extendFieldProps } from "./Input";
import { FieldFooter } from "./FieldFooter";
import { FieldLabel } from "./FieldLabel";

export const Textarea: React.FunctionComponent<any> = ({
  field,
  form,
  ...props
}) => {
  extendFieldProps(props, field, form);

  const {
    options,
    errorMessage,
    help,
    label,
    showRequired,
    selectedValue,
    checked,
    ...componentOptions
  } = props;

  return (
    <div className="field">
      <FieldLabel label={label} showRequired={showRequired}></FieldLabel>
      <div className="control">
        <textarea className={`textarea ${errorMessage !== undefined ? "is-danger" : ""}`} {...field} {...componentOptions} />

        <FieldFooter help={help} errorMessage={errorMessage}></FieldFooter>
      </div>
    </div>
  );
};
