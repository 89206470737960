import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSortableData } from "../../../services/sortableData.service"
import { SortButton } from "../../SortButton"
import { IConsultantLocation } from "../../../core/cm.types"
import appService from "../../../services/app.service";
import { LocationAwareButton } from "../../LocationAwareButton";
import graphService from "../../../services/graph.service";
import { useTranslation } from "react-i18next";
import { NoAdminAlert } from "../../NoAdminAlert"
import { DetailsOverview } from "../base/DetailsOverview";

export const ConsultantLocationOverview: React.FunctionComponent<{ isAdmin: boolean }> = (props) => {
    const { isAdmin } = props;
    const { t } = useTranslation();
    const title = t("client:headers:tabConsDepLocation");
    const detailsRedirection = "/config/consultantLocations/details";

    if (isAdmin !== true) { return <NoAdminAlert /> }

    const columns = [
        {
            Header: t("client:tabConsDepLocation:locationName"),
            accessor: 'title'
        }
    ];

    return (
        <DetailsOverview columns={columns} detailsRedirection={detailsRedirection} getItems={appService.getConsultantLocations} entityType="consultantLocations" title={title}></DetailsOverview>
    );
};
