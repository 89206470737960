import React from "react";
import { Field, useFormikContext } from "formik";
import { Input } from "../fields/Input";

import { Select } from "../fields/Select";
import { IAlk, IBaseDetailsItem, IClient, IConsultant, IResidencePermit } from "../../core/cm.types";
import { DateField } from "../fields/DateField";
import 'react-tabs/style/react-tabs.css';
import { useTranslation } from "react-i18next";
import MaskedInput from "react-text-mask";
import { PhoneField } from "../fields/PhoneField"
import { ClientConsultantDropdownV2 } from "../fields/ClientConsultantDropdownV2";
import { getIn } from "formik";

export const MasterData: React.FunctionComponent<{ setFieldValue: any, alk: IAlk[], consultants: IConsultant[], countries: IBaseDetailsItem[], residencePermits: IResidencePermit[], qualifications: IBaseDetailsItem[], isReadOnly: boolean, client: IClient }> = (props) => {
    const { t } = useTranslation();
    const { alk, consultants, countries, isReadOnly, client, qualifications, residencePermits, setFieldValue } = props;

    const alkOptions = [{ key: "", text: t("client:tabMasterData:alkOptions") }, ...alk.map((item) => {
        return {
            key: item.key,
            text: `${item.title} (${item.alkNumber})`
        };
    })];

    const residencePermitOption = [{ key: "", text: t("client:tabMasterData:residenceOptions") }, ...residencePermits?.map((item) => {
        return {
            key: item.key,
            text: item.title + " " + item.description
        };
    })];

    const title = [t("client:tabMasterData:mr"), t("client:tabMasterData:mrs")];
    const titleOptions = [{ key: "", text: t("client:tabMasterData:titleOptions") }, ...title.map((item) => {
        return {
            key: item,
            text: item
        };
    })];

    const qualificationOption = [{ key: "", text: t("client:tabMasterData:qualificationOptions") }, ...qualifications?.map((item) => {
        return {
            key: item.key,
            text: item.title
        };
    })];

    const countriesOption = [{ key: "", text: t("client:tabMasterData:countriesOptions") }, ...countries?.map((item) => {
        return {
            key: item.key,
            text: item.title
        };
    })];

    const socialSecurityNumberMask = [
        /\d/,
        /\d/,
        /\d/,
        ".",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ".",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ".",
        /\d/,
        /\d/
    ];

    const timeNumberMask = [
        /[0-1]/,
        /[0-9]/,
        ":",
        /[0-9]/,
        /[0-9]/
    ];

    const plzNumberMask = [
        /\d/,
        /\d/,
        /\d/,
        /\d/
    ];

    const employeeNumberMask = [
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/
    ];


    const { errors } = useFormikContext();
    const error = getIn(errors, "personId");

    return (
        <React.Fragment>
            <Field
                name="title"
                label={t("client:tabMasterData:title")}
                disabled={isReadOnly}
                options={titleOptions}
                component={Select}
            />
            <Field
                name="firstName"
                label={t("client:tabMasterData:firstName")}
                placeholder={t("client:tabMasterData:firstNamePlaceholder")}
                disabled={isReadOnly}
                component={Input}
            />

            <Field
                name="lastName"
                label={t("client:tabMasterData:lastName")}
                placeholder={t("client:tabMasterData:lastNamePlaceholder")}
                disabled={isReadOnly}
                component={Input}
            />

            <Field
                name="street"
                label={t("client:tabMasterData:street")}
                placeholder={t("client:tabMasterData:streetPlaceholder")}
                disabled={isReadOnly}
                component={Input}
            />

            <div className="field">
                <label htmlFor="zip" className="label" style={{ display: "block" }}>
                    {t("client:tabMasterData:zip")}
                </label>
                <Field
                    name="zip"
                    label={t("client:tabMasterData:zip")}
                    component={Input}
                    render={({ field }) => (
                        <MaskedInput
                            {...field}
                            mask={plzNumberMask}
                            id="zip"
                            placeholder={t("client:tabMasterData:zipPlaceholder")}
                            type="text"
                            disabled={isReadOnly}
                            className="input"
                        />
                    )}
                />
            </div>

            <Field
                name="city"
                label={t("client:tabMasterData:city")}
                placeholder={t("client:tabMasterData:cityPlaceholder")}
                disabled={isReadOnly}
                component={Input}
            />

            <ClientConsultantDropdownV2 name="consultantId" optionlabel={t("client:tabStart:consultantOptions")} itemlabel={t("client:tabMasterData:consultantId")} items={consultants} isDisabled={isReadOnly} />

            <div className="field">
                <label htmlFor="personId" className="label" style={{ display: "block" }}>
                    {t("client:tabMasterData:personId")}
                </label>
                <Field
                    name="personId"
                    render={({ field }) => (
                        <MaskedInput
                            {...field}
                            mask={employeeNumberMask}
                            id="personId"
                            placeholder={t("client:tabMasterData:personIdPlaceholder")}
                            type="text"
                            disabled={isReadOnly}
                            className={`${error !== undefined ? 'input is-danger' : 'input'}`}
                        />
                    )}
                />
                {error !== undefined && error !== "*" && (<span className="help is-danger">{error}</span>)}
            </div>

            <Field
                name="interview.date"
                disabled={isReadOnly}
                label={t("client:tabMasterData:interviewDate")}
                dateFormat="dd.MM.yyyy"
                selected={new Date(client?.interview?.date)}
                component={DateField}
            />
            <div className="field">
                <label htmlFor="interview.starttime" className="label" style={{ display: "block" }}>
                    {t("client:tabEntry:startTime")}
                </label>
                <Field
                    name="interview.starttime"
                    label={t("client:tabEntry:startTime")}
                    component={Input}
                    render={({ field }) => (
                        <MaskedInput
                            {...field}
                            mask={timeNumberMask}
                            id="interview.starttime"
                            placeholder={t("client:tabMasterData:timePlaceholder")}
                            type="text"
                            disabled={isReadOnly}
                            className="input"
                        />
                    )}
                />
            </div>
            <Field
                name="email"
                label={t("client:tabEntry:mail")}
                disabled={isReadOnly}
                component={Input}
                suffixControl={
                    <div className="control">
                        <a className="button" href={`mailto:${client?.email}`}>
                            <i className={`fas fa-envelope`} /></a>
                    </div>
                }
            />
            <PhoneField name="phone" label={t("client:tabMasterData:phone")} placeholder={t("client:tabMasterData:phonePlaceholder")} />
            <PhoneField name="mobile" label={t("client:tabMasterData:mobile")} placeholder={t("client:tabMasterData:mobilePlaceholder")} />
            <Field
                name="startPeriod"
                disabled={isReadOnly}
                label={t("client:tabMasterData:startPeriod")}
                dateFormat="dd.MM.yyyy"
                onChangeExecuted={(change) => {
                    var date = new Date(change);
                    var twoYears = new Date(date.setFullYear(date.getFullYear() + 2));
                    twoYears.setDate(twoYears.getDate() - 1);

                    setFieldValue("endPeriod", twoYears, true);
                }
                }
                selected={new Date(client?.startPeriod)}
                component={DateField}
            />
            <Field
                name="endPeriod"
                disabled={isReadOnly}
                label={t("client:tabMasterData:endPeriod")}
                dateFormat="dd.MM.yyyy"
                selected={new Date(client?.endPeriod)}
                component={DateField}
            />
            <Field
                name="pensum"
                label={t("client:tabMasterData:pensum")}
                placeholder={t("client:tabMasterData:pensumPlaceholder")}
                disabled={isReadOnly}
                component={Input}
            />
            <Field
                name="qualification"
                label={t("client:tabMasterData:qualification")}
                disabled={isReadOnly}
                options={qualificationOption}
                component={Select}
            />
            <Field
                name="jobLearned"
                label={t("client:tabMasterData:jobLearned")}
                placeholder={t("client:tabMasterData:jobLearnedPlaceholder")}
                disabled={isReadOnly}
                component={Input}
            />
            <Field
                name="lastJob"
                label={t("client:tabMasterData:lastJob")}
                placeholder={t("client:tabMasterData:lastJobPlaceholder")}
                disabled={isReadOnly}
                component={Input}
            />
            <Field
                name="birthDay"
                disabled={isReadOnly}
                label={t("client:tabMasterData:birthDay")}
                dateFormat="dd.MM.yyyy"
                selected={new Date(client?.birthDay)}
                component={DateField}
            />
            <Field
                name="nationality"
                label={t("client:tabMasterData:nationality")}
                disabled={isReadOnly}
                options={countriesOption}
                component={Select}
            />
            <Field
                name="residencePermit"
                label={t("client:tabMasterData:residencePermit")}
                disabled={isReadOnly}
                options={residencePermitOption}
                component={Select}
            />
            <div className="field">
                <label htmlFor="socialSecurityNumber" className="label" style={{ display: "block" }}>
                    {t("client:tabMasterData:socialSecurityNumber")}
                </label>
                <Field
                    name="socialSecurityNumber"
                    render={({ field }) => (
                        <MaskedInput
                            {...field}
                            mask={socialSecurityNumberMask}
                            id="socialSecurityNumber"
                            placeholder="___.____.____.__"
                            type="text"
                            disabled={isReadOnly}
                            className="input"
                        />
                    )}
                />
            </div>

            <Field
                name="alkId"
                label={t("client:tabMasterData:alkId")}
                disabled={isReadOnly}
                value={client?.alkId}
                options={alkOptions}
                component={Select}
            />
            <Field
                name="entry.date"
                disabled={isReadOnly}
                label={t("client:tabMasterData:entrydate")}
                dateFormat="dd.MM.yyyy"
                selected={new Date(client?.entry?.date)}
                component={DateField}
            />
        </React.Fragment>
    );
};
