import React from "react";

export const ColumnSortContainer: React.FunctionComponent<{ column: { canSort, isSorted, isSortedDesc }}> = (props) => {
  const { column } = props;
  const { canSort, isSorted, isSortedDesc } = column;

  if (canSort !== true || isSorted === false)
    return <React.Fragment></React.Fragment>

  return (
    <React.Fragment>{isSortedDesc
        ? ' ↓'
        : ' ↑'}
      </React.Fragment>
  )
}
