import React from "react";
import { Field } from "formik";
import { Textarea } from "../fields/Textarea";

import { Select } from "../fields/Select";
import { IBaseDetailsItem, IClient, IEmployee } from "../../core/cm.types";
import { DateField } from "../fields/DateField";
import 'react-tabs/style/react-tabs.css';
import { useTranslation } from "react-i18next";
import { Input } from "../fields/Input";
import MaskedInput from "react-text-mask";
import { ClientConsultantDropdown } from "../fields/ClientConsultantDropdown";

export const Interview: React.FunctionComponent<{ employee: IEmployee[], interviewStatus: IBaseDetailsItem[], isReadOnly: boolean, client: IClient }> = (props) => {
    const { t } = useTranslation();
    const { employee, isReadOnly, client, interviewStatus } = props;

    const statusOption = [{ key: "", text: t("client:tabInterview:statusOptions") }, ...interviewStatus?.map((item) => {
        return {
            key: item.key,
            text: item.title
        };
    })];

    const timeNumberMask = [
        /[0-1]/,
        /[0-9]/,
        ":",
        /[0-9]/,
        /[0-9]/
    ];

    return (
        <React.Fragment>
            <Field
                name="interview.date"
                disabled={isReadOnly}
                label={t("client:tabInterview:date")}
                dateFormat="dd.MM.yyyy"
                selected={new Date(client?.interview?.date)}
                component={DateField}
            />
            <div className="field">
                <label htmlFor="interview.starttime" className="label" style={{ display: "block" }}>
                Startzeit Vorgespräch
                </label>
                <Field
                    name="interview.starttime"
                    label={t("client:tabEntry:startTime")}
                    component={Input}
                    render={({ field }) => (
                        <MaskedInput
                            {...field}
                            mask={timeNumberMask}
                            id="interview.starttime"
                            placeholder={t("client:tabMasterData:timePlaceholder")}
                            type="text"
                            disabled={isReadOnly}
                            className="input"
                        />
                    )}
                />
            </div>
            <ClientConsultantDropdown name="interview.employeeId" optionlabel={t("client:tabInterview:collaboratorOptions")} itemlabel={t("client:tabInterview:collaboratorId")} items={employee} isDisabled={isReadOnly} />
            <Field
                name="interview.statusId"
                label={"Status Vorgespräch"}
                disabled={isReadOnly}
                options={statusOption}
                component={Select}
            />
            <Field
                name="interview.feedback"
                label={t("client:tabInterview:feedback")}
                placeholder={t("client:tabInterview:feedbackPlaceholder")}
                disabled={isReadOnly}
                component={Textarea}
            />
            <Field
                name="interview.nextSteps"
                label={t("client:tabInterview:nextSteps")}
                placeholder={t("client:tabInterview:nextStepsPlaceholder")}
                disabled={isReadOnly}
                component={Textarea}
            />
        </React.Fragment>
    );
};
