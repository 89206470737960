import React from "react";
import appService from "../../../services/app.service";
import { useTranslation } from "react-i18next";
import { NoAdminAlert } from "../../NoAdminAlert"
import { DetailsOverview } from "../base/DetailsOverview";

export const EmployeeFunctionOverview: React.FunctionComponent<{ isAdmin: boolean }> = (props) => {
    const { isAdmin } = props;
    const { t } = useTranslation();
    const title = t("client:headers:tabEmployeeFunction");
    const detailsRedirection = "/config/employeeFunctions/details";

    if (isAdmin !== true) { return <NoAdminAlert /> }

    return (
        <DetailsOverview detailsRedirection={detailsRedirection} getItems={appService.getEmployeeFunctions} entityType="employeeFunctions" title={title}></DetailsOverview>
    );
};
