import React from 'react';
import { useTranslation } from "react-i18next";
import { IClient, IMonthPresenceDetails } from '../../core/cm.types';
import { FieldPair } from './FieldPair'
import 'moment/locale/de';

export interface IDayDefinition {
    isFreeDay: boolean,
    title: number
}

export const PresenceInput: React.FunctionComponent<{ clients: IClient[], details: IMonthPresenceDetails[], days: IDayDefinition[], isSubmitting: boolean, isDirty: boolean, isValid: boolean }> = (props) => {
    const { t } = useTranslation();
    const { details, days, clients, isSubmitting, isDirty, isValid } = props;

    const getClientNameById = (clientId: string): string => {
        var client = clients?.find(p => p.key === clientId);
        if (client !== undefined) {
            return `${client.lastName} ${client.firstName}`;
        }
        return "";
    }

    if (details === undefined || details.length === 0) {
        return (<React.Fragment>{t("client:tabPresence:selectClient")}</React.Fragment>);
    }

    return (
        <React.Fragment>
            <div className="table-container">
                <table className="table is-fullwidth clientTable">
                    <tbody>
                        {details.map((client, index) => (
                            <>
                                <tr key={client.customerId + "_header"}>
                                    <td></td>
                                    {days.map((day, index) => {
                                        return <td key={"th" + index} className={day.isFreeDay === true ? "day dayHeader isFreeDay" : "day dayHeader"}>{day.title}</td>
                                    })}
                                </tr>
                                <tr key={client.customerId}>
                                    <td className='clientHeader'>
                                        {getClientNameById(client.customerId)}
                                    </td>
                                    {days.map((day, fieldIndex) => {
                                        return <FieldPair key={fieldIndex} customerId={client.customerId} isFreeDay={day.isFreeDay} index={index} fieldId={day.title} />
                                    })}
                                </tr>
                            </>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="button-footer">
                <button
                    type="submit"
                    className={`button is-link is-pulled-right is-small backAndSaveButton ${isSubmitting ? t("client:common:isLoading") : ""
                        }`}
                    disabled={isSubmitting || !isDirty || !isValid}
                >
                    {t("client:common:saveButton")}
                </button>
            </div>
        </React.Fragment>
    );
};
