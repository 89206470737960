import "./App.css";

import React, { useState } from 'react';
import { useTranslation, withTranslation } from "react-i18next";
import { Route, Switch, useHistory } from "react-router-dom";
import { IAppProps, IAppState } from "./core/app.types";
import { Auth } from "./services/auth/auth.service";
import { InProgramContainer } from "./components/InProgramContainer"
import { InProgramPicturesContainer } from "./components/InProgramPicturesContainer"
import { InProgramPlusContainer } from "./components/InProgramPlusContainer"
import { EntriesContainer } from "./components/EntriesContainer"
import { ExitsContainer } from "./components/ExitsContainer"
import { UpcomingExitsContainer } from "./components/UpcomingExitsContainer"
import { ActivitiesContainer } from "./components/ActivitiesContainer"
import { AmmSearchContainer } from "./components/AmmSearchContainer"
import { AllClientsContainer } from "./components/AllClientsContainer"
import { AccessDenied } from "./components/AccessDenied"
import appService from "./services/app.service"
import { Navigation } from "./components/Navigation"
import { Loading } from "./components/Loading"
import { LocationList } from "./components/LocationList"
import { ClientDetails } from "./components/details/ClientDetails"
import { AlkOverview } from "./components/config/alk/AlkOverview"
import { AlkDetails } from "./components/config/alk/AlkDetails"
import { ExpenseOverview } from "./components/config/expenses/ExpenseOverview"
import { ExpenseDetails } from "./components/config/expenses/ExpenseDetails"
import { DocumentsInterviewContainer } from "./components/documents/DocumentsInterviewContainer"
import { DocumentsDefaultContainer } from "./components/documents/DocumentsDefaultContainer"
import { EmployeeFunctionOverview } from "./components/config/employeeFunctions/EmployeeFunctionOverview"
import { EmployeeFunctionDetails } from "./components/config/employeeFunctions/EmployeeFunctionDetails"
import { ConsultantLocationOverview } from "./components/config/consultantLocations/ConsultantLocationOverview"
import { ConsultantLocationDetails } from "./components/config/consultantLocations/ConsultantLocationDetails"
import { ConsultantDepartmentOverview } from "./components/config/consultantDepartments/ConsultantDepartmentOverview"
import { ConsultantDepartmentDetails } from "./components/config/consultantDepartments/ConsultantDepartmentDetails"
import { EmployeeDetails } from "./components/config/employee/EmployeeDetails"
import { EmployeeOverview } from "./components/config/employee/EmployeeOverview"
import { ConsultantDetails } from "./components/config/consultants/ConsultantDetails"
import { ConsultantOverview } from "./components/config/consultants/ConsultantOverview"
import { FreedayOverview } from "./components/config/freedays/FreedayOverview"
import { FreedayDetails } from "./components/config/freedays/FreedayDetails"
import { ConsultantFunctionDetails } from "./components/config/consultantFunctions/ConsultantFunctionDetails"
import { ConsultantFunctionOverview } from "./components/config/consultantFunctions/ConsultantFunctionOverview"
import { NationalityOverview } from "./components/config/nationalities/NationalityOverview";
import { NationalityDetails } from "./components/config/nationalities/NationalityDetails";
import { TerminationOverview } from "./components/config/terminations/TerminationOverview";
import { TerminationDetails } from "./components/config/terminations/TerminationDetails";
import { QualificationDetails } from "./components/config/qualifications/QualificationDetails"
import { QualificationOverview } from "./components/config/qualifications/QualificationOverview"
import { InterviewStatusDetails } from "./components/config/interviewstatus/InterviewStatusDetails"
import { InterviewStatusOverview } from "./components/config/interviewstatus/InterviewStatusOverview"
import { AdditionalInfoDetails } from "./components/config/additionalinfo/AdditionalInfoDetails"
import { AdditionalInfoOverview } from "./components/config/additionalinfo/AdditionalInfoOverview"
import { AlvstatusDetails } from "./components/config/alvstatus/AlvstatusDetails"
import { AlvstatusOverview } from "./components/config/alvstatus/AlvstatusOverview"
import { EtlSolutionOverview } from "./components/config/etlsolutions/EtlSolutionOverview"
import { EtlSolutionDetails } from "./components/config/etlsolutions/EtlSolutionDetails"
import { StrategiesOverview } from "./components/config/strategies/StrategiesOverview"
import { StrategiesDetails } from "./components/config/strategies/StrategiesDetails"
import { ResidencePermitDetails } from "./components/config/residencepermit/ResidencePermitDetails"
import { ResidencePermitOverview } from "./components/config/residencepermit/ResidencePermitOverview"
import { EnsureLocation } from "./components/EnsureLocation";
import { initializeIcons } from '@fluentui/react/lib/Icons';
import { OfficelocationsDetails } from "./components/config/officelocations/OfficelocationsDetails";
import { OfficelocationsOverview } from "./components/config/officelocations/OfficelocationsOverview";
import { registerLocale } from "react-datepicker";
import de from "date-fns/locale/de"; // the locale you want
import { LocationInfoOverview } from "./components/config/locationinfo/LocationInfoOverview";
import { LocationInfoDetails } from "./components/config/locationinfo/LocationInfoDetails";
import { Presence } from "./components/presence/Presence"
import { PresencePrint } from "./components/presenceprint/PresencePrint"
import { IAlk, IBaseDetailsItem, IClient, IConsultant, IEmployee, ILocationInfo } from "./core/cm.types";
import { FormikHelpers } from "formik";
import { TableService } from "./services/table.service"
import { NoAdminCheck } from "./components/NoAdminCheck"
import { AllClientsOlderOneYearContainer } from "./components/AllClientsOlderOneYearContainer"
import { EntriesPlusContainer } from "./components/EntriesPlusContainer"
import "react-datepicker/dist/react-datepicker.css";

registerLocale("de", de);

initializeIcons();

export const App: React.FunctionComponent<IAppProps> = (props) => {
  const { t } = useTranslation();
  const [alk, setAlk] = React.useState<IAlk[]>([]);
  const history = useHistory();

  const [clients, setClients] = React.useState<IClient[]>([]);
  const [consultants, setConsultants] = React.useState<IConsultant[]>([]);
  const [employee, setEmployees] = React.useState<IEmployee[]>([]);
  const [countries, setCountries] = React.useState<IBaseDetailsItem[]>([]);
  const [residencePermit, setResidencePermits] = React.useState<IBaseDetailsItem[]>([]);
  const [qualifications, setQualifications] = React.useState<IBaseDetailsItem[]>([]);
  const [interviewStatus, setInterviewStatus] = React.useState<IBaseDetailsItem[]>([]);
  const [additionalInfo, setAdditionalInfo] = React.useState<IBaseDetailsItem[]>([]);
  const [alvStatus, setAlvStatus] = React.useState<IBaseDetailsItem[]>([]);
  const [etlSolutions, setEtlSolution] = React.useState<IBaseDetailsItem[]>([]);
  const [termination, setTermination] = React.useState<IBaseDetailsItem[]>([]);
  const [strategy, setStrategy] = React.useState<IBaseDetailsItem[]>([]);
  const [locationInfo, setLocationInfo] = React.useState<ILocationInfo[]>([]);


  const [data, setData] = useState<IAppState>({
    loading: true,
    locations: [],
    isAdmin: false,
    isMember: false,
    isDataAdmin: false,
    currentLocation: undefined
  });

  const onSubmit = (
    values: Readonly<IClient | undefined>,
    formikHelpers: FormikHelpers<Readonly<IClient>>
  ) => {
    const client = values;

    const submit = async () => {
      if (client !== undefined && client !== null) {
        const isNew = client.key === undefined;
        appService.saveClient(client).then((modulesResults) => {
          if (isNew) {
            setClients([...clients, modulesResults]);
          }
          else {
            const index = clients.findIndex(p => p.key === modulesResults.key);
            clients[index] = modulesResults;
            setClients([...clients]);
          }

          formikHelpers.resetForm({
            values: modulesResults
          })
          if (isNew) {
            const location = appService.getLocation();
            history.push(`/${location}/details/${modulesResults.key}`)
          }
        }).catch(() => {
          alert(t("client:common:errorOnSave"));
        });
      }
    };

    submit();
  };

  function checkDate(val) {
    if (val === null || val === undefined)
      return "";

    const d = new Date(val);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return day + "." + month + "." + year;
  }

  function checkValue(val) {
    try {
      if (val === null || val === undefined)
        return "";

      return val?.replaceAll(",", "").replaceAll(";", "").replace(/[\n\r]+/g, ' ');
    } catch (e) {
      return "error";
    }
  }

  const exportAllTable = async (rows) => {
    const alks = await appService.getAlks()
    const nations = await appService.getNationality();
    const residentPermits = await appService.getResidencePermits();
    const strategies = await appService.getStrategies();
    const terminations = await appService.getTerminations();

    return rows.map(row => {
      const client = row.original as IClient

      const myAlk = alks.find(a => a.key === client?.alkId);
      const quali = qualifications.find(q => q.key === client?.qualification);
      const nation = nations.find(n => n.key === client?.nationality);
      const residentsPermit = residentPermits.find(r => r.key === client?.residencePermit);
      const alvStat = alvStatus.find(r => r.key === client?.exit?.alvStatus);
      const strategy = strategies.find(r => r.key === client?.exit?.strategy);
      const additionalInf = additionalInfo.find(r => r.key === client?.entry?.additionalInfoId)
      const etlSolution = etlSolutions.find(r => r.key === client?.exit?.etl)
      const termination = terminations.find(r => r.key === client?.amm?.terminationId)

      const consultant = consultants.find(e => e.key === client?.consultantId);

      const employeeVG = employee.find(e => e.key === client?.interview?.employeeId);
      const employeeFA = employee.find(e => e.key === client?.entry?.employeeId);
      const interviewSt = interviewStatus.find(e => e.key === client?.interview?.statusId);
      const location = locationInfo.find(e => e.key === client?.entry?.locationId);

      let message = {};

      message[`${t("client:tabMasterData:title")}`] = checkValue(client?.title);
      message[`${t("client:tabMasterData:firstName")}`] = checkValue(client?.firstName);

      message[`${t("client:tabMasterData:lastName")}`] = checkValue(client?.lastName);
      message[`${t("client:tabMasterData:street")}`] = checkValue(client?.street);
      message[`${t("client:tabMasterData:zip")}`] = checkValue(client?.zip);
      message[`${t("client:tabMasterData:city")}`] = checkValue(client?.city);
      message[`${t("client:tabMasterData:mobile")}`] = checkValue(client?.mobile);
      message[`${t("client:tabMasterData:phone")}`] = checkValue(client?.phone);
      message[`${t("client:tabEntry:mail")}`] = checkValue(client?.email);
      message[`${t("client:tabMasterData:personId")}`] = checkValue(client?.personId);
      message[`${t("client:tabMasterData:startPeriod")}`] = checkDate(client?.startPeriod);
      message[`${t("client:tabMasterData:endPeriod")}`] = checkDate(client?.endPeriod);
      message[`${t("client:tabMasterData:pensum")}`] = checkValue(client?.pensum);
      message[`${t("client:tabMasterData:qualification")}`] = checkValue(quali?.title);
      message[`${t("client:tabMasterData:jobLearned")}`] = checkValue(client?.jobLearned);
      message[`${t("client:tabMasterData:lastJob")}`] = checkValue(client?.lastJob);
      message[`${t("client:tabMasterData:birthDay")}`] = checkDate(client?.birthDay);
      message[`${t("client:tabMasterData:nationality")}`] = checkValue(nation?.title);
      message[`${t("client:tabMasterData:residencePermit")}`] = checkValue(residentsPermit?.title);
      message[`${t("client:tabMasterData:socialSecurityNumber")}`] = checkValue(client?.socialSecurityNumber);

      message[`${t("client:tabStart:collaboratorId")}`] = checkValue(employeeFA === null || employeeFA === undefined ? "" : employeeFA.name + " " + employeeFA.lastName);

      message[`${t("client:tabMasterData:alkId")}`] = checkValue(myAlk?.title);
      message[`${t("client:config:columnAlkNummer")}`] = checkValue(myAlk?.alkNumber);
      message[`${t("client:tabTableExport:columnStreetALK")}`] = checkValue(myAlk?.street);
      message[`${t("client:tabTableExport:columnPLZALK")}`] = checkValue(myAlk?.zip);
      message[`${t("client:tabTableExport:columnOrtALK")}`] = checkValue(myAlk?.city);
      message[`${t("client:tabTableExport:columnPhoneALK")}`] = checkValue(myAlk?.phone);
      message[`${t("client:tabTableExport:columnEmailALK")}`] = checkValue(myAlk?.email);

      message[`${t("client:tabInterview:date")}`] = checkDate(client?.interview?.date);
      message[`${t("client:tabTableExport:columnStartTimeInterview")}`] = checkValue(client?.interview?.starttime);
      message[`${t("client:tabInterview:collaboratorId")}`] = checkValue(employeeVG === null || employeeVG === undefined ? "" : employeeVG.name + " " + employeeVG.lastName); //  "ma_vorgespraech":
      message[`${t("client:tabInterview:status")}`] = checkValue(interviewSt?.title);
      message[`${t("client:tabInterview:feedback")}`] = checkValue(client?.interview?.feedback);
      message[`${t("client:tabInterview:nextSteps")}`] = checkValue(client?.interview?.nextSteps);

      message[`${t("client:tabTableExport:columnStartDateEntry")}`] = checkDate(client?.entry?.date);
      message[`${t("client:tabTableExport:columnStartTimeEntry")}`] = checkValue(client?.entry?.starttime);
      message[`${t("client:tabEntry:trials")}`] = checkValue(client?.entry?.trials);
      message[`${t("client:tabEntry:objective1")}`] = checkValue(client?.entry?.objective1);
      message[`${t("client:tabEntry:objective2")}`] = checkValue(client?.entry?.objective2);
      message[`${t("client:tabEntry:objective3")}`] = checkValue(client?.entry?.objective3);
      message[`${t("client:tabEntry:objectiveIndividual")}`] = checkValue(client?.entry?.objectiveIndividual);
      message[`${t("client:tabEntry:location")}`] = checkValue(location?.title);       // "tn_standortinfos":
      message[`${t("client:tabEntry:additionalInfoId")}`] = checkValue(additionalInf?.title)    // "tn_zusatzinfos":

      message[`${t("client:tabTableExport:consultantName")}`] = checkValue(consultant === null || consultant === undefined ? "" : consultant.name + " " + consultant.lastName)

      message[`${t("client:tabExit:exitDate")}`] = checkDate(client?.exit?.date);
      message[`${t("client:tabExit:alvStatus")}`] = checkValue(alvStat?.title); //  "tn_alv_status_bei_austritt":
      message[`${t("client:tabExit:etl")}`] = checkValue(etlSolution?.title);
      message[`${t("client:tabExit:executionTime")}`] = checkValue(client?.exit?.executionTime);
      message[`${t("client:tabExit:firstWorkingDay")}`] = checkDate(client?.exit?.firstWorkingDay);
      message[`${t("client:tabExit:employerName")}`] = checkValue(client?.exit?.employer?.name);
      message[`${t("client:tabExit:employerContact")}`] = checkValue(client?.exit?.employer?.contact);
      message[`${t("client:tabExit:employerAddress")}`] = checkValue(client?.exit?.employer?.address);
      message[`${t("client:tabExit:employerFunction")}`] = checkValue(client?.exit?.employer?.function);
      message[`${t("client:tabExit:employerRemarks")}`] = checkValue(client?.exit?.employer?.remarks);
      message[`${t("client:tabExit:strategy")}`] = checkValue(strategy?.title);
      message[`${t("client:tabExit:intermediaryInterview")}`] = checkValue(client?.exit?.intermediaryInterview);

      message[`${t("client:tabAMM:status")}`] = client?.amm?.status === true ? "Aktiv" : "Passiv";
      message[`${t("client:tabAMM:abandonment")}`] = checkValue(termination?.title);
      message[`${t("client:tabAMM:remarks")}`] = checkValue(client?.amm?.remarks);

      return message;
    })
  };

  const fetchData = async () => {
    Auth.initialize(props.Config);
    const auth = Auth.getInstance();

    auth
      .login()
      .then(async () => {
        try {
          setAlk(await appService.getAlks());
          setConsultants(await appService.getConsultants());
          setEmployees(await appService.getEmployees());
          setCountries(await appService.getNationality());
          setResidencePermits(await appService.getResidencePermits());

          setInterviewStatus(await appService.getInterviewStatus());
          setTermination(await appService.getTerminations());
          setStrategy(await appService.getStrategies());
          setEtlSolution(await appService.getEtlSolutions());
          setAlvStatus(await appService.getAlvStatus());

          let locations = await appService.getOfficeLocations();
          const username = auth.getUserAccount().username;
          const employee = await appService.getEmployees();
          let user = employee.find(e => e.username?.toLowerCase() === username.toLowerCase());
          //console.log(username);
          setData({ ...data, locations: locations, username: username, employee: employee, currentUser: user, loading: false })
        } catch (ex) {
          setData({ loading: false, locations: [], isMember: false, isAdmin: false, isDataAdmin: false });
        }
      })
      .catch((ex) => {
        if (ex.errorCode !== "interaction_in_progress") {
          setData({ loading: false, locations: [], isMember: false, isAdmin: false, isDataAdmin: false });
        }
      });
  };

  React.useEffect(() => {
    fetchData()
  }, []);

  const fetchLocationDependentData = async () => {
    if (appService.getLocation() === undefined)
      return;

    setAdditionalInfo(await appService.getAdditionalInfo());
    setQualifications(await appService.getQualification());
    setLocationInfo(await appService.getLocationInfos());
    setClients(await appService.getClients());
  };

  const onClientDelete = async (id: string) => {
    if (confirm(t("client:tabLanding:deleteMessage"))) {
      await appService.delEntity("clients", id);

      let activities = await appService.getActivities(id);
      for (let i = 0; i < activities.length; i++) {
        await appService.delEntityByCustomerId(id, "activities", activities[i].key);
      }

      let applications = await appService.getApplications(id);
      for (let i = 0; i < applications.length; i++) {
        await appService.delEntityByCustomerId(id, "applications", applications[i].key);
      }

      fetchLocationDependentData()

      //fetchData();
    }
  }

  React.useEffect(() => {
    fetchLocationDependentData()
  }, [appService.getLocation()]);

  const { currentUser, locations } = data;
  const changeLocation = (newLocation: string) => {
    appService.setDefaultLocation(newLocation)
    if (window.location.pathname.split("/").length === 2) {
      history.push(`/${newLocation}`)
    }
    setData({ ...data, currentLocation: newLocation })
  }

  if (data.loading === true) {
    return <Loading isLoading={data.loading}></Loading>;
  }

  let isAdmin = currentUser?.isadmin;
  const filteredLocations = locations?.filter(location => {
    if ((location.members?.includes(currentUser?.key) === true || isAdmin === true)) {
      return location;
    }
  })
  const getLocationByPath = () => {
    const elements = window.location.pathname.split("/");
    if (elements.length >= 2) {
      return elements[1];
    }
    return undefined;
  }
  if (data.currentLocation === undefined && filteredLocations.length > 0) {
    let suggestedLocation = getLocationByPath();
    if ((suggestedLocation === undefined || suggestedLocation === "") && filteredLocations.length > 0) {
      suggestedLocation = filteredLocations[0].path;
    }
    changeLocation(suggestedLocation);
  }

  const officeLocation = locations?.find(p => p.path === data.currentLocation);
  let isDataAdmin = isAdmin || officeLocation?.dataadmins?.includes(currentUser?.key)
  let isMember = isAdmin || officeLocation?.members?.includes(currentUser?.key);

  if (officeLocation === undefined) {
    isMember = false;
    isDataAdmin = false;
    isAdmin = false;
  }

  if (isDataAdmin === false && isDataAdmin === false && isMember === false) { // if (!isDataAdmin && !isDataAdmin && !isMember) {
    //console.log(isDataAdmin,isAdmin, isMember,currentUser)
    return <AccessDenied isAccessDenied={data.loading === false}></AccessDenied>
  }

  if (isMember === false) {
    return (<article className="message is-danger">
      <div className="message-header">
        <p>{t("client:tabAccess:noAccess")}</p>
        <button className="delete" aria-label="delete"></button>
      </div>
      <div className="message-body">
        {t("client:tabLanding:noAccess")}
      </div>
    </article>)
  }

  require(`./App-default.css`)

  const tableService = new TableService(t, consultants, employee, qualifications, additionalInfo, locationInfo, interviewStatus, qualifications, alvStatus, etlSolutions);

  return (
    <React.Fragment>
      <Navigation color={officeLocation?.color} isAdmin={isAdmin} isDataAdmin={isDataAdmin}>
        <LocationList currentLocation={officeLocation} locations={filteredLocations} changeLocation={changeLocation} />
      </Navigation>
      <div className="container is-fluid">
        <Switch>
          <Route
            path={["/", "/:location", "/:location/program"]}
            exact
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <InProgramContainer clients={clients} tableService={tableService} onClientDelete={onClientDelete} exportAllTable={exportAllTable} />
              </EnsureLocation>
            }
          />
          <Route
            path={["/", "/:location", "/:location/programpictures"]}
            exact
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <InProgramPicturesContainer clients={clients} tableService={tableService} onClientDelete={onClientDelete} exportAllTable={exportAllTable} />
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/programplus"]}
            exact
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <InProgramPlusContainer clients={clients} tableService={tableService} onClientDelete={onClientDelete} exportAllTable={exportAllTable} />
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/oldclients"]}
            exact
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <AllClientsOlderOneYearContainer clients={clients} tableService={tableService} onClientDelete={onClientDelete} exportAllTable={exportAllTable} />
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/all"]}
            exact
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <AllClientsContainer clients={clients} tableService={tableService} onClientDelete={onClientDelete} exportAllTable={exportAllTable} />
              </EnsureLocation>
            }
          />


          <Route
            path={["/:location/entries"]}
            exact
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <EntriesContainer clients={clients} tableService={tableService} onClientDelete={onClientDelete} exportAllTable={exportAllTable} />
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/entriesplus"]}
            exact
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <EntriesPlusContainer clients={clients} tableService={tableService} onClientDelete={onClientDelete} exportAllTable={exportAllTable} />
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/exits"]}
            exact
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <ExitsContainer clients={clients} tableService={tableService} onClientDelete={onClientDelete} exportAllTable={exportAllTable} />
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/upcomingexits"]}
            exact
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <UpcomingExitsContainer clients={clients} tableService={tableService} onClientDelete={onClientDelete} exportAllTable={exportAllTable} />
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/ammsearch"]}
            exact
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <AmmSearchContainer clients={clients} tableService={tableService} onClientDelete={onClientDelete} exportAllTable={exportAllTable} />
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/activities"]}
            exact
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <ActivitiesContainer clients={clients} tableService={tableService} onClientDelete={onClientDelete} exportAllTable={exportAllTable} />
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/details"]}
            exact
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <ClientDetails onSubmit={onSubmit} clientDriveId={undefined} detailId={undefined} locationInfo={locationInfo} strategy={strategy} termination={termination} etlSolutions={etlSolutions} alvStatus={alvStatus} additionalInfo={additionalInfo} interviewStatus={interviewStatus} qualifications={qualifications} residencePermit={residencePermit} alk={alk} consultants={consultants} employee={employee} countries={countries} clients={clients} />
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/details/:detailId"]}
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <ClientDetails onSubmit={onSubmit} clientDriveId={officeLocation?.clientDriveId} detailId={renderProps.match.params.detailId} locationInfo={locationInfo} strategy={strategy} termination={termination} etlSolutions={etlSolutions} alvStatus={alvStatus} additionalInfo={additionalInfo} interviewStatus={interviewStatus} qualifications={qualifications} residencePermit={residencePermit} alk={alk} consultants={consultants} employee={employee} countries={countries}  clients={clients} />
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/config/expenses"]}
            exact
            render={(renderProps) =>
              <NoAdminCheck isAdmin={isAdmin}>
                <EnsureLocation location={renderProps.match.params.location}>
                  <ExpenseOverview isAdmin={isAdmin} />
                </EnsureLocation>
              </NoAdminCheck>
            }
          />
          <Route
            path={["/:location/config/expenses/details"]}
            exact
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <ExpenseDetails id={undefined} isAdmin={isAdmin} />
              </EnsureLocation>
            }
          />

          <Route
            path={["/:location/config/expenses/details/:id"]}
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <ExpenseDetails id={renderProps.match.params.id} isAdmin={isAdmin} />
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/config/freedays"]}
            exact
            render={(renderProps) =>
              <NoAdminCheck isAdmin={isAdmin}>
                <EnsureLocation location={renderProps.match.params.location}>
                  <FreedayOverview isAdmin={isAdmin}></FreedayOverview>
                </EnsureLocation>
              </NoAdminCheck>
            }
          />
          <Route
            path={["/:location/config/freedays/details"]}
            exact
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <FreedayDetails id={undefined} isAdmin={isAdmin}></FreedayDetails>
              </EnsureLocation>
            }
          />

          <Route
            path={["/:location/config/freedays/details/:id"]}
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <FreedayDetails id={renderProps.match.params.id} isAdmin={isAdmin}></FreedayDetails>
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/config/alks"]}
            exact
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <AlkOverview isAdmin={isAdmin}></AlkOverview>
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/config/alks/details"]}
            exact
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <AlkDetails id={undefined} isAdmin={isAdmin}></AlkDetails>
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/config/employeeFunctions"]}
            exact
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <EmployeeFunctionOverview isAdmin={isAdmin}></EmployeeFunctionOverview>
              </EnsureLocation>
            }
          />

          <Route
            path={["/:location/config/employeeFunctions/details"]}
            exact
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <EmployeeFunctionDetails id={undefined} isAdmin={isAdmin}></EmployeeFunctionDetails>
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/config/employeeFunctions/details/:id"]}
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <EmployeeFunctionDetails id={renderProps.match.params.id} isAdmin={isAdmin}></EmployeeFunctionDetails>
              </EnsureLocation>
            }
          />

          <Route
            path={["/:location/config/consultantFunctions"]}
            exact
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <ConsultantFunctionOverview isAdmin={isAdmin}></ConsultantFunctionOverview>
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/config/consultantFunctions/details"]}
            exact
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <ConsultantFunctionDetails id={undefined} isAdmin={isAdmin}></ConsultantFunctionDetails>
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/config/consultantFunctions/details/:id"]}
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <ConsultantFunctionDetails id={renderProps.match.params.id} isAdmin={isAdmin} ></ConsultantFunctionDetails>
              </EnsureLocation>
            }
          />

          <Route
            path={["/:location/config/locationinfo"]}
            exact
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <LocationInfoOverview isDataAdmin={isDataAdmin}></LocationInfoOverview>
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/config/locationinfo/details"]}
            exact
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <LocationInfoDetails id={undefined} isDataAdmin={isDataAdmin}></LocationInfoDetails>
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/config/locationinfo/details/:id"]}
            exact
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <LocationInfoDetails id={renderProps.match.params.id} isDataAdmin={isDataAdmin}></LocationInfoDetails>
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/config/officelocations"]}
            exact
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <OfficelocationsOverview isAdmin={isAdmin}></OfficelocationsOverview>
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/config/officelocations/details"]}
            exact
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <OfficelocationsDetails id={undefined} isAdmin={isAdmin}></OfficelocationsDetails>
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/config/officelocations/details/:id"]}
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <OfficelocationsDetails id={renderProps.match.params.id} isAdmin={isAdmin}></OfficelocationsDetails>
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/config/alks/details/:id"]}
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <AlkDetails id={renderProps.match.params.id} isAdmin={isAdmin}></AlkDetails>
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/config/consultantLocations"]}
            exact
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <ConsultantLocationOverview isAdmin={isAdmin}></ConsultantLocationOverview>
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/config/consultantLocations/details"]}
            exact
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <ConsultantLocationDetails id={undefined} isAdmin={isAdmin}></ConsultantLocationDetails>
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/config/consultantLocations/details/:id"]}
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <ConsultantLocationDetails id={renderProps.match.params.id} isAdmin={isAdmin}></ConsultantLocationDetails>
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/config/consultantdepartments"]}
            exact
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <ConsultantDepartmentOverview isAdmin={isAdmin}></ConsultantDepartmentOverview>
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/config/consultantdepartments/details"]}
            exact
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <ConsultantDepartmentDetails id={undefined} isAdmin={isAdmin}></ConsultantDepartmentDetails>
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/config/consultantdepartments/details/:id"]}
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <ConsultantDepartmentDetails id={renderProps.match.params.id} isAdmin={isAdmin}></ConsultantDepartmentDetails>
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/config/employee"]}
            exact
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <EmployeeOverview isAdmin={isAdmin}></EmployeeOverview>
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/config/employee/details"]}
            exact
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <EmployeeDetails id={undefined} isAdmin={isAdmin}></EmployeeDetails>
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/config/employee/details/:id"]}
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <EmployeeDetails id={renderProps.match.params.id} isAdmin={isAdmin}></EmployeeDetails>
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/config/consultants"]}
            exact
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <ConsultantOverview isAdmin={isAdmin}></ConsultantOverview>
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/config/consultants/details"]}
            exact
            render={(renderProps) =>

              <EnsureLocation location={renderProps.match.params.location}>
                <ConsultantDetails id={undefined} isAdmin={isAdmin}></ConsultantDetails>
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/config/consultants/details/:id"]}
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <ConsultantDetails id={renderProps.match.params.id} isAdmin={isAdmin}></ConsultantDetails>
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/config/nationalities"]}
            exact
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <NationalityOverview isAdmin={isAdmin}></NationalityOverview>
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/config/nationalities/details"]}
            exact
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <NationalityDetails id={undefined} isAdmin={isAdmin}></NationalityDetails>
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/config/nationalities/details/:id"]}
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <NationalityDetails id={renderProps.match.params.id} isAdmin={isAdmin} />
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/config/terminations"]}
            exact
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <TerminationOverview isAdmin={isAdmin}></TerminationOverview>
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/config/terminations/details"]}
            exact
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <TerminationDetails id={undefined} isAdmin={isAdmin}></TerminationDetails>
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/config/terminations/details/:id"]}
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <TerminationDetails id={renderProps.match.params.id} isAdmin={isAdmin} />
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/config/qualifications"]}
            exact
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <QualificationOverview isDataAdmin={isDataAdmin}></QualificationOverview>
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/config/qualifications/details"]}
            exact
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <QualificationDetails id={undefined} isDataAdmin={isDataAdmin}></QualificationDetails>
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/config/qualifications/details/:id"]}
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <QualificationDetails id={renderProps.match.params.id} isDataAdmin={isDataAdmin} />
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/config/interviewstatus"]}
            exact
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <InterviewStatusOverview isAdmin={isAdmin}></InterviewStatusOverview>
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/config/interviewstatus/details"]}
            exact
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <InterviewStatusDetails id={undefined} isAdmin={isAdmin}></InterviewStatusDetails>
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/config/interviewstatus/details/:id"]}
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <InterviewStatusDetails id={renderProps.match.params.id} isAdmin={isAdmin} />
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/config/additionalinfo"]}
            exact
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <AdditionalInfoOverview isDataAdmin={isDataAdmin}></AdditionalInfoOverview>
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/config/additionalinfo/details"]}
            exact
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <AdditionalInfoDetails id={undefined} isDataAdmin={isDataAdmin}></AdditionalInfoDetails>
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/config/additionalinfo/details/:id"]}
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <AdditionalInfoDetails id={renderProps.match.params.id} isDataAdmin={isDataAdmin} />
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/config/alvstatus"]}
            exact
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <AlvstatusOverview isAdmin={isAdmin}></AlvstatusOverview>
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/config/alvstatus/details"]}
            exact
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <AlvstatusDetails id={undefined} isAdmin={isAdmin}></AlvstatusDetails>
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/config/alvstatus/details/:id"]}
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <AlvstatusDetails id={renderProps.match.params.id} isAdmin={isAdmin} />
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/config/etlsolution"]}
            exact
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <EtlSolutionOverview isAdmin={isAdmin}></EtlSolutionOverview>
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/config/etlsolution/details"]}
            exact
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <EtlSolutionDetails id={undefined} isAdmin={isAdmin}></EtlSolutionDetails>
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/config/etlsolution/details/:id"]}
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <EtlSolutionDetails id={renderProps.match.params.id} isAdmin={isAdmin} />
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/config/strategies"]}
            exact
            render={(renderProps) =>

              <EnsureLocation location={renderProps.match.params.location}>
                <StrategiesOverview isAdmin={isAdmin}></StrategiesOverview>
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/config/strategies/details"]}
            exact
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <StrategiesDetails id={undefined} isAdmin={isAdmin}></StrategiesDetails>
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/config/strategies/details/:id"]}
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <StrategiesDetails id={renderProps.match.params.id} isAdmin={isAdmin} />
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/config/residencepermits"]}
            exact
            render={(renderProps) =>

              <EnsureLocation location={renderProps.match.params.location}>
                <ResidencePermitOverview isAdmin={isAdmin}></ResidencePermitOverview>
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/config/residencepermits/details"]}
            exact
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <ResidencePermitDetails id={undefined} isAdmin={isAdmin}></ResidencePermitDetails>
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/config/residencepermits/details/:id"]}
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <ResidencePermitDetails id={renderProps.match.params.id} isAdmin={isAdmin} />

              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/documents"]}
            exact
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <DocumentsDefaultContainer clients={clients} tableService={tableService} clientDriveId={officeLocation?.clientDriveId} mailMergeDriveId={officeLocation?.mailMergeDriveId} templateDriveId={officeLocation?.templateDriveId} exportAllTable={exportAllTable} />
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/documents/interview"]}
            exact
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <DocumentsInterviewContainer clients={clients} tableService={tableService} clientDriveId={officeLocation?.clientDriveId} mailMergeDriveId={officeLocation?.mailMergeDriveId} templateDriveId={officeLocation?.templateDriveId} exportAllTable={exportAllTable} />
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/presence"]}
            exact
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <Presence clients={clients} tableService={tableService} exportAllTable={exportAllTable} />
              </EnsureLocation>
            }
          />
          <Route
            path={["/:location/presence/print"]}
            exact
            render={(renderProps) =>
              <EnsureLocation location={renderProps.match.params.location}>
                <PresencePrint clients={clients} tableService={tableService} exportAllTable={exportAllTable} clientDriveId={officeLocation?.clientDriveId} mailMergeDriveId={officeLocation?.mailMergeDriveId} allowanceFactor={officeLocation?.allowanceFactor} templateDriveId={officeLocation?.templateDriveId} />
              </EnsureLocation>
            }
          />
        </Switch>
      </div>
    </React.Fragment>
  );
}

export default withTranslation()(App);
