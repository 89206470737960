import React from "react";
import { IClient } from "../../core/cm.types"
import { Table } from "../table/Table"
import { useMemo } from "react";
import { TableService } from "../../services/table.service";
import { date } from "yup";
import moment from "moment";


export const PastExits: React.FunctionComponent<{ suffix: any, postfix: any, clients: IClient[], tableService: TableService, exportAllTable(rows) }> = (props) => {
    const { clients, tableService, suffix, postfix, exportAllTable } = props;

    //const today = (new Date()).getTime();
    const thisDay = moment(new Date()).startOf('day');

    const primaryInterviewStatusId = tableService.getPrimaryInterviewStatusId();
    const data = clients.filter((client) => {
        if (client?.entry?.date === undefined || client?.exit?.date === undefined)
            return false;

        //const exitDate = Date.parse(client.exit.date);
        const exit = moment(client.exit.date).startOf('day');
        if (client?.firstName ==="Daniel" && client?.lastName === "Marti") {
            console.log(`${client.firstName}` + ' ' + `${client.lastName}`);
            console.log('exitDate' + ` ${exit}`);
            console.log('today' + ` ${thisDay}`);
            console.log('result' + ` ${(exit < thisDay)}`);
        }

        return client.interview?.statusId === primaryInterviewStatusId &&
            exit < thisDay;
    });

    const columns = useMemo(() => [
        tableService.getKeyColumn(),
        tableService.getFirstNameColumn(),
        tableService.getLastNameColumn(),
        tableService.getMailColumn(),
        tableService.getEntryEmployeeColumn(),
        tableService.getConsultantColumn(),
        tableService.getEntryDateColumn(),
        tableService.getExitDateColumn(),
        tableService.getExitAlvStatusColumn(),
        tableService.getEtlStatusColumn(),
        tableService.getQualificationColumn(),
        tableService.getEntryLocationColumn(),
        tableService.getAmmStatusColumn(),
        tableService.getEntryAdditionalinfoColumn(),
    ], [tableService]);

    const sortBy = [
        { id: 'exit.date', desc: true }
    ];

    return (
        <Table data={data} columns={columns} columnssuffix={suffix} columnspostfix={postfix} sortBy={sortBy} exportAllTable={exportAllTable} />
    )
};
