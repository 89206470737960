import React from "react";
import appService from "../../../services/app.service";
import { DetailsBase } from "../base/DetailsBase";
import { useTranslation } from "react-i18next";
import { NoAdminAlert } from "../../NoAdminAlert"

export const ConsultantDepartmentDetails: React.FunctionComponent<{
    id: string | undefined;
    isAdmin: boolean
}> = (props) => {
    const { id,isAdmin } = props;
    const { t } = useTranslation();

    const backButton = "/config/consultantDepartments";
    const title = t("client:headers:tabConsDepDetails"); //"zugewiesene Stellen - Abteilung";

    if (isAdmin !== true) { return <NoAdminAlert /> }
    
    return (
        <DetailsBase id={id} backButton={backButton} getById={appService.getConsultantDepartment} saveItem={appService.saveConsultantDepartments} inputTitle={title}></DetailsBase>
    );
};
