import React from "react";

export const TableSelectionCheckbox = React.forwardRef((props, ref) => {
  const defaultRef = React.useRef()
  const resolvedRef = ref || defaultRef
  const { indeterminate } = props as any;

  React.useEffect(() => {
    (resolvedRef as any).current.indeterminate = indeterminate
  }, [resolvedRef, indeterminate])

  return (
    <input type="checkbox" ref={resolvedRef as any} {...props} />
  )
});
