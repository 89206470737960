import React from 'react';
import { useHistory } from 'react-router-dom';
import appService from "../services/app.service";

export const LocationAwareButton: React.FunctionComponent<{ title?: string, className, relativeLocation: string }> = (
  props
) => {
  const {title, relativeLocation, className} = props;
  const history = useHistory();
  
  const changeLocation = (history: any) => {
    const location = appService.getLocation();
    history.push(`/${location}${relativeLocation}`)
  }

  return (
    <button className={className} type='button' onClick={(e) => { e.preventDefault(); changeLocation(history); }}>{title}{props.children}</button>
  );
};
