import React from "react";
import { Field, Formik, FormikHelpers } from "formik";
import { Input } from "../../fields/Input";
import appService from "../../../services/app.service";
import { Loading } from "../../Loading";
import * as Yup from "yup";

import { useHistory } from "react-router-dom";
import { IEmployee, IEmployeeFunction, IOfficeLocation } from "../../../core/cm.types";
import { LocationAwareButton } from "../../LocationAwareButton";
import { useTranslation } from "react-i18next";
import { Textarea } from "../../fields/Textarea";
import { DateField } from "../../fields/DateField";
import { Select } from "../../fields/Select";
import { Checkbox } from "../../fields/Checkbox";
import { NoAdminAlert } from "../../NoAdminAlert"
import MaskedInput from "react-text-mask";
import { PhoneField } from "../../fields/PhoneField"

const validationSchema = Yup.object().shape({
    name: Yup.string().required("*"),
    lastName: Yup.string().required("*"),
    mailPrivate: Yup.string().email("Bitte eine gültige E-Mail Addresse angeben"),
    email: Yup.string().email("Bitte eine gültige E-Mail Addresse angeben"),
    username: Yup.string().email("Bitte eine gültige E-Mail Addresse angeben")
});

export const EmployeeDetails: React.FunctionComponent<{
    id: string | undefined;
    isAdmin: boolean;
}> = (props) => {
    const { t } = useTranslation();
    const { id, isAdmin } = props;
    const backButton = "/config/employee"
    const isNew = id === undefined || id === null;
    const title = <h1 className="title is-3">{t("client:headers:tabEmployeeII")}</h1>
    const history = useHistory();
    const [data, setData] = React.useState<{
        isLoading: boolean;
        data: IEmployee;
    }>({
        isLoading: true,
        data: {}
    });

    const [officeLocations, setOfficeLocation] = React.useState<IOfficeLocation[]>([]);
    const [employeeFunction, setEmployeeFunction] = React.useState<IEmployeeFunction[]>([]);

    React.useEffect(() => {
        appService.getEmployeeFunctions().then((functions) => {
            setEmployeeFunction(functions);
        })

        appService.getOfficeLocations().then((locations) => {
            setOfficeLocation(locations);
        })

        const fetchData = async () => {
            const data = await appService.getEmployee(id);
            setData({
                isLoading: false,
                data: data,
            });
        };

        if (isNew) {
            setData({
                isLoading: false,
                data: {}
            });
        } else {
            fetchData();
        }
    }, []);

    const onSubmit = (
        values: Readonly<IEmployee | undefined>,
        formikHelpers: FormikHelpers<Readonly<IEmployee>>
    ) => {
        const data = values;

        const submit = async () => {
            if (data !== undefined && data !== null) {
                appService.saveEmployee(data).then((modulesResults) => {
                    var location = appService.getLocation();
                    history.push(`/${location}${backButton}`)
                }).catch(() => {
                    alert(t("client:common:errorOnSave"));
                });
            }
        };

        submit();
    };

    const functionOption = [{ key: "", text: t("client:tabConsultantDetail:selectFunction") }, ...employeeFunction?.map((item) => {
        return {
            key: item.key,
            text: item.title
        };
    })];

    const officeLocationOption = [{ key: "", text: t("client:tabConsultantDetail:selectLocation") }, ...officeLocations?.map((item) => {
        return {
            key: item.key,
            text: item.title
        };
    })];


    const plzNumberMask = [
        /\d/,
        /\d/,
        /\d/,
        /\d/
    ];

    if (isAdmin !== true) { return <NoAdminAlert /> }

    return (
        <React.Fragment>
            <div className="section defaultSection">
                <Loading isLoading={data.isLoading}></Loading>
                {data.isLoading === false && (
                    <Formik
                        initialValues={data.data}
                        onSubmit={onSubmit}
                        validateOnMount={true}
                        validationSchema={validationSchema}
                        enableReinitialize
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            isValid,
                            dirty,
                            setFieldValue,
                        }) => (
                            <React.Fragment>
                                <form onSubmit={handleSubmit} className="form">
                                    <div className="columns">
                                        <div className="column">{title}</div>
                                        <div className="column button-header">
                                            <button
                                                type="submit"
                                                className={`button is-link is-small is-pulled-right backAndSaveButton ${isSubmitting ? t("client:common:isLoading") : ""
                                                    }`}
                                                disabled={isSubmitting || !dirty || !isValid}
                                            >
                                                {t("client:common:saveButton")}
                                            </button>
                                            <LocationAwareButton title={t("client:common:backButton")} className="button is-small is-pulled-right backAndSaveButton" relativeLocation={backButton}></LocationAwareButton>
                                        </div>
                                    </div>
                                    <div className="columns">
                                        <div className="column">
                                            <Field
                                                name="name"
                                                label={t("client:config:columnFirstName")}
                                                component={Input}
                                            />

                                            <Field
                                                name="lastName"
                                                label={t("client:config:columnLastName")}
                                                component={Input}
                                            />

                                            <Field
                                                name="email"
                                                label={t("client:config:columnEmail")}
                                                component={Input}
                                                suffixControl={
                                                    <div className="control">
                                                        <a className="button" href={`mailto:${values.email}`}>
                                                            <i className={`fas fa-envelope`} /></a>
                                                    </div>
                                                }
                                            />

                                            <Field
                                                name="username"
                                                label={"Benutzername"}
                                                component={Input}
                                            />

                                            <Field
                                                name="emailPrivate"
                                                label={t("client:config:columnMailPrivate")}
                                                component={Input}
                                                suffixControl={
                                                    <div className="control">
                                                        <a className="button" href={`mailto:${values.emailPrivate}`}>
                                                            <i className={`fas fa-envelope`} /></a>
                                                    </div>
                                                }
                                            />
                                            <PhoneField name="mobile" label={t("client:tabMasterData:mobile")} placeholder={t("client:config:columnMobilePlaceholder")} />
                                            <PhoneField name="phone" label={t("client:tabMasterData:phone")} placeholder={t("client:config:columnPhonePlaceholder")} />
                                            <PhoneField name="phonePrivate" label={t("client:config:columnPhonePrivate")} placeholder={t("client:config:columnPhonePrivatePlaceholder")} />

                                        </div>
                                        <div className="column">
                                            <Field
                                                name="description"
                                                label={t("client:config:columnDescription")}
                                                component={Textarea}
                                            />

                                            <Field
                                                name="functionId"
                                                label={t("client:config:columnFunction")}
                                                options={functionOption}
                                                component={Select}
                                            />


                                            <Field
                                                name="officeLocationId"
                                                label={t("client:config:columnLocation")}
                                                options={officeLocationOption}
                                                component={Select}
                                            />


                                            <Field
                                                name="entry"
                                                label={t("client:config:columnEntry")}
                                                dateFormat="dd.MM.yyyy"
                                                selected={new Date(values?.entry)}
                                                component={DateField}
                                            />

                                            <Field
                                                name="exit"
                                                label={t("client:config:columnExit")}
                                                dateFormat="dd.MM.yyyy"
                                                selected={new Date(values?.exit)}
                                                component={DateField}
                                            />

                                            <Field
                                                name="birthday"
                                                label={t("client:config:columnBirthday")}
                                                dateFormat="dd.MM.yyyy"
                                                selected={new Date(values?.birtday)}
                                                component={DateField}
                                            />
                                        </div>

                                    </div>

                                    <Field
                                        name="street"
                                        label={t("client:config:columnStreet")}
                                        component={Input}
                                    />

                                    <div className="columns">
                                        <div className="field column">
                                            <label htmlFor="zip" className="label" style={{ display: "block" }}>
                                                {t("client:config:columnPLZ")}
                                            </label>
                                            <Field
                                                name="zip"
                                                label={t("client:config:columnPLZ")}
                                                component={Input}
                                                render={({ field }) => (
                                                    <MaskedInput
                                                        {...field}
                                                        mask={plzNumberMask}
                                                        id="zip"
                                                        type="text"
                                                        className="input"
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className="column">
                                            <Field
                                                name="city"
                                                label={t("client:config:columnOrt")}
                                                component={Input}
                                            />
                                        </div>
                                    </div>
                                    <div className="columns">
                                        <div className="column">
                                            <h3 className="label">{t("client:config:columnAdmin")}</h3>
                                            <div className="employeeAdmin">
                                                <Field
                                                    name="isadmin"
                                                    label={t("client:config:columnAdminExplain")}
                                                    component={Checkbox}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="button-footer">
                                        <button
                                            type="submit"
                                            className={`button is-link is-small is-pulled-right backAndSaveButton${isSubmitting ? t("client:common:isLoading") : ""
                                                }`}
                                            disabled={isSubmitting || !dirty || !isValid}
                                        >
                                            {t("client:common:saveButton")}
                                        </button>
                                        <LocationAwareButton title={t("client:common:backButton")} className="button is-small is-pulled-right backAndSaveButton" relativeLocation={backButton}></LocationAwareButton>
                                    </div>
                                </form>
                            </React.Fragment>
                        )}
                    </Formik>
                )}
            </div>
        </React.Fragment>
    );
};
