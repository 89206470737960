import React from "react";
import { IClient } from "../../core/cm.types"
import { Table } from "../table/Table"
import { useMemo } from "react";
import { TableService } from "../../services/table.service"
import { TableSelectionCheckbox } from "../table/TableSelectionCheckbox";

export const AllClientsOlderOneYear: React.FunctionComponent<{ clients: IClient[], tableService: TableService, suffix?: any, postfix?: any, exportAllTable(rows) }> = (props) => {
    const { clients, tableService, suffix, postfix, exportAllTable } = props;

    const columns = useMemo(() => [
        tableService.getKeyColumn(),
        tableService.getFirstNameColumn(),
        tableService.getLastNameColumn(),
        tableService.getEntryEmployeeColumn(),
        tableService.getInterviewState(),
        tableService.getConsultantColumn(),
        tableService.getEntryDateColumn(),
        tableService.getExitDateColumn(),
        tableService.getQualificationColumn(),
        tableService.getEntryLocationColumn(),
        tableService.getAmmStatusColumn(),
        tableService.getEntryAdditionalinfoColumn(),
    ], [tableService]);

    const sortBy = [
        { id: 'modified', desc: true }
    ];

    const newPostFix = postfix ?? [];
    const newSuffix = suffix ?? [
        {
            id: 'selection',
            Header: ({ getToggleAllPageRowsSelectedProps }) => (
                <TableSelectionCheckbox {...getToggleAllPageRowsSelectedProps()} />
            ),
            Cell: ({ row }) => (
                <TableSelectionCheckbox {...row.getToggleRowSelectedProps()} />
            ),
        }] as any;

    var date = new Date();
    date.setFullYear(date.getFullYear() - 1);
    const filteredClients = clients.filter((client) => {
        //var createdDate = new Date(client.created)
        //return createdDate < date
        if (client.modified === undefined) {
          var createdDate = new Date(client.created);
          return createdDate < date;
        }
        if (client.exit === undefined) {
          var modifiedDate = new Date(client.modified);
          return modifiedDate < date;
        }
        var exitDate = new Date(client.modified)
        return exitDate < date
    })

    return (
        <Table data={filteredClients} columns={columns} columnssuffix={newSuffix} columnspostfix={newPostFix} sortBy={sortBy} exportAllTable={exportAllTable} />
    )
};
