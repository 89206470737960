import React from "react";
import { Field } from "formik";
import { Textarea } from "../fields/Textarea";

import { Select } from "../fields/Select";
import { IBaseDetailsItem, IClient, IEmployee, ILocationInfo } from "../../core/cm.types";
import { DateField } from "../fields/DateField";
import 'react-tabs/style/react-tabs.css';
import { Input } from "../fields/Input";
import { useTranslation } from "react-i18next";
import MaskedInput from "react-text-mask";
import { ClientConsultantDropdown } from "../fields/ClientConsultantDropdown";

export const Entry: React.FunctionComponent<{ employee: IEmployee[], additionalInfo: IBaseDetailsItem[], isReadOnly: boolean, client: IClient, locationInfo: ILocationInfo[] }> = (props) => {
    const { t } = useTranslation();
    const { employee, isReadOnly, client, additionalInfo, locationInfo } = props;

    const locationOption = [{ key: "", text: t("client:tabEntry:selectLocation") }, ...locationInfo?.map((item) => {
        return {
            key: item.key,
            text: item.title
        };
    })];

    const additionalInfoOptions = [{ key: "", text: t("client:tabEntry:selectInfo") }, ...additionalInfo?.map((item) => {
        return {
            key: item.key,
            text: item.title
        };
    })];

    const dayOptions = [{ key: "Montag", text: "Montag" }, { key: "Dienstag", text: "Dienstag" }, { key: "Mittwoch", text: "Mittwoch" }, { key: "Donnerstag", text: "Donnerstag" }, { key: "Freitag", text: "Freitag" }];

    const timeNumberMask = [
        /[0-1]/,
        /[0-9]/,
        ":",
        /[0-9]/,
        /[0-9]/
    ];


    return (
        <React.Fragment>
            <Field
                name="entry.date"
                disabled={isReadOnly}
                label={t("client:tabEntry:entryDate")}
                dateFormat="dd.MM.yyyy"
                selected={new Date(client?.entry?.date)}
                component={DateField}
            />
            <Field
                name="entry.day"
                label={t("client:tabEntry:day")}
                disabled={isReadOnly}
                options={dayOptions}
                component={Select}
            />
            <div className="field">
                <label htmlFor="entry.starttime" className="label" style={{ display: "block" }}>
                    {t("client:tabEntry:startTime")}
                </label>
                <Field
                    name="entry.starttime"
                    label={t("client:tabEntry:startTime")}
                    component={Input}
                    render={({ field }) => (
                        <MaskedInput
                            {...field}
                            mask={timeNumberMask}
                            id="entry.starttime"
                            placeholder={t("client:tabMasterData:timePlaceholder")}
                            type="text"
                            disabled={isReadOnly}
                            className="input"
                        />
                    )}
                />
            </div>
            <Field
                name="exit.date"
                disabled={isReadOnly}
                label={t("client:tabEntry:exitDate")}
                dateFormat="dd.MM.yyyy"
                selected={new Date(client?.exit?.date)}
                component={DateField}
            />
            <Field
                name="entry.trials"
                label={t("client:tabEntry:trials")}
                disabled={isReadOnly}
                component={Input}
            />
            <Field
                name="entry.objective1"
                label={t("client:tabEntry:objective1")}
                disabled={isReadOnly}
                component={Textarea}
            />
            <Field
                name="entry.objective2"
                label={t("client:tabEntry:objective2")}
                disabled={isReadOnly}
                component={Textarea}
            />
            <Field
                name="entry.objective3"
                label={t("client:tabEntry:objective3")}
                disabled={isReadOnly}
                component={Textarea}
            />
            <Field
                name="entry.objectiveIndividual"
                label={t("client:tabEntry:objectiveIndividual")}
                disabled={isReadOnly}
                component={Textarea}
            />
            <ClientConsultantDropdown name="entry.employeeId" optionlabel={t("client:tabStart:collaboratorOptions")} itemlabel={t("client:tabStart:collaboratorId")} items={employee} isDisabled={isReadOnly} />
            <Field
                name="entry.locationId"
                label={t("client:tabEntry:location")}
                disabled={isReadOnly}
                options={locationOption}
                component={Select}
            />
            <Field
                name="entry.additionalInfoId"
                label={t("client:tabEntry:additionalInfoId")}
                disabled={isReadOnly}
                options={additionalInfoOptions}
                component={Select}
            />
        </React.Fragment>
    );
};
