import React from "react";
import { IActivity } from "../../../core/cm.types";
import { Input } from "../../fields/Input";
import { ModalBox } from "../../fields/ModalBox";
import appService from "../../../services/app.service";
import DatePicker from "react-datepicker";

import { useTranslation } from "react-i18next";
import { RichTextBox } from "../../fields/RichTextBox";

export const ActivityDetail: React.FunctionComponent<{ isActive: boolean, trackState: any, clientId: string, activityId: string | undefined, onSubmit(activity: IActivity): void, onCloseClick?(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): any }> = (props) => {
    const { t } = useTranslation();
    const { isActive, onCloseClick, onSubmit, activityId, clientId, trackState } = props;
    const isNew = activityId === undefined;
    const [activity, setCurrentActivity] = React.useState<IActivity | undefined>({
        activityDate: new Date().toISOString()
    });

    const initializeActivity = () => {
        setCurrentActivity({
            activityDate: new Date().toISOString()
        })
    }

    React.useEffect(() => {
        const fetchData = async () => {
            const activity = await appService.getActivity(clientId, activityId);
            setCurrentActivity(activity);
        };
        if (!isNew)
            fetchData();
        else {
            initializeActivity();
        }
    }, [activityId]);

    return (
        <ModalBox title={t("client:tabActivities:modalTitle")} onConfirmClick={() => {
            onSubmit(activity);
            initializeActivity();
        }} onCloseClick={onCloseClick} isActive={isActive}>
            <DatePicker className={`input date-activities`} dateFormat="dd.MM.yyyy HH:mm"
                locale={"de"}
                showTimeSelect
                timeFormat="HH:mm"
                selected={activity.activityDate !== undefined ? new Date(activity.activityDate) : new Date()}
                onChange={val => setCurrentActivity({
                    ...activity,
                    activityDate: val
                })} />

            <Input value={activity.subject} onChange={(event) => {
                setCurrentActivity({
                    ...activity,
                    subject: event.target.value
                })
            }} />

            <RichTextBox trackState={trackState} refreshvalue={activity.key} value={activity?.description} setFieldValue={(contents) => {
                setCurrentActivity({
                    ...activity,
                    description: contents
                })
            }} />
        </ModalBox>
    );
};
