import React from "react";
import { DetailsBase } from "../base/DetailsBase";
import appService from "../../../services/app.service";
import { useTranslation } from "react-i18next";
import { NoAdminAlert } from "../../NoAdminAlert"

export const StrategiesDetails: React.FunctionComponent<{
    id: string | undefined;
    isAdmin:boolean;
}> = (props) => {
    const { id,isAdmin } = props;
    const { t } = useTranslation();
    const backButton = "/config/strategies";
    const title = t("client:headers:tabStrategies");

    if (isAdmin !== true) { return <NoAdminAlert /> }

    return (
        <DetailsBase id={id} backButton={backButton} getById={appService.getStrategiesById} hasNumber={true} saveItem={appService.saveStrategies} inputTitle={title}></DetailsBase>
    );
};
