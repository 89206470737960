import React from "react";
import { Field } from "formik";
import { Textarea } from "../fields/Textarea";

import { Select } from "../fields/Select";
import { IBaseDetailsItem, IClient } from "../../core/cm.types";
import { DateField } from "../fields/DateField";
import 'react-tabs/style/react-tabs.css';
import { Input } from "../fields/Input";
import { useTranslation } from "react-i18next";

export const Exit: React.FunctionComponent<{ alvStatus: IBaseDetailsItem[], etlSolutions: IBaseDetailsItem[], strategies: IBaseDetailsItem[], isReadOnly: boolean, client: IClient }> = (props) => {
    const { t } = useTranslation();
    const { isReadOnly, client, alvStatus, etlSolutions, strategies } = props;

    const alvStatusOptions = [{ key: "", text: t("client:tabExit:statusOptions") }, ...alvStatus?.map((item) => {
        return {
            key: item.key,
            text: item.title
        };
    })];

    const etlData = [{ key: "", text: t("client:tabExit:etlOptions") }, ...etlSolutions?.map((item) => {
        //      const etlData = [{ key: "2646f7d4-2b67-4935-afcb-74fdbacd6ca3", text: "Arbeitslos / AL" }, ...etlSolutions?.map((item) => {
        return {
            key: item.key,
            text: item.title
        };
    })];

    const executionTime = ['1 Woche', '2 Wochen', '3 Wochen', '4 Wochen', '5 Wochen', '6 Wochen', '7 Wochen', '8 Wochen', '9 Wochen', '10 Wochen', '11 Wochen', '12 Wochen', '13 Wochen', '14 Wochen', '15 Wochen', '16 Wochen', '17 Wochen', '18 Wochen'];
    const executionData = [{ key: "", text: t("client:tabExit:executionOptions") }, ...executionTime.map((item) => {
        return {
            key: item,
            text: item
        };
    })];

    const strategyOption = [{ key: "", text: t("client:tabExit:strategyOptions") }, ...strategies?.map((item) => {
        return {
            key: item.key,
            text: item.title
        };
    })];

    return (
        <React.Fragment>
            <Field
                name="exit.date"
                disabled={isReadOnly}
                label={t("client:tabExit:exitDate")}
                dateFormat="dd.MM.yyyy"
                selected={new Date(client?.exit?.date)}
                component={DateField}
            />
            <Field
                name="exit.alvStatus"
                label={t("client:tabExit:alvStatus")}
                disabled={isReadOnly}
                options={alvStatusOptions}
                component={Select}
            />
            <Field
                name="exit.etl"
                label={t("client:tabExit:etl")}
                disabled={isReadOnly}
                options={etlData}
                component={Select}
            />
            <Field
                name="exit.executionTime"
                label={t("client:tabExit:executionTime")}
                disabled={isReadOnly}
                options={executionData}
                component={Select}
            />
            <Field
                name="exit.firstWorkingDay"
                disabled={isReadOnly}
                label={t("client:tabExit:firstWorkingDay")}
                dateFormat="dd.MM.yyyy"
                selected={new Date(client?.interview?.date)}
                component={DateField}
            />
            <Field
                name="exit.employer.name"
                label={t("client:tabExit:employerName")}
                disabled={isReadOnly}
                component={Input}
            />
            <Field
                name="exit.employer.contact"
                label={t("client:tabExit:employerContact")}
                disabled={isReadOnly}
                component={Input}
            />
            <Field
                name="exit.employer.address"
                label={t("client:tabExit:employerAddress")}
                disabled={isReadOnly}
                component={Textarea}
            />
            <Field
                name="exit.employer.function"
                label={t("client:tabExit:employerFunction")}
                disabled={isReadOnly}
                component={Input}
            />
            <Field
                name="exit.employer.remarks"
                label={t("client:tabExit:employerRemarks")}
                disabled={isReadOnly}
                component={Textarea}
            />

            <Field
                name="exit.strategy"
                label={t("client:tabExit:strategy")}
                disabled={isReadOnly}
                options={strategyOption}
                component={Select}
            />
            <Field
                name="exit.intermediaryInterview"
                label={t("client:tabExit:intermediaryInterview")}
                disabled={isReadOnly}
                component={Textarea}
            />
        </React.Fragment>
    );
};
