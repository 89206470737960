import React from 'react';
import { Field, useFormikContext } from "formik";
import { Select } from "./Select"
import { IBaseContactItem } from "../../core/cm.types";
import { getIn } from "formik";

export const ClientConsultantDropdownV2: React.FunctionComponent<{ name: string, optionlabel: string, itemlabel: string, items: IBaseContactItem[], isDisabled: boolean, }> = (props) => {
  const { isDisabled, items, name, optionlabel, itemlabel } = props;
  const { values } = useFormikContext();

  const options = [{ key: "", text: optionlabel }, ...items?.map((item) => {
    return {
      key: item.key,
      text: item.lastName + " " + item.name
    };
  })];

  const selectedValue = getIn(values, name);
  const selectedItem = selectedValue !== undefined && selectedValue !== "" ? items.filter(p => p.key === selectedValue) : [];
  const selectedPhone = selectedItem.length > 0 ? selectedItem[0].phone : undefined;
  const selectedMail = selectedItem.length > 0 ? selectedItem[0].email : undefined;

  return (
    <Field
      name={name}
      label={itemlabel}
      disabled={isDisabled}
      options={options}
      suffixControl={
        <React.Fragment>
          {selectedPhone !== undefined && (
            <div className="control">
              <a className="button" href={`tel:${selectedPhone}`}>
                <i className={`fas fa-phone`} /></a>
            </div>
          )}
          {selectedMail !== undefined && (
            <div className="control">
              <a className="button" href={`mailto:${selectedMail}`}>
                <i className={`fas fa-envelope`} /></a>
            </div>
          )}
        </React.Fragment>
      }
      component={Select}
    />
  );
}
