import React from "react";
import appService from "../../../services/app.service";
import { DetailsOverview } from "../base/DetailsOverview";
import { useTranslation } from "react-i18next";
import { NoAdminAlert } from "../../NoAdminAlert"

export const NationalityOverview: React.FunctionComponent<{ isAdmin: boolean }> = (props) => {
    const detailsRedirection = "/config/nationalities/details";
    const { t } = useTranslation();
    const title = t("client:headers:tabNationality");
    const { isAdmin } = props;

    if (isAdmin !== true) { return <NoAdminAlert /> }
    
    return (
                <DetailsOverview detailsRedirection={detailsRedirection} getItems={appService.getNationality} hasNumber={true} entityType="nationalities" title={title}></DetailsOverview>
    );
};