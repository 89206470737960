import React, { useState } from 'react'
import { ImageUpload } from './ImageUpload'
import { ModalBox } from '../modalbox/ModalBox';
import "./UploadModalBox.css"
import appService from "../../services/app.service";
import { Loading } from '../Loading';
import { useTranslation } from "react-i18next";

export const UploadModalBox: React.FC<{ label: string, field: string, width: number; height: number; setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void; }> = (props) => {
    const { t } = useTranslation();
    const { setFieldValue, width, height, field, label } = props;
    const [showModal, setShowModal] = useState<boolean>(false);
    const [selectedImage, setSelectedImage] = useState<any>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onCloseClick = async () => {
        setShowModal(!showModal)
        setIsLoading(false);
    }

    const onImageSelected = (e) => {
        setSelectedImage(e);
    }

    const onUploadClick = async () => {
        setIsLoading(true);
        let result = await appService.uploadImage(selectedImage);
        setFieldValue(`${field}`, result.location, true);
        onCloseClick();
    }

    return (
        <React.Fragment>
            <div className="columns is-centered">
                <div className="column is-half has-text-centered">
                    <button className="button is-small" type="button" onClick={(e) => { e.preventDefault(); setShowModal(true) }}>
                        {t("client:tabUpload:modalTitle")}
                    </button>
                </div>
            </div>
            <ModalBox title={t("client:tabUploadModalBox:uploadPicture")} isActive={showModal} onCloseClick={onCloseClick}
                onConfirmClick={onUploadClick} hideConfirm={selectedImage === undefined}>
                <Loading isLoading={isLoading} />
                {isLoading === false && (
                    <div className="uploadModalBox">
                        <p>
                            {t("client:tabUpload:modalMessage")}
                        </p>
                        <ImageUpload width={width} height={height} onImageSelected={onImageSelected} />
                    </div>
                )}
            </ModalBox>
        </React.Fragment>
    );
};