import {
  AuthenticationResult,
  AccountInfo,
  InteractionRequiredAuthError,
  AuthError,
  PublicClientApplication,
  Configuration,
} from "@azure/msal-browser";

import {
  Client,
  AuthenticationProvider
} from "@microsoft/microsoft-graph-client";
import { ImplicitAuthenticationProvider } from "./ImplicitAuthenticationProvider";

import { IAuth, IAuthLoginResponse, IConfig, IUserAccountInfo } from "./auth.types";
import { MSALAuthenticationProviderOptions } from "@microsoft/microsoft-graph-client/lib/src/MSALAuthenticationProviderOptions";
import { QueryStringService } from "../querystring.service";

export class MsalAuth implements IAuth {
  private _app: PublicClientApplication;
  private _config: IConfig;
  private _graphClient: Client;
  private _authProvider: AuthenticationProvider;
  private _account: AccountInfo | null;

  public constructor(config: IConfig) {
    this._config = config;
    this._account = null;

    const msalConfig: Configuration = {
      auth: this._config.auth,
      cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
      },
    };

    if (
      msalConfig !== undefined &&
      msalConfig.auth != undefined &&
      msalConfig.auth.redirectUri === undefined
    ) {
      msalConfig.auth.redirectUri = `${window.location.origin}`;
    }

    if (
      msalConfig !== undefined &&
      msalConfig.auth != undefined
    ) {
      // used to optimize the token retrieval operation
      msalConfig.auth.cloudDiscoveryMetadata = '{"tenant_discovery_endpoint":"https://login.microsoftonline.com/common/v2.0/.well-known/openid-configuration","api-version":"1.1","metadata":[{"preferred_network":"login.microsoftonline.com","preferred_cache":"login.windows.net","aliases":["login.microsoftonline.com","login.windows.net","login.microsoft.com","sts.windows.net"]},{"preferred_network":"login.partner.microsoftonline.cn","preferred_cache":"login.partner.microsoftonline.cn","aliases":["login.partner.microsoftonline.cn","login.chinacloudapi.cn"]},{"preferred_network":"login.microsoftonline.de","preferred_cache":"login.microsoftonline.de","aliases":["login.microsoftonline.de"]},{"preferred_network":"login.microsoftonline.us","preferred_cache":"login.microsoftonline.us","aliases":["login.microsoftonline.us","login.usgovcloudapi.net"]},{"preferred_network":"login-us.microsoftonline.com","preferred_cache":"login-us.microsoftonline.com","aliases":["login-us.microsoftonline.com"]}]}';
    }
    this._app = new PublicClientApplication(
      msalConfig as Configuration
    );

    const graphScopes = config.app.graphScope.split(",");
    this._authProvider = new ImplicitAuthenticationProvider(
      new MSALAuthenticationProviderOptions(graphScopes)
    );
    const authProvider = this._authProvider;
    this._graphClient = Client.initWithMiddleware({
      authProvider,
      fetchOptions: { headers: { ConsistencyLevel: 'eventual' } }
    });
  }

  async logout(): Promise<void> {
    this._app.logout();
  }

  getGraphClient(): Client {
    return this._graphClient;
  }

  getUserAccount = (): IUserAccountInfo | undefined => {
    if (this._account !== null && this._account !== undefined) {
      const accountInfo: IUserAccountInfo = {
        id: this._account.homeAccountId,
        username: this._account.username,
        displayName: this._account.name
      }

      if (accountInfo.id.indexOf(".")) {
        accountInfo.id = accountInfo.id.substring(0, accountInfo.id.indexOf("."));
      }

      return accountInfo;
    }
    else {
      return undefined;
    }
  };

  fetchWithToken = (
    url: string,
    token: string | undefined,
    options?: RequestInit
  ): Promise<Response> => {
    options = options || {};
    options.headers = options.headers || new Headers();

    let header = options.headers as Headers;
    if (header) {
      header.append("Accept", "application/json");
      header.append("Content-Type", "application/json");
      //header.append("pragma", "no-cache");
      //header.append("cache-control", "no-cache");
      if (token) header.append("Authorization", `Bearer ${token}`);
    }
    return fetch(url, options);
  };

  login(): Promise<IAuthLoginResponse> {
    const easyScope = this._config.app.appScope.split(",");

    return new Promise<IAuthLoginResponse>(async (resolve, reject) => {
      let feedback: IAuthLoginResponse = {
        account: null,
        errorCode: undefined,
        accessDenied: false,
      };
      
      try {
        const currentAccounts = this._app.getAllAccounts();
        if (currentAccounts === null || currentAccounts.length === 0) {
          const message = "No accounts in the cache. Loging in...";
          console.log(message)
          throw message
        }

        if (currentAccounts.length > 1) {
          // Add choose account code here
          const message = "Multiple accounts detected, need to add choose account code.";
          console.log(message);
          throw message;
        } 
        
        this._account = currentAccounts[0];
        feedback.account = this._account;

        const silentRequest = {
          scopes: easyScope,
          account: this._account as AccountInfo,
          forceRefresh: false,
        };
        await this._app.acquireTokenSilent(silentRequest);
        resolve(feedback);
      } 
      catch {
        this._app
        .handleRedirectPromise()
        .then((response: AuthenticationResult | null) => {
          feedback.account = response !== null ? response.account : null;
          this._account = feedback.account;

          if (response === null) {
            console.log("Authentication attempt");
            const easyScope = this._config.app.appScope.split(",");
            this._app
              .loginRedirect({
                scopes: easyScope,
                loginHint: QueryStringService.getInstance().getUrlParameterByName("userPrincipalName")
              })
              .then(() => {
                console.log("Loginredirect");
                // do not return promise since a redirect is executed
              })
              .catch((ex) => {
                console.log("Exception in loginredirect");
                console.log(ex);
                feedback.accessDenied = true;
                feedback.errorCode = ex?.errorCode;
                reject(feedback);
              });
          } else {
            console.log("Authentication response available");
            resolve(feedback);
          }
        })
        .catch((ex) => {
          feedback.accessDenied = true;
          feedback.errorCode = ex?.errorCode;
          reject(feedback);
        });
      }
    });
  }

  getToken = async (scopes: string[]): Promise<string | undefined> => {
    return new Promise<string | undefined>(async (resolve, reject) => {
      try {
        const silentRequest = {
          scopes: scopes,
          account: this._account as AccountInfo,
          forceRefresh: false,
        };

        const response = await this._app.acquireTokenSilent(silentRequest);
        if (response !== null && response !== undefined) {
          resolve(response.accessToken);
        }
        else {
          reject()
        }
      } catch (e) {
        if (
          e instanceof AuthError ||
          e instanceof InteractionRequiredAuthError
        ) {
          if (
            e.errorCode === "no_tokens_found" ||
            e.errorCode === "interaction_required" ||
            e.errorCode === "invalid_grant"
          ) {
            this._app
              .acquireTokenRedirect({
                scopes: scopes,
              })
              .then(() => {
                // it redirects here
                resolve(undefined);
              })
              .catch((ex) => {
                reject(undefined);
              });
          } else {
            console.log(e);
            reject(undefined);
          }
        }
      }
    });
  };

  getConfig(): IConfig {
    return this._config;
  }
}