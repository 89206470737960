import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { IClient, IMonthPresence } from '../../core/cm.types';
import appService from "../../services/app.service";
import { Select } from '../fields/Select';
import moment from 'moment';
import 'moment/locale/de';
import { Loading } from '../Loading';
import "./PresencePrint.css"
import { TableService } from '../../services/table.service';
import { PresenceAllClients } from '../views/PresenceAllClients';
import graphService from "../../services/graph.service";

export interface IDayDefinition {
    isFreeDay: boolean,
    title: number
}

export const PresencePrint: React.FunctionComponent<{ clients: IClient[], tableService: TableService, exportAllTable(rows), clientDriveId: string, allowanceFactor: number, mailMergeDriveId: string, templateDriveId: string }> = (props) => {
    const currentDate = new Date();

    const { t } = useTranslation();
    const { tableService, clients, exportAllTable, clientDriveId, mailMergeDriveId, templateDriveId, allowanceFactor } = props;
    const [currentMonth, setCurrentMonth] = React.useState<number>(currentDate.getMonth());
    const [currentYear, setCurrentYear] = React.useState<number>(currentDate.getFullYear());
    const [selectedRows, setSelectedRows] = useState([]);
    const [presence, setPresence] = useState<IMonthPresence>({});
    const [currentAmmClients, setCurrentAmmClients] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [days, setDays] = useState<IDayDefinition[]>([]);

    const fetchData = async () => {
        setIsLoading(true);
        setPresence({})
        setDays([])
        setSelectedRows([])
        setCurrentAmmClients([])

        try {
            var presence = await appService.getPresence(currentYear, currentMonth);
            let addedClients = [];
            presence.details.forEach(detail => {
                const customer = clients.find(a => a.key === detail.customerId);
                if (customer !== undefined) {
                    detail.customerName = customer.firstName + " " + customer.lastName;
                    addedClients.push(customer);
                }
            });

            setPresence(presence);
            setCurrentAmmClients(addedClients)
            setDays(days)
            setSelectedRows([])
        }
        catch {
            setDays([])
            setSelectedRows([])
            setCurrentAmmClients([])
        }
        setIsLoading(false);
    };

    function checkDate(val) {
        if (val === null || val === undefined)
            return "";

        const d = new Date(val);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        let year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return day + "." + month + "." + year;
    }

    function checkValue(val) {
        try {
            if (val === null || val === undefined)
                return "";

            return val;
        } catch (e) {
            return "error";
        }
    }

    const printData = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        //setTemplates(await graphService.getItemsByDriveId(templateDriveId));
        const selectedFolder = "/Administration/AMMB"
        const ammtemplatename = "ammtemplate.docx"

        const templates = await graphService.getItemsByDriveId(templateDriveId);
        const ammTemplate = templates.find(template => template.name.toLowerCase() === ammtemplatename);

        if (ammTemplate === undefined) {
            alert(`Konnte nicht die Datei "${ammtemplatename}" bei den Vorlagen finden`);
            setIsLoading(false);
            return;
        }
        const consultants = await appService.getConsultants();
        const alks = await appService.getAlks();
        const qualifications = await appService.getQualification();
        const nations = await appService.getNationality();
        const residentPermits = await appService.getResidencePermits();
        const alvStatus = await appService.getAlvStatus();
        const strategies = await appService.getStrategies();
        const employees = await appService.getEmployees();
        const employeeFunctions = await appService.getEmployeeFunctions();
        const officeLocations = await appService.getOfficeLocations();
        const consultantDepartments = await appService.getConsultantDepartments();
        const consultantLocations = await appService.getConsultantLocations();
        const additionalInfo = await appService.getAdditionalInfo();
        const etlSolutions = await appService.getEtlSolutions();
        const terminations = await appService.getTerminations();
        const interviewStatus = await appService.getInterviewStatus();
        const locationInfo = await appService.getLocationInfos();
        const expenses = await appService.getExpenses();

        const mailRootFolder = await graphService.getFolderIdByDrivePath(mailMergeDriveId, "/root")

        await Promise.all(selectedRows.map(async client => {
            try {
                const fileName = `${currentYear}_${moment.months()[currentMonth]}_${new Date().getDate()}_${client?.lastName?.toString()}_${client?.firstName?.toString()}_${client?.personId}.docx`
                let targetFolder = undefined;
                /*
                try {
                    const path = `/root:/${client?.sharePointFolder}${selectedFolder}`;
                    console.log(path)
                    targetFolder = await graphService.getFolderIdByDrivePath(clientDriveId, path);
                }
                catch {
                    targetFolder = await graphService.getFolderIdByDrivePath(clientDriveId, `/root:/${client?.sharePointFolder}`);
                }
                */

                let path = `/root:/${client?.sharePointFolder}${selectedFolder}`;
                try {
                    targetFolder = await graphService.getFolderIdByDrivePath(clientDriveId, path);
                }
                catch {
                    path = `/root:/${client?.sharePointFolder}`;
                    targetFolder = await graphService.getFolderIdByDrivePath(clientDriveId, path);
                }
                path = `/drives/${clientDriveId}/items${path}`;

                console.log(path);
                //let itemId = await graphService.copyTemplateToFolder(templateDriveId, ammTemplate.id, targetFolder.parentReference.driveId, targetFolder.id, fileName);
                let itemId = await graphService.copyTemplateToFolderByTargetPath(templateDriveId, ammTemplate.id, path, fileName)
                console.log(itemId);
                path += `/${fileName}`;
                console.log(path);

                const myAlk = alks.find(a => a.key === client?.alkId);

                const quali = qualifications.find(q => q.key === client?.qualification);
                const nation = nations.find(n => n.key === client?.nationality);
                const residentsPermit = residentPermits.find(r => r.key === client?.residencePermit);
                const alvStat = alvStatus.find(r => r.key === client?.exit?.alvStatus);
                const strategy = strategies.find(r => r.key === client?.exit?.strategy);
                const additionalInf = additionalInfo.find(r => r.key === client?.entry?.additionalInfoId)
                const etlSolution = etlSolutions.find(r => r.key === client?.exit?.etl)
                const termination = terminations.find(r => r.key === client?.amm?.terminationId)

                const consultant = consultants.find(e => e.key === client?.consultantId);
                const consultantLocation = consultantLocations.find(e => e.key === consultant?.locationId);
                const consultantDepartment = consultantDepartments.find(e => e.key === consultant?.departmentId)

                const employeeVG = employees.find(e => e.key === client?.interview?.employeeId);
                const employeeVGFunction = employeeFunctions.find(e => e.key === employeeVG?.functionId);
                const employeeVGLocation = officeLocations.find(e => e.key === employeeVG?.officeLocationId);
                const employeeFA = employees.find(e => e.key === client?.entry?.employeeId)
                const employeeFAFunction = employeeFunctions.find(e => e.key === employeeFA?.functionId);
                const employeeFALocation = officeLocations.find(e => e.key === employeeFA?.officeLocationId);
                const interviewSt = interviewStatus.find(e => e.key === client?.interview?.statusId)
                const location = locationInfo.find(e => e.key === client?.entry?.locationId)

                const userPresence = presence.details.find(p => p.customerId === client.key);
                const calculateDailyAllowance = function () {
                    let callMoneyDays = 0;
                    for (var i = 1; i <= 31; i++) {
                        const xVM = `v${i}`;
                        const xNM = `n${i}`;
                        const actualVM = userPresence[xVM] !== undefined ? userPresence[xVM].toLowerCase() : "";
                        const actualNM = userPresence[xNM] !== undefined ? userPresence[xNM].toLowerCase() : "";

                        const currentMoney = expenses.find((expense) => (expense.vm !== undefined ? expense.vm.toLocaleLowerCase() : "") === actualVM && (expense.nm !== undefined ? expense.nm.toLocaleLowerCase() : "") === actualNM);
                        if (currentMoney !== undefined) {
                            callMoneyDays += currentMoney.dailyAllowance;
                        }
                    }
                    return callMoneyDays;
                };
                const calculateRationDays = function () {
                    let rationMoneyDays = 0;
                    for (var i = 1; i <= 31; i++) {
                        const xVM = `v${i}`;
                        const xNM = `n${i}`;
                        const actualVM = userPresence[xVM] !== undefined ? userPresence[xVM].toLowerCase() : "";
                        const actualNM = userPresence[xNM] !== undefined ? userPresence[xNM].toLowerCase() : "";

                        const currentMoney = expenses.find((expense) => (expense.vm !== undefined ? expense.vm.toLocaleLowerCase() : "") === actualVM && (expense.nm !== undefined ? expense.nm.toLocaleLowerCase() : "") === actualNM);
                        if (currentMoney !== undefined) {
                            rationMoneyDays = currentMoney.isMealExpense ? rationMoneyDays + 1 : rationMoneyDays;
                        }
                    }
                    return rationMoneyDays;
                };
                const callMoneyDays = calculateDailyAllowance();
                const rationMoneyDays = calculateRationDays();

                let metadata = {
                    "tn_anrede": checkValue(client?.title),
                    "tn_erlernter_beruf": checkValue(client?.jobLearned),
                    "tn_mail": checkValue(client?.email),
                    "tn_mobil": checkValue(client?.mobile),
                    "tn_nachname": checkValue(client?.lastName),
                    "tn_nationalitaet": checkValue(nation?.title),
                    "tn_pensum_in_prozent": checkValue(client?.pensum),
                    "tn_personennummer": checkValue(client?.personId),
                    "tn_plz": checkValue(client?.zip),
                    "tn_sozialversicherungsnummer": checkValue(client?.socialSecurityNumber),
                    "tn_startzeit": checkValue(client?.entry?.starttime),
                    "tn_tag": checkValue(client?.entry?.day),
                    "tn_strasse_nr": checkValue(client?.street),
                    "tn_festnetz": checkValue(client?.phone),
                    "tn_vorname": checkValue(client?.firstName),
                    "tn_wohnort": checkValue(client?.city),
                    "tn_zuletzt_ausgeuebter_beruf": checkValue(client?.lastJob),

                    "tn_aufenthaltsbewilligung": checkValue(residentsPermit?.title),
                    "tn_qualifizierung": checkValue(quali?.title),

                    "tn_alk_name": checkValue(myAlk?.title),
                    "tn_alk_nummer": checkValue(myAlk?.alkNumber),
                    "tn_alk_plz": checkValue(myAlk?.zip),
                    "tn_alk_ort": checkValue(myAlk?.city),
                    "tn_alk_haupt_tel": checkValue(myAlk?.phone),
                    "tn_alk_info_mail": checkValue(myAlk?.email),

                    "tn_anzahl_arbeitsbemuehungen": checkValue(client?.entry?.trials),
                    "tn_abbruch_durch_wen": checkValue(termination?.title),
                    "tn_alv_status_bei_austritt": checkValue(alvStat?.title),
                    "tn_einsatzdauer": checkValue(client?.exit?.executionTime),
                    "tn_erfolgsstrategie": checkValue(strategy?.title),
                    "tn_funktion_in_neuer_arbeitsstelle": checkValue(client?.exit?.employer?.function),
                    "tn_kontaktperson_firma": checkValue(client?.exit?.employer?.contact),
                    "tn_loesungsdaten_etl": checkValue(etlSolution?.title),
                    "tn_name_neuer_arbeitgeber": checkValue(client?.exit?.employer?.name),
                    "tn_status_nach_vg": checkValue(interviewSt?.title),
                    "tn_wie_weiter_nach_vg": checkValue(client?.interview?.nextSteps),
                    "tn_zusatzinfos": checkValue(additionalInf?.title),
                    "tn_standortinfos": checkValue(location?.title),
                    "tn_status": client?.amm?.status === true ? "Ja" : "Nein",
                    "ma_fallfuehrung": checkValue(employeeFA === null || employeeFA === undefined ? "" : employeeFA.name + " " + employeeFA.lastName),
                    "ma_fallfuehrung_name": checkValue(employeeFA?.lastName),
                    "ma_fallfuehrung_vorname": checkValue(employeeFA?.name),
                    "ma_fallfuehrung_mail_intern": checkValue(employeeFA?.email),
                    "ma_fallfuehrung_tel_intern": checkValue(employeeFA?.phone),
                    "ma_fallfuehrung_funktion": checkValue(employeeFAFunction?.title),
                    "ma_fallfuehrung_org_name": checkValue(employeeFALocation?.title),
                    "ma_fallfuehrung_org_duf_nr": checkValue(employeeFALocation?.dufNumber),
                    "ma_fallfuehrung_org_strasse": checkValue(employeeFALocation?.street),
                    "ma_fallfuehrung_org_plz": checkValue(employeeFALocation?.zip),
                    "ma_fallfuehrung_org_ort": checkValue(employeeFALocation?.city),
                    "ma_fallfuehrung_org_haupt_tel": checkValue(employeeFALocation?.phone),
                    "ma_fallfuehrung_org_info_mail": checkValue(employeeFALocation?.email),
                    "ma_vorgespraech": checkValue(employeeVG === null || employeeVG === undefined ? "" : employeeVG.name + " " + employeeVG.lastName),
                    "ma_vorgespraech_name": checkValue(employeeVG?.lastName),
                    "ma_vorgespraech_vorname": checkValue(employeeVG?.name),
                    "ma_vorgespraech_mail_intern": checkValue(employeeVG?.email),
                    "ma_vorgespraech_tel_intern": checkValue(employeeVG?.phone),
                    "ma_vorgespraech_funktion": checkValue(employeeVGFunction?.title),
                    "ma_vorgespraech_org_name": checkValue(employeeVGLocation?.title),
                    "ma_vorgespraech_org_duf_nr": checkValue(employeeVGLocation?.dufNumber),
                    "ma_vorgespraech_org_strasse": checkValue(employeeVGLocation?.street),
                    "ma_vorgespraech_org_plz": checkValue(employeeVGLocation?.zip),
                    "ma_vorgespraech_org_ort": checkValue(employeeVGLocation?.city),
                    "ma_vorgespraech_org_haupt_tel": checkValue(employeeVGLocation?.phone),
                    "ma_vorgespraech_org_info_mail": checkValue(employeeVGLocation?.email),
                    "tn_vorgespraechsdatum": checkDate(client?.interview?.date),
                    "tn_vorgespraechsdatum_startzeit": checkValue(client?.interview?.starttime),
                    "tn_start_rahmenfrist": checkDate(client?.startPeriod),
                    "tn_ende_rahmenfrist": checkDate(client?.endPeriod),
                    "tn_austritt": checkDate(client?.exit?.date),
                    "tn_eintritt": checkDate(client?.entry?.date),
                    "tn_geburtsdatum": checkDate(client?.birthDay),
                    "tn_1_arbeitstag": checkDate(client?.exit?.firstWorkingDay),

                    "zu_beratende": checkValue(consultant === null || consultant === undefined ? "" : consultant.name + " " + consultant.lastName),
                    "zu_vorname": checkValue(consultant?.name),
                    "zu_nachname": checkValue(consultant?.lastName),
                    "zu_tel": checkValue(consultant?.phone),
                    "zu_mail": checkValue(consultant?.email),
                    "zu_standortbezeichnung": checkValue(consultantLocation?.title),
                    "zu_plz": checkValue(consultantLocation?.zip),
                    "zu_ort": checkValue(consultantLocation?.city),
                    "zu_haupt_tel_nr": checkValue(consultantLocation?.phone),
                    "zu_standort_infomail": checkValue(consultantLocation?.email),
                    "zu_funktion": checkValue(consultant?.lastName),
                    "zu_abteilung": checkValue(consultantDepartment?.title),

                    "tn_ziel_1": checkValue(client?.entry?.objective1),
                    "tn_ziel_2": checkValue(client?.entry?.objective2),
                    "tn_ziel_3": checkValue(client?.entry?.objective3),
                    "tn_rueckmeldung_zum_vg_an_pb": checkValue(client?.interview?.feedback),
                    "tn_bemerkung_austrittsmeldung": checkValue(client?.exit?.employer?.remarks),
                    "tn_bemerkung_ammbescheinigung": checkValue(client?.amm?.remarks),
                    "tn_zusatzbemerkung_erfolgsstrategie": checkValue(client?.exit?.intermediaryInterview),
                    "tn_individuelle_zielsetzung": checkValue(client?.entry?.objectiveIndividual),
                    "tn_adresse_firma": checkValue(client?.exit?.employer?.address),

                    "amm_cancelbyorganisation": termination?.title === 'Organisator' ? 'X' : '',
                    "amm_cancelbymutual": termination?.title === 'Gegenseitig' ? 'X' : '',
                    "amm_cancelbyinsurant": termination?.title === 'Versicherten' ? 'X' : '',

                    "amm_daily": client.pensum === 100 ? 'X' : '',
                    "amm_daymoney": client.pensum !== 100 ? 'X' : '',

                    "amm_calldays": callMoneyDays,
                    "amm_rationdays": rationMoneyDays,
                    "amm_rationcost": allowanceFactor,
                    "amm_rationtotal": rationMoneyDays * allowanceFactor,
                    "amm_hasrationmoney": rationMoneyDays > 0 ? 'X' : '',
                    "amm_monthtext": moment.months()[currentMonth],
                    "amm_yeartext": currentYear.toString(),
                    "amm_01vm": checkValue(userPresence?.v1),
                    "amm_02vm": checkValue(userPresence?.v2),
                    "amm_03vm": checkValue(userPresence?.v3),
                    "amm_04vm": checkValue(userPresence?.v4),
                    "amm_05vm": checkValue(userPresence?.v5),
                    "amm_06vm": checkValue(userPresence?.v6),
                    "amm_07vm": checkValue(userPresence?.v7),
                    "amm_08vm": checkValue(userPresence?.v8),
                    "amm_09vm": checkValue(userPresence?.v9),
                    "amm_10vm": checkValue(userPresence?.v10),
                    "amm_11vm": checkValue(userPresence?.v11),
                    "amm_12vm": checkValue(userPresence?.v12),
                    "amm_13vm": checkValue(userPresence?.v13),
                    "amm_14vm": checkValue(userPresence?.v14),
                    "amm_15vm": checkValue(userPresence?.v15),
                    "amm_16vm": checkValue(userPresence?.v16),
                    "amm_17vm": checkValue(userPresence?.v17),
                    "amm_18vm": checkValue(userPresence?.v18),
                    "amm_19vm": checkValue(userPresence?.v19),
                    "amm_20vm": checkValue(userPresence?.v20),
                    "amm_21vm": checkValue(userPresence?.v21),
                    "amm_22vm": checkValue(userPresence?.v22),
                    "amm_23vm": checkValue(userPresence?.v23),
                    "amm_24vm": checkValue(userPresence?.v24),
                    "amm_25vm": checkValue(userPresence?.v25),
                    "amm_26vm": checkValue(userPresence?.v26),
                    "amm_27vm": checkValue(userPresence?.v27),
                    "amm_28vm": checkValue(userPresence?.v28),
                    "amm_29vm": checkValue(userPresence?.v29),
                    "amm_30vm": checkValue(userPresence?.v30),
                    "amm_31vm": checkValue(userPresence?.v31),
                    "amm_01nm": checkValue(userPresence?.n1),
                    "amm_02nm": checkValue(userPresence?.n2),
                    "amm_03nm": checkValue(userPresence?.n3),
                    "amm_04nm": checkValue(userPresence?.n4),
                    "amm_05nm": checkValue(userPresence?.n5),
                    "amm_06nm": checkValue(userPresence?.n6),
                    "amm_07nm": checkValue(userPresence?.n7),
                    "amm_08nm": checkValue(userPresence?.n8),
                    "amm_09nm": checkValue(userPresence?.n9),
                    "amm_10nm": checkValue(userPresence?.n10),
                    "amm_11nm": checkValue(userPresence?.n11),
                    "amm_12nm": checkValue(userPresence?.n12),
                    "amm_13nm": checkValue(userPresence?.n13),
                    "amm_14nm": checkValue(userPresence?.n14),
                    "amm_15nm": checkValue(userPresence?.n15),
                    "amm_16nm": checkValue(userPresence?.n16),
                    "amm_17nm": checkValue(userPresence?.n17),
                    "amm_18nm": checkValue(userPresence?.n18),
                    "amm_19nm": checkValue(userPresence?.n19),
                    "amm_20nm": checkValue(userPresence?.n20),
                    "amm_21nm": checkValue(userPresence?.n21),
                    "amm_22nm": checkValue(userPresence?.n22),
                    "amm_23nm": checkValue(userPresence?.n23),
                    "amm_24nm": checkValue(userPresence?.n24),
                    "amm_25nm": checkValue(userPresence?.n25),
                    "amm_26nm": checkValue(userPresence?.n26),
                    "amm_27nm": checkValue(userPresence?.n27),
                    "amm_28nm": checkValue(userPresence?.n28),
                    "amm_29nm": checkValue(userPresence?.n29),
                    "amm_30nm": checkValue(userPresence?.n30),
                    "amm_31nm": checkValue(userPresence?.n31)
                }

                await graphService.updateMetadata(targetFolder.parentReference.driveId, itemId, metadata);
                let targetCopyFilePath = `/drives/${mailMergeDriveId}/:root:/`;
                await graphService.copyTemplateToFolderByTargetPath(targetFolder.parentReference.driveId, itemId, targetCopyFilePath, fileName);
            }
            catch (ex) {
                console.log(ex);
                alert(t("client:tabDocuments:failedPrefix") + ` ${client?.firstName} ${client?.lastName} ` + t("client:tabDocuments:failedSuffix"))
            }
        }))
        alert(t("client:tabDocuments:printDone"))
        setIsLoading(false);
    }

    React.useEffect(() => {
        fetchData()
    }, [currentYear, currentMonth, clients]);

    let years = [];
    for (let currentYear = 2018; currentYear <= currentDate.getFullYear() + 1; currentYear++)
        years.push(currentYear);
    const yearsOption = [...years.map((item) => {
        return {
            key: item,
            text: item
        };
    })];

    moment.locale('de');
    const monthNames = moment.months()
    let monthOptions = [...monthNames.map((month, index) => {
        return {
            key: index,
            text: month
        };
    })]

    if (isLoading === true || clients.length === 0)
        return <Loading isLoading={true}></Loading>

    return (
        <div className="section defaultSection">
            <div className="columns">
                <div className="column">
                    <h1 className="title is-3">{t("client:tabPresencePrint:getAMM")}</h1>
                </div>
            </div>
            <div className="field is-horizontal">
                <Select options={yearsOption} value={currentYear} onChange={(e) => setCurrentYear(e.target.value)}></Select>
                <Select options={monthOptions} value={currentMonth} onChange={(e) => setCurrentMonth(e.target.value)}></Select>
            </div>
            <button
                type="button"
                disabled={selectedRows.length === 0}
                className={`button is-link is-small is-fullwidth`}
                onClick={printData}
            >
                {t("client:tabPresencePrint:print")}
            </button >
            <PresenceAllClients clients={currentAmmClients} tableService={tableService} selectedRows={selectedRows} setSelectedRows={setSelectedRows} exportAllTable={exportAllTable} />
            <button
                type="button"
                disabled={selectedRows.length === 0}
                className={`button is-link is-small is-fullwidth`}
                onClick={printData}
            >
                {t("client:tabPresencePrint:print")}
            </button>
        </div >
    );
};
