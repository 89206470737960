import React from "react";
import { IClient } from "../core/cm.types"
import { useTranslation } from "react-i18next";
import { LocationAwareButton } from "./LocationAwareButton";
import { TableService } from "../services/table.service"
import { PresenceAmmClients } from "./views/PresenceAmmClients";

export const AmmSearchContainer: React.FunctionComponent<{ clients: IClient[], tableService: TableService, onClientDelete: any, exportAllTable(rows) }> = (props) => {
    const { t } = useTranslation();
    const { clients, tableService, onClientDelete, exportAllTable } = props;

    return (
        <div className="section defaultSection">
            <h1 className="title is-3">{t("client:headers:tabAMMSearch")}</h1>
            <div className="buttons is-right">
                <LocationAwareButton title={t("client:newClient")} className="button is-small" relativeLocation="/details" />
            </div>
            <PresenceAmmClients onClientDelete={onClientDelete} clients={clients} tableService={tableService} exportAllTable={exportAllTable} />
        </div>
    )
};
