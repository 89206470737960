import React from "react";
import { DetailsBase } from "../base/DetailsBase";
import appService from "../../../services/app.service";
import { useTranslation } from "react-i18next";
import { NoAdminAlert } from "../../NoAdminAlert"

export const AlvstatusDetails: React.FunctionComponent<{
    id: string | undefined;
    isAdmin: boolean
}> = (props) => {
    const { id, isAdmin } = props;
    const { t } = useTranslation();
    const backButton = "/config/alvstatus";
    const title = t("client:headers:tabALVStatus");

    if (isAdmin !== true) { return <NoAdminAlert /> }

    return (
            <DetailsBase id={id} backButton={backButton} getById={appService.getAlvStatusById} saveItem={appService.saveAlvStatus} hasNumber={true} inputTitle={title}></DetailsBase>
        );
};
