import React from "react";
import { Field, Formik, FormikHelpers } from "formik";
import { Input } from "../../fields/Input";
import appService from "../../../services/app.service";
import { Loading } from "../../Loading";
import * as Yup from "yup";

import { useHistory } from "react-router-dom";
import { IEmployeeFunction } from "../../../core/cm.types";
import { LocationAwareButton } from "../../LocationAwareButton";
import { useTranslation } from "react-i18next";
import { NoAdminAlert } from "../../NoAdminAlert"

const validationSchema = Yup.object().shape({
    title: Yup.string().required("*"),
});

export const EmployeeFunctionDetails: React.FunctionComponent<{
    id: string | undefined;
    isAdmin: boolean;
}> = (props) => {
    const { t } = useTranslation();
    const { id, isAdmin } = props;
    const backButton = "/config/employeeFunctions"
    const isNew = id === undefined || id === null;
    const title = <h1 className="title is-3">{t("client:headers:tabEmployeeFunction")}</h1>
    const history = useHistory();
    const [data, setData] = React.useState<{
        isLoading: boolean;
        data: IEmployeeFunction;
    }>({
        isLoading: true,
        data: {},
    });

    React.useEffect(() => {
        const fetchData = async () => {
            const data = await appService.getEmployeeFunction(id);
            setData({
                isLoading: false,
                data: data,
            });
        };

        if (isNew) {
            setData({
                isLoading: false,
                data: {},
            });
        } else {
            fetchData();
        }
    }, []);

    const onSubmit = (
        values: Readonly<IEmployeeFunction | undefined>,
        formikHelpers: FormikHelpers<Readonly<IEmployeeFunction>>
    ) => {
        const data = values;

        const submit = async () => {
            if (data !== undefined && data !== null) {
                appService.saveEmployeeFunction(data).then((modulesResults) => {
                    var location = appService.getLocation();
                    history.push(`/${location}${backButton}`)
                }).catch(() => {
                    alert(t("client:common:errorOnSave"));
                });
            }
        };

        submit();
    };

    if (isAdmin !== true) { return <NoAdminAlert /> }

    return (
        <React.Fragment>
            <div className="section defaultSection">
                <Loading isLoading={data.isLoading}></Loading>
                {data.isLoading === false && (
                    <Formik
                        initialValues={data.data}
                        onSubmit={onSubmit}
                        validateOnMount={true}
                        validationSchema={validationSchema}
                        enableReinitialize
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            isValid,
                            dirty,
                            setFieldValue,
                        }) => (
                            <React.Fragment>
                                <form onSubmit={handleSubmit} className="form">
                                    <div className="columns">
                                        <div className="column">{title}</div>
                                        <div className="column button-header">
                                            <button
                                                type="submit"
                                                className={`button is-link is-small is-pulled-right backAndSaveButton ${isSubmitting ? t("client:common:isLoading") : ""
                                                    }`}
                                                disabled={isSubmitting || !dirty || !isValid}
                                            >
                                                {t("client:common:saveButton")}
                                            </button>
                                            <LocationAwareButton title={t("client:common:backButton")} className="button is-small is-pulled-right backAndSaveButton" relativeLocation={backButton}></LocationAwareButton>
                                        </div>
                                    </div>
                                    <div className="columns">
                                        <div className="column">
                                            <Field
                                                name="title"
                                                label={t("client:common:columnTitle")}
                                                component={Input}
                                            />
                                        </div>
                                    </div>

                                    <div className="button-footer">
                                        <button
                                            type="submit"
                                            className={`button is-link is-small is-pulled-right backAndSaveButton${isSubmitting ? t("client:common:isLoading") : ""
                                                }`}
                                            disabled={isSubmitting || !dirty || !isValid}
                                        >
                                            {t("client:common:saveButton")}
                                        </button>
                                        <LocationAwareButton title={t("client:common:backButton")} className="button is-small is-pulled-right backAndSaveButton" relativeLocation={backButton}></LocationAwareButton>
                                    </div>
                                </form>
                            </React.Fragment>
                        )}
                    </Formik>
                )}
            </div>
        </React.Fragment>
    );
};
