import { Field, useFormikContext } from 'formik';
import React from 'react';

const DayInput = ({ field, form, ...props }) => {
    const { name } = field;
    const { setFieldValue } = useFormikContext()

    return <input className="dayInput" {...field} {...props} onChange={(e) => {
        if (e.target.value?.length <= 1) {
            e.target.value = e.target.value?.toUpperCase()
            setFieldValue(name, e.target.value)
        }
        console.log(e.target.value)
    }} />;
};

export const FieldPair: React.FunctionComponent<{ customerId: string, index: number, isFreeDay: boolean, fieldId: number }> = (props) => {
    const { index, isFreeDay, fieldId, customerId } = props;

    return (
        <td key={customerId + fieldId} className={isFreeDay === true ? "day isFreeDay" : "day"}>
            <Field
                name={`details[${index}].v${fieldId}`}
                component={DayInput}
            />
            <Field
                name={`details[${index}].n${fieldId}`}
                component={DayInput}
            />
        </td>
    );
};
