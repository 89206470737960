import React from "react";
import { IEmployee } from "../../../core/cm.types"
import appService from "../../../services/app.service";
import { useTranslation } from "react-i18next";
import { NoAdminAlert } from "../../NoAdminAlert"
import { DetailsOverview } from "../base/DetailsOverview";
import moment from "moment";

export const EmployeeOverview: React.FunctionComponent<{ isAdmin: boolean }> = (props) => {
    const { isAdmin } = props;
    const { t } = useTranslation();
    const title = t("client:headers:tabEmployee");
    const detailsRedirection = "/config/employee/details";

    if (isAdmin !== true) { return <NoAdminAlert /> }
    const columns = [
        {
            Header: t("client:config:columnName"),
            accessor: 'name'
        },
        {
            Header: t("client:config:columnLastName"),
            accessor: 'lastName'
        },
        {
            Header: t("client:config:columnEmail"),
            accessor: 'email'
        }
    ];

    const sortBy = [
        { id: "name", desc: false }
    ];

    const exportAllTable = async (rows) => {

        const functions = await appService.getEmployeeFunctions();
        const officeLocations = await appService.getOfficeLocations();
        return rows.map(row => {
            const item = row.original as IEmployee

            let message = {};

            message[`${t("client:config:columnFirstName")}`] = item.name
            message[`${t("client:config:columnLastName")}`] = item.lastName
            message[`${t("client:config:columnEmail")}`] = item.email
            message[`${t("Benutzername")}`] = item.username
            message[`${t("client:config:columnMailPrivate")}`] = item.emailPrivate
            message[`${t("client:tabMasterData:mobile")}`] = item.mobile
            message[`${t("client:tabMasterData:phone")}`] = item.phone
            message[`${t("client:config:columnPhonePrivate")}`] = item.phonePrivate
            message[`${t("config:config:columnStreet")}`] = item.street
            message[`${t("client:config:columnOrt")}`] = item.city
            message[`${t("config:client:columnPLZ")}`] = item.zip
            message[`${t("client:config:columnDescription")}`] = item.description
            message[`${t("client:config:columnFunction")}`] = functions.find(p => p.key === item.functionId)?.title
            message[`${t("client:config:columnLocation")}`] = officeLocations.find(p => p.key === item.officeLocationId)?.title
            message[`${t("client:config:columnEntry")}`] = moment(item.entry).format("DD.MM.YYYY")
            message[`${t("client:config:columnExit")}`] = moment(item.exit).format("DD.MM.YYYY")
            message[`${t("client:config:columnBirthday")}`] = moment(item.birtday).format("DD.MM.YYYY")
            message[`${t("client:config:columnAdmin")}`] = item.isadmin === true ? "Ja" : "Nein"

            return message;
        })
    };

    return (
        <DetailsOverview columns={columns} sorting={sortBy} detailsRedirection={detailsRedirection} getItems={appService.getEmployees} entityType="employee" title={title} exportAllTable={exportAllTable}></DetailsOverview>
    );
};
