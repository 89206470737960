import React from "react";
import { IActivity, IClient } from "../core/cm.types"
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { LocationAwareButton } from "./LocationAwareButton";
import { TableService } from "../services/table.service"
import { Activities } from "./views/Activities"
import appService from "../services/app.service";

export const ActivitiesContainer: React.FunctionComponent<{ clients: IClient[], tableService: TableService, onClientDelete: any, exportAllTable(rows) }> = (props) => {
    const { t } = useTranslation();
    const { clients, tableService } = props;
    const [activities, setActivities] = React.useState<IActivity[]>([]);

    const fetchLocationDependentData = async () => {
        setActivities(await appService.getLocationWideActivities());
    };

    React.useEffect(() => {
        fetchLocationDependentData()
    }, []);

    const suffix = useMemo(() => [
        {
            id: 'button',
            Cell: ({ row }) => (
                <LocationAwareButton className="button is-small" relativeLocation={`/details/${row.original.key}`}>
                    <span className="icon is-small">
                        <i className={`fas fa-edit`} />
                    </span>
                </LocationAwareButton>
            ),
        }], []);

    const postfix = [];

    return (
        <div className="section defaultSection">
            <h1 className="title is-3">{t("client:headers:tabActivitiesSearch")}</h1>
            <div className="buttons is-right">
                <LocationAwareButton title={t("client:newClient")} className="button is-small" relativeLocation="/details" />
            </div>
            <Activities postfix={postfix} suffix={suffix} clients={clients} activities={activities} tableService={tableService} exportAllTable={undefined} />
        </div>
    )
};
