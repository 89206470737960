import React from 'react';
import { useState } from 'react';
import { useTranslation } from "react-i18next";
import { IAlk, IClient, IConsultant, IFolderStructure, IBaseDetailsItem, IEmployee, ILocationInfo, IOfficeLocation, IEmployeeFunction, IConsultantDepartment, IConsultantLocation, IConsultantFunction } from '../../core/cm.types';
import appService from '../../services/app.service'
import graphService from "../../services/graph.service";
import { GraphItem } from "../../services/graph.types";
import { Select } from '../fields/Select';
import { Loading } from '../Loading';

export const Documents: React.FunctionComponent<{ title: string, selectedRows: IClient[], clientDriveId: string, mailMergeDriveId: string, templateDriveId: string, setSelectedRows: React.Dispatch<React.SetStateAction<any[]>> }> = (props) => {
    const { t } = useTranslation();
    const [folderStructure, setFolderStructure] = React.useState<IFolderStructure>();
    const [consultants, setConsultants] = React.useState<IConsultant[]>([]);
    const [selectedTemplate, setSelectedTemplate] = useState<string>("");
    const [selectedCategory, setSelectedCategory] = useState<string>("");
    const [selectedFolder, setSelectedFolder] = useState<string>("/");
    const [categories, setCategories] = useState<GraphItem[]>([]);
    const [templates, setTemplates] = useState<GraphItem[]>([]);
    const [locationInfo, setLocationInfo] = React.useState<ILocationInfo[]>([]);
    const [isPrinting, setIsPrinting] = useState(false);
    const [alks, setAlks] = useState<IAlk[]>([]);
    const [additionalInfo, setAdditionalInfo] = useState<IBaseDetailsItem[]>([]);
    const [qualifications, setQualifications] = useState<IBaseDetailsItem[]>([]);
    const [terminations, setTerminations] = useState<IBaseDetailsItem[]>([]);
    const [employeeFunctions, setEmployeeFunctions] = useState<IEmployeeFunction[]>([]);
    const [nations, setNations] = useState<IBaseDetailsItem[]>([]);
    const [residentPermits, setResidentPermits] = useState<IBaseDetailsItem[]>([]);
    const [etlSolutions, setEtlSolutions] = useState<IBaseDetailsItem[]>([]);
    const [alvStatus, setAlvStatus] = useState<IBaseDetailsItem[]>([]);
    const [strategies, setStrategies] = useState<IBaseDetailsItem[]>([]);
    const [interviewStatus, setInterviewStatus] = useState<IBaseDetailsItem[]>([]);
    const [consultantDepartments, setConsultantDepartments] = useState<IConsultantDepartment[]>([]);
    const [consultantLocations, setConsultantLocations] = useState<IConsultantLocation[]>([]);
    const [consultantFunctions, setConsultantFunctions] = useState<IConsultantFunction[]>([]);
    const [employees, setEmployees] = useState<IEmployee[]>([]);
    const [officeLocations, setOfficeLocations] = useState<IOfficeLocation[]>([]);
    const { clientDriveId, mailMergeDriveId, templateDriveId, title, selectedRows, setSelectedRows } = props;

    const retrieveTemplates = async (templateFolderId: string) => {
        setSelectedTemplate("");
        setTemplates(await graphService.getItemsById(templateDriveId, templateFolderId));
    }

    const fetchData = async () => {
        try {
            const values = await graphService.getItemsByDriveId(templateDriveId);
            setCategories(values.filter((item) => item.isFolder === true));
            const retrieveConsultants = async () => {
                setConsultants(await appService.getConsultants());
            };
            const retrieveFolderStructure = async () => {
                setFolderStructure(await appService.getSharePointFolderStructure());
            };
            const retrieveAlks = async () => {
                setAlks(await appService.getAlks());
            };
            const retrieveQualifications = async () => {
                setQualifications(await appService.getQualification());
            };
            const retrieveNations = async () => {
                setNations(await appService.getNationality());
            };
            const retrieveResidentPermits = async () => {
                setResidentPermits(await appService.getResidencePermits());
            };
            const retrieveAlvStatus = async () => {
                setAlvStatus(await appService.getAlvStatus());
            };
            const retrieveStrategies = async () => {
                setStrategies(await appService.getStrategies());
            };
            const retrieveEmployees = async () => {
                setEmployees(await appService.getEmployees());
            };
            const retrieveEmployeeFunctions = async () => {
                setEmployeeFunctions(await appService.getEmployeeFunctions());
            };
            const retrieveOfficeLocations = async () => {
                setOfficeLocations(await appService.getOfficeLocations());
            };
            const retrieveConsultantDepartments = async () => {
                setConsultantDepartments(await appService.getConsultantDepartments());
            };
            const retrieveConsultantLocations = async () => {
                setConsultantLocations(await appService.getConsultantLocations());
            };
            const retrieveConsultantFunctions = async () => {
                setConsultantFunctions(await appService.getConsultantFunctions());
            };
            const retrieveAdditionalInfo = async () => {
                setAdditionalInfo(await appService.getAdditionalInfo());
            };
            const retrieveEtlSolutions = async () => {
                setEtlSolutions(await appService.getEtlSolutions());
            };
            const retrieveTerminations = async () => {
                setTerminations(await appService.getTerminations());
            };
            const retrievInterviewStatus = async () => {
                setInterviewStatus(await appService.getInterviewStatus());
            };
            const retrieveLocationInfo = async () => {
                setLocationInfo(await appService.getLocationInfos());
            };
            retrieveConsultants();
            retrieveFolderStructure();
            retrieveAlks();
            retrieveQualifications();
            retrieveNations();
            retrieveResidentPermits();
            retrieveAlvStatus();
            retrieveStrategies();
            retrieveEmployees();
            retrieveEmployeeFunctions();
            retrieveOfficeLocations();
            retrieveConsultantDepartments();
            retrieveConsultantLocations();
            retrieveConsultantFunctions();
            retrieveAdditionalInfo();
            retrieveEtlSolutions();
            retrieveTerminations();
            retrievInterviewStatus();
            retrieveLocationInfo();
        }
        catch {

        }
    };

    React.useEffect(() => {
        if (templateDriveId !== undefined)
            fetchData()
    }, [templateDriveId]);

    function checkDate(val) {
        if (val === null || val === undefined)
            return "";

        const d = new Date(val);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        let year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return day + "." + month + "." + year;
    }

    function checkValue(val) {
        try {
            if (val === undefined || val === "")
                return "";

            return val;
        } catch (e) {
            console.log('checkValue error');
            return "error";
        }
    }

    function checkValueAndLength(val, length: number) {
        try {
            if (val === undefined || val === "")
                return "";
                /* - this is not wanted - Natalie - INC 623
                - Marti wanted this, but this did not solve the issue with text not written to doc in some cases
                - removed Dirk - 19.04.2023
            if (val.length > length) {
                let res = val.substring(0,length)+"...";
                return res;
            }
            */
            return val;
        } catch (e) {
            console.log('checkValueAndLength error');
            return "error";
        }
    }

    const copyTemplates = async () => {
        setIsPrinting(true);
        //const mailRootFolder = await graphService.getFolderIdByDrivePath(mailMergeDriveId, "/root")

        await Promise.all(selectedRows.map(async client => {
            try {
                const d = new Date(Date.now());
                let month = '' + (d.getMonth() + 1);
                let day = '' + d.getDate();
                let year = d.getFullYear();

                const template = fileOptions.find(f => f.key === selectedTemplate);
                const fileName = `${(day.length < 2 ? "0" + day : day) + "_" + (month.length < 2 ? "0" + month : month) + "_" + year + "_" + client?.lastName?.toString() + "_" + client?.firstName?.toString() + "_" + (template?.text?.replace(".docx", ""))}.docx`
                let targetFolder = undefined;
                /*
                try {
                    let path = `/root:/${client?.sharePointFolder}${selectedFolder}`;
                    targetFolder = await graphService.getFolderIdByDrivePath(clientDriveId, path);
                }
                catch {
                    targetFolder = await graphService.getFolderIdByDrivePath(clientDriveId, `/root:/${client?.sharePointFolder}`);
                }
                let itemId = await graphService.copyTemplateToFolder(templateDriveId, selectedTemplate, targetFolder.parentReference.driveId, targetFolder.id, fileName);
                */
                let path = `/root:/${client?.sharePointFolder}${selectedFolder}`;
                try {
                    targetFolder = await graphService.getFolderIdByDrivePath(clientDriveId, path);
                }
                catch {
                    path = `/root:/${client?.sharePointFolder}`;
                    targetFolder = await graphService.getFolderIdByDrivePath(clientDriveId, path);
                }
                path = `/drives/${clientDriveId}/items${path}`;

                console.log(path);
                //let itemId = await graphService.copyTemplateToFolder(templateDriveId, ammTemplate.id, targetFolder.parentReference.driveId, targetFolder.id, fileName);

//console.log('copy template' + ` ${templateDriveId} ${selectedTemplate} ${path} ${fileName}`);
                let itemId = await graphService.copyTemplateToFolderByTargetPath(templateDriveId, selectedTemplate, path, fileName)
console.log('template copied');
                const myAlk = alks.find(a => a.key === client?.alkId);

                const quali = qualifications.find(q => q.key === client?.qualification);
                const nation = nations.find(n => n.key === client?.nationality);
                const residentsPermit = residentPermits.find(r => r.key === client?.residencePermit);
                const alvStat = alvStatus.find(r => r.key === client?.exit?.alvStatus);
                const strategy = strategies.find(r => r.key === client?.exit?.strategy);
                const additionalInf = additionalInfo.find(r => r.key === client?.entry?.additionalInfoId)
                const etlSolution = etlSolutions.find(r => r.key === client?.exit?.etl)
                const termination = terminations.find(r => r.key === client?.amm?.terminationId)

                const consultant = consultants.find(e => e.key === client?.consultantId);
                const consultantLocation = consultantLocations.find(e => e.key === consultant?.locationId);
                const consultantFunction = consultantFunctions.find(e => e.key === consultant?.functionId);
                const consultantDepartment = consultantDepartments.find(e => e.key === consultant?.departmentId)

                const employeeVG = employees.find(e => e.key === client?.interview?.employeeId);
                const employeeVGFunction = employeeFunctions.find(e => e.key === employeeVG?.functionId);
                const employeeVGLocation = officeLocations.find(e => e.key === employeeVG?.officeLocationId);
                const employeeFA = employees.find(e => e.key === client?.entry?.employeeId)
                const employeeFAFunction = employeeFunctions.find(e => e.key === employeeFA?.functionId);
                const employeeFALocation = officeLocations.find(e => e.key === employeeFA?.officeLocationId);

                const interviewSt = interviewStatus.find(e => e.key === client?.interview?.statusId)

                const location = locationInfo.find(e => e.key === client?.entry?.locationId)
console.log('params read');

                let metadata = {
                    "tn_anrede": checkValue(client?.title),
                    "tn_erlernter_beruf": checkValue(client?.jobLearned),
                    "tn_mail": checkValue(client?.email),
                    "tn_mobil": checkValue(client?.mobile),
                    "tn_nachname": checkValue(client?.lastName),
                    "tn_nationalitaet": checkValue(nation?.title),
                    "tn_pensum_in_prozent": checkValue(client?.pensum),
                    "tn_personennummer": checkValue(client?.personId),
                    "tn_plz": checkValue(client?.zip),
                    "tn_sozialversicherungsnummer": checkValue(client?.socialSecurityNumber),
                    "tn_startzeit": checkValue(client?.entry?.starttime),
                    "tn_tag": checkValue(client?.entry?.day),
                    "tn_strasse_nr": checkValue(client?.street),
                    "tn_festnetz": checkValue(client?.phone),
                    "tn_vorname": checkValue(client?.firstName),
                    "tn_wohnort": checkValue(client?.city),
                    "tn_zuletzt_ausgeuebter_beruf": checkValue(client?.lastJob),

                    "tn_aufenthaltsbewilligung": checkValue(residentsPermit?.title),
                    "tn_qualifizierung": checkValue(quali?.title),

                    "tn_alk_name": checkValue(myAlk?.title),
                    "tn_alk_nummer": checkValue(myAlk?.alkNumber),
                    "tn_alk_plz": checkValue(myAlk?.zip),
                    "tn_alk_ort": checkValue(myAlk?.city),
                    "tn_alk_haupt_tel": checkValue(myAlk?.phone),
                    "tn_alk_info_mail": checkValue(myAlk?.email),

                    "tn_anzahl_arbeitsbemuehungen": checkValue(client?.entry?.trials),
                    "tn_abbruch_durch_wen": checkValue(termination?.title),
                    "tn_alv_status_bei_austritt": checkValue(alvStat?.title),
                    "tn_einsatzdauer": checkValue(client?.exit?.executionTime),
                    "tn_erfolgsstrategie": checkValue(strategy?.title),
                    "tn_funktion_in_neuer_arbeitsstelle": checkValue(client?.exit?.employer?.function),
                    "tn_kontaktperson_firma": checkValue(client?.exit?.employer?.contact),
                    "tn_loesungsdaten_etl": checkValue(etlSolution?.title),
                    "tn_name_neuer_arbeitgeber": checkValue(client?.exit?.employer?.name),
                    "tn_status_nach_vg": checkValue(interviewSt?.title),
                    "tn_wie_weiter_nach_vg": checkValueAndLength(client?.interview?.nextSteps,200),
                    "tn_zusatzinfos": checkValue(additionalInf?.title),
                    "tn_standortinfos": checkValue(location?.title),
                    "tn_status": client?.amm?.status === true ? "Ja" : "Nein",
                    "ma_fallfuehrung": checkValue(employeeFA === null || employeeFA === undefined ? "" : employeeFA?.name + " " + employeeFA?.lastName),
                    "ma_fallfuehrung_name": checkValue(employeeFA?.lastName),
                    "ma_fallfuehrung_vorname": checkValue(employeeFA?.name),
                    "ma_fallfuehrung_mail_intern": checkValue(employeeFA?.email),
                    "ma_fallfuehrung_tel_intern": checkValue(employeeFA?.phone),
                    "ma_fallfuehrung_funktion": checkValue(employeeFAFunction?.title),
                    "ma_fallfuehrung_org_name": checkValue(employeeFALocation?.title),
                    "ma_fallfuehrung_org_duf_nr": checkValue(employeeFALocation?.dufNumber),
                    "ma_fallfuehrung_org_strasse": checkValue(employeeFALocation?.street),
                    "ma_fallfuehrung_org_plz": checkValue(employeeFALocation?.zip),
                    "ma_fallfuehrung_org_ort": checkValue(employeeFALocation?.city),
                    "ma_fallfuehrung_org_haupt_tel": checkValue(employeeFALocation?.phone),
                    "ma_fallfuehrung_org_info_mail": checkValue(employeeFALocation?.email),
                    "ma_vorgespraech": checkValue(employeeVG === null || employeeVG === undefined ? "" : employeeVG?.name + " " + employeeVG?.lastName),
                    "ma_vorgespraech_name": checkValue(employeeVG?.lastName),
                    "ma_vorgespraech_vorname": checkValue(employeeVG?.name),
                    "ma_vorgespraech_mail_intern": checkValue(employeeVG?.email),
                    "ma_vorgespraech_tel_intern": checkValue(employeeVG?.phone),
                    "ma_vorgespraech_funktion": checkValue(employeeVGFunction?.title),
                    "ma_vorgespraech_org_name": checkValue(employeeVGLocation?.title),
                    "ma_vorgespraech_org_duf_nr": checkValue(employeeVGLocation?.dufNumber),
                    "ma_vorgespraech_org_strasse": checkValue(employeeVGLocation?.street),
                    "ma_vorgespraech_org_plz": checkValue(employeeVGLocation?.zip),
                    "ma_vorgespraech_org_ort": checkValue(employeeVGLocation?.city),
                    "ma_vorgespraech_org_haupt_tel": checkValue(employeeVGLocation?.phone),
                    "ma_vorgespraech_org_info_mail": checkValue(employeeVGLocation?.email),
                    "tn_vorgespraechsdatum": checkDate(client?.interview?.date),
                    "tn_vorgespraechsdatum_startzeit": checkValue(client?.interview?.starttime),
                    "tn_start_rahmenfrist": checkDate(client?.startPeriod),
                    "tn_ende_rahmenfrist": checkDate(client?.endPeriod),
                    "tn_austritt": checkDate(client?.exit?.date),
                    "tn_eintritt": checkDate(client?.entry?.date),
                    "tn_geburtsdatum": checkDate(client?.birthDay),
                    "tn_1_arbeitstag": checkDate(client?.exit?.firstWorkingDay),

                    "zu_beratende": checkValue(consultant === null || consultant === undefined ? "" : consultant?.name + " " + consultant?.lastName),
                    "zu_vorname": checkValue(consultant?.name),
                    "zu_nachname": checkValue(consultant?.lastName),
                    "zu_tel": checkValue(consultant?.phone),
                    "zu_mail": checkValue(consultant?.email),
                    "zu_standortbezeichnung": checkValue(consultantLocation?.title),
                    "zu_plz": checkValue(consultantLocation?.zip),
                    "zu_ort": checkValue(consultantLocation?.city),
                    "zu_haupt_tel_nr": checkValue(consultantLocation?.phone),
                    "zu_standort_infomail": checkValue(consultantLocation?.email),
                    "zu_funktion": checkValue(consultantFunction?.title),
                    "zu_abteilung": checkValue(consultantDepartment?.title),
                    "tn_ziel_1": checkValueAndLength(client?.entry?.objective1,200),
                    "tn_ziel_2": checkValueAndLength(client?.entry?.objective2,200),
                    "tn_ziel_3": checkValueAndLength(client?.entry?.objective3,200),
                    "tn_rueckmeldung_zum_vg_an_pb": checkValueAndLength(client?.interview?.feedback,200),
                    "tn_bemerkung_austrittsmeldung": checkValueAndLength(client?.exit?.employer?.remarks,200),
                    "tn_bemerkung_ammbescheinigung": checkValueAndLength(client?.amm?.remarks,200),
                    "tn_zusatzbemerkung_erfolgsstrategie": checkValue(client?.exit?.intermediaryInterview),
                    "tn_individuelle_zielsetzung": checkValue(client?.entry?.objectiveIndividual),
                    "tn_adresse_firma": checkValue(client?.exit?.employer?.address)
                }

                /*
                await graphService.updateMetadata(targetFolder.parentReference.driveId, itemId, metadata);
                await graphService.copyTemplateToFolder(targetFolder.parentReference.driveId, itemId, mailMergeDriveId, mailRootFolder.id, fileName);
                */
               
                console.log('metadata:' + ` ${targetFolder.parentReference.driveId} ${itemId} ${JSON.stringify(metadata)}`);
                await graphService.updateMetadata(targetFolder.parentReference.driveId, itemId, metadata);
                //console.log('metadata updated');
                let targetCopyFilePath = `/drives/${mailMergeDriveId}/:root:/`;
                await graphService.copyTemplateToFolderByTargetPath(targetFolder.parentReference.driveId, itemId, targetCopyFilePath, fileName);
                //console.log('template copied' + ` ${targetCopyFilePath} ${itemId} ${targetFolder.parentReference.driveId} ${fileName}`);
                
            }
            catch (ex) {
                console.log(ex);
                alert(t("client:tabDocuments:failedPrefix") + ` ${client?.firstName} ${client?.lastName} ` + t("client:tabDocuments:failedSuffix"))
            }
        }))
        alert(t("client:tabDocuments:printDone"))
        setIsPrinting(false);
    }
    const categoryOptions = [{ key: "", text: t("client:tabDocuments:selectCategory") }, ...categories.map((item) => {
        return {
            key: item.id,
            text: item.name
        };
    })];
    const fileOptions = [{ key: "", text: t("client:tabDocuments:selectTemplate") }, ...templates.map((item) => {
        return {
            key: item.id,
            text: item.name
        };
    })];
    const flattenArray = (a: any, pathPrefix = '') => {
        if (a === null || a === undefined)
            return []
        return a.reduce(function (flattened, { displayName, children }) {
            let path = pathPrefix + "/" + displayName;
            return flattened
                .concat([{ path }]).concat(children ? flattenArray(children, path) : []);
        }, []);
    }
    const folderOptions = [
        {
            path: "/"
        },
        ...flattenArray(folderStructure?.children, '')]?.map((item) => {
            return {
                key: item.path,
                text: item.path
            };
        });

    if (isPrinting === true)
        return <Loading isLoading={true}></Loading>

    return (
        <div className="section defaultSection">
            <h1 className="title is-3">{title}</h1>
            <hr />
            <br />
            {selectedRows.length === 0 ? (
                <React.Fragment>
                    Bitte Klienten auswählen<br />
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <button type="button" className="button is-link is-small is-fullwidth" disabled={selectedRows.length === 0 || selectedTemplate === ""} title={t("client:tabDocuments:mergeAndPrint")} onClick={(e) => { e.preventDefault(); copyTemplates(); }}>Druck</button>
                    <br />
                    <div className="columns">
                        <div className="column">
                            <h1 className="title is-4">Ausgewählte Klienten</h1>
                            <ul>
                                {selectedRows.map((client) => {
                                    return <li key={client.key}>{`${client.firstName} ${client.lastName}`}&nbsp;<button
                                    className="button is-danger is-link is-small"
                                    title="Löschen"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setSelectedRows([...selectedRows.filter(row => row.key !== client.key)])
                                    }}
                                >
                                    <span className="icon">
                                        <i className={`fas fa-trash`} />
                                    </span>
                                </button></li>
                                })}
                            </ul>
                        </div>
                        <div className="column">
                            <Select name="category" label={t("client:tabDocuments:category")} options={categoryOptions} onChange={(e) => { setSelectedCategory(e.target.value); retrieveTemplates(e.target.value) }} value={selectedCategory}></Select>
                            <Select disabled={selectedCategory === ""} name="vorlagen" label={t("client:tabDocuments:template")} options={fileOptions} onChange={(e) => { setSelectedTemplate(e.target.value) }} value={selectedTemplate}></Select>
                            <Select name="ordner" label={t("client:tabDocuments:folder")} options={folderOptions} onChange={(e) => { setSelectedFolder(e.target.value) }} value={selectedFolder}></Select>
                        </div>
                    </div>
                    <button className="button is-link is-small is-fullwidth" disabled={selectedRows.length === 0 || selectedTemplate === ""} title={t("client:tabDocuments:mergeAndPrint")} onClick={(e) => { e.preventDefault(); copyTemplates(); }}>Druck</button>
                    <br />
                    <button className="button is-link is-small is-fullwidth" disabled={selectedRows.length === 0} onClick={(e) => { e.preventDefault(); setSelectedRows([]) }}>Auswahl neu starten</button>
                </React.Fragment>
            )}
            <br />
            <hr />
            <br /><br />
            {props.children}
        </div>
    )

};
