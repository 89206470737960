import React from "react";
import { Field, Formik, FormikHelpers } from "formik";
import { Input } from "../../fields/Input";
import appService from "../../../services/app.service";
import { Loading } from "../../Loading";
import * as Yup from "yup";

import { useHistory } from "react-router-dom";
import { IOfficeLocation, IEmployee } from "../../../core/cm.types";
import { LocationAwareButton } from "../../LocationAwareButton";
import { useTranslation } from "react-i18next";
import { Checkbox } from "../../fields/Checkbox";
import graphService from "../../../services/graph.service";
import { NoAdminAlert } from "../../NoAdminAlert"
import MaskedInput from "react-text-mask";
import { PhoneField } from "../../fields/PhoneField"

const validationSchema = Yup.object().shape({
    title: Yup.string().required("*"),
    dufNumber: Yup.number(),
    email: Yup.string().email("Bitte eine gültige E-Mail Addresse angeben")
});


export interface IListDetails {
    key: string;
    name: string;
    ismember: boolean;
    isdataadmin: boolean;
}

export const OfficelocationsDetails: React.FunctionComponent<{
    id: string | undefined;
    isAdmin: boolean;
}> = (props) => {
    const { t } = useTranslation();
    const { id, isAdmin } = props;
    const backButton = "/config/officelocations"
    const isNew = id === undefined || id === null;
    const title = <h1 className="title is-3">{t("client:headers:tabOfficeLocation")}</h1>
    const history = useHistory();

    const [data, setData] = React.useState<{
        isLoading: boolean;
        location: IOfficeLocation;
        employees: IEmployee[];
        listdetails: IListDetails[];
    }>({
        isLoading: true, employees: [], location: {}, listdetails: []
    });

    const onSubmit = (
        values: Readonly<IOfficeLocation | undefined>,
        formikHelpers: FormikHelpers<Readonly<IOfficeLocation>>
    ) => {

        let members = "";
        let dataAdmins = "";
        for (var i = 0; i < data?.listdetails?.length; i++) {
            if (data.listdetails[i]?.ismember === true) {
                members += data.listdetails[i]?.key + ";";
                if (data.listdetails[i]?.isdataadmin) { dataAdmins += data.listdetails[i]?.key + ";"; }
            }
        }

        const loc = {
            key: values.key,
            path: values.path,
            title: values.title,
            dufNumber: values.dufNumber,
            street: values.street,
            zip: values.zip,
            city: values.city,
            phone: values.phone,
            color: values.color,
            email: values.email,
            groupId: values.groupId,
            mailMergeDriveId: values.mailMergeDriveId,
            templateDriveId: values.templateDriveId,
            clientDriveId: values.clientDriveId,
            members: members,
            dataadmins: dataAdmins
        } as IOfficeLocation;

        const submit = async () => {
            if (data !== undefined && data !== null) {
                appService.saveOfficeLocation(loc).then((modulesResults) => {
                    var location = appService.getLocation();
                    history.push(`/${location}${backButton}`)
                }).catch(() => {
                    alert(t("client:common:errorOnSave"));
                });
            }
        };

        submit();
    };


    const fetchData = async () => {
        setData({ isLoading: true, employees: [], location: {}, listdetails: [] })
        try {
            const location = await appService.getOfficeLocation(id);
            const employees = await appService.getEmployees();
            let listdetails = [] as IListDetails[];

            for (var i = 0; i < employees.length; i++) {
                const det = {
                    key: employees[i]?.key,
                    name: employees[i]?.name + " " + employees[i]?.lastName,
                    ismember: location?.members?.includes(employees[i]?.key),
                    isdataadmin: location?.dataadmins?.includes(employees[i]?.key)
                } as IListDetails;
                listdetails.push(det);
            }
            setData({ isLoading: false, location: location, employees: employees, listdetails: listdetails });
        }

        catch {
            setData({ isLoading: true, employees: [], location: {}, listdetails: [] });
        }

    };

    React.useEffect(() => {
        if (isNew === false)
            fetchData()
        else {
            setData({ isLoading: false, employees: [], location: {}, listdetails: [] });
        }
    }, []);

    const setMember = (key, values, event) => {
        let dat = data;
        //
        dat?.listdetails?.map(item => {
            if (item.key === key) {
                item.ismember = event?.target?.checked;
                item.isdataadmin = (event?.target?.checked && item.isdataadmin);
                setData(dat);
            }
        })
    }

    const lookup = async (groupId: string, setFieldValue) => {
        try {
            var drives = await graphService.getGroupDrives(groupId)
            for (let i = 0; i < drives.length; i++) {
                let drive = drives[i];

                if (drive.webUrl.indexOf("CMClients") > -1) {
                    setFieldValue("clientDriveId", drive.id);

                } else if (drive.webUrl.indexOf("CMTemplates") > -1) {
                    setFieldValue("templateDriveId", drive.id);
                }
                else if (drive.webUrl.indexOf("CMMailMerge") > -1) {
                    setFieldValue("mailMergeDriveId", drive.id);
                }
            }
        }
        catch (ex) {
            console.log(ex);
            alert("Konnte das Team nicht finden");
        }
    }


    const setDataAdmin = (key, values, event) => {
        let dat = data;

        dat?.listdetails?.map(item => {
            if (item.key === key) {
                item.isdataadmin = (event?.target?.checked);
                setData(dat);
            }
        })
    }

    const phoneNumberMask = [
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
    ];

    const plzNumberMask = [
        /\d/,
        /\d/,
        /\d/,
        /\d/
    ];

    if (isAdmin !== true) { return <NoAdminAlert /> }

    return (
        <React.Fragment>
            <div className="section defaultSection">
                <Loading isLoading={data.isLoading}></Loading>
                {data.isLoading === false && (
                    <Formik
                        initialValues={data.location}
                        onSubmit={onSubmit}
                        validateOnMount={true}
                        validationSchema={validationSchema}
                        enableReinitialize
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            isValid,
                            dirty,
                            setFieldValue
                        }) => (
                            <React.Fragment>
                                <form onSubmit={handleSubmit} className="form">
                                    <div className="columns">
                                        <div className="column">{title}</div>
                                        <div className="column button-header">
                                            <button
                                                type="submit"
                                                className={`button is-link is-small is-pulled-right backAndSaveButton ${isSubmitting ? t("client:common:isLoading") : ""
                                                    }`}
                                                disabled={isSubmitting || !dirty || !isValid}
                                            >
                                                {t("client:common:saveButton")}
                                            </button>
                                            <LocationAwareButton title={t("client:common:backButton")} className="button is-small is-pulled-right backAndSaveButton" relativeLocation={backButton}></LocationAwareButton>
                                        </div>
                                    </div>
                                    <Field
                                        name="title"
                                        label={(t("client:common:columnTitle"))}
                                        component={Input}
                                    />
                                    <Field
                                        name="dufNumber"
                                        type="number"
                                        label={(t("client:tabOfficeLocation:dufNumber"))}
                                        component={Input}
                                    />
                                    <Field
                                        name="street"
                                        label={(t("client:config:columnStreet"))}
                                        component={Input}
                                    />
                                    <div className="columns">
                                        <div className="field column">
                                            <label htmlFor="zip" className="label" style={{ display: "block" }}>
                                                {t("client:config:columnPLZ")}
                                            </label>
                                            <Field
                                                name="zip"
                                                label={t("client:config:columnPLZ")}
                                                component={Input}
                                                render={({ field }) => (
                                                    <MaskedInput
                                                        {...field}
                                                        mask={plzNumberMask}
                                                        id="zip"
                                                        type="text"
                                                        className="input"
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className="column">
                                            <Field
                                                name="city"
                                                label={(t("client:config:columnOrt"))}
                                                component={Input}
                                            />
                                        </div>
                                    </div>
                                    <PhoneField name="phone" label={t("client:config:columnPhone")} placeholder={t("client:config:columnPhonePlaceholder")} />
                                    <Field
                                        name="email"
                                        label={(t("client:config:columnEmail"))}
                                        component={Input}
                                        suffixControl={
                                            <div className="control">
                                                <a className="button" href={`mailto:${values.email}`}>
                                                    <i className={`fas fa-envelope`} /></a>
                                            </div>
                                        }
                                    />
                                    <Field
                                        name="allowanceFactor"
                                        type="number"
                                        label={(t("client:config:columnAllowanceFactor"))}
                                        component={Input}
                                    />

                                    <Field
                                        name="groupId"
                                        label={(t("client:config:columnGroupId"))}
                                        onBlur={(e) => { lookup(values.groupId, setFieldValue); }}
                                        component={Input}
                                    />
                                    <Field
                                        name="path"
                                        label="Pfad"
                                        component={Input}
                                    />
                                    <Field
                                        name="color"
                                        label="Farbe"
                                        component={Input}
                                    />
                                    <div className="columns">
                                        <div className="column">
                                            <h3 className="label">{t("client:tabOfficeLocation:emplOfLocation")}</h3>
                                            {data.listdetails?.length > 0 && (
                                                <table className="tableEmployees">
                                                    <tbody>
                                                        {data.listdetails.map((item: IListDetails, index: number) => (
                                                            <tr key={index}>
                                                                <td>&nbsp;</td>
                                                                <td className="tableEmployees">
                                                                    <Field
                                                                        name={"locationMember" + item?.key}
                                                                        checked={item?.ismember}
                                                                        component={Checkbox}
                                                                        onChange={(e) => {
                                                                            setMember(item?.key, values, e)
                                                                            handleChange(e)
                                                                        }}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    {item?.name}
                                                                </td>
                                                                <td>
                                                                    &nbsp;&nbsp;&nbsp;
                                                                </td>
                                                                <td>
                                                                    <React.Fragment>
                                                                        {(item?.ismember && (
                                                                            <Field
                                                                                name={"stammdatenadmin" + item?.key}
                                                                                checked={item?.isdataadmin}
                                                                                label={t("client:tabOfficeLocation:stammdatenadmin")}
                                                                                component={Checkbox}
                                                                                onChange={(e) => {
                                                                                    setDataAdmin(item?.key, values, e)
                                                                                    handleChange(e)
                                                                                }} />
                                                                        ))}
                                                                    </React.Fragment>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            )}
                                        </div>
                                    </div>

                                    <div className="button-footer">
                                        <button
                                            type="submit"
                                            className={`button is-link is-small is-pulled-right backAndSaveButton${isSubmitting ? t("client:common:isLoading") : ""
                                                }`}
                                            disabled={isSubmitting || !dirty || !isValid}
                                        >
                                            {t("client:common:saveButton")}
                                        </button>
                                        <LocationAwareButton title={t("client:common:backButton")} className="button is-small is-pulled-right backAndSaveButton" relativeLocation={backButton}></LocationAwareButton>
                                    </div>
                                </form>
                            </React.Fragment>
                        )}
                    </Formik>
                )}
            </div>
        </React.Fragment >
    );
};
