import React from "react";
import appService from "../../../services/app.service";
import { DetailsOverview } from "../base/DetailsOverview";
import { useTranslation } from "react-i18next";
import { NoDataAdminAlert } from "../../NoDataAdminAlert"

export const LocationInfoOverview: React.FunctionComponent<{ isDataAdmin: boolean; }> = (props) => {
    const detailsRedirection = "/config/locationinfo/details";
    const { t } = useTranslation();
    const title = t("client:headers:tabLocationInfo");
    const { isDataAdmin } = props;

    if (isDataAdmin !== true) {
        return <NoDataAdminAlert />
    }

    return (
        <DetailsOverview detailsRedirection={detailsRedirection} getItems={appService.getLocationInfos} hasNumber={true} entityType="locationinfo" title={title}></DetailsOverview>
    );
};