import React from "react";
import { Field } from "formik";
import { Textarea } from "../fields/Textarea";

import { Select } from "../fields/Select";
import { IBaseDetailsItem, IClient } from "../../core/cm.types";
import { DateField } from "../fields/DateField";
import 'react-tabs/style/react-tabs.css';
import { useTranslation } from "react-i18next";

export const AMMData: React.FunctionComponent<{ termination: IBaseDetailsItem[], isReadOnly: boolean, client: IClient }> = (props) => {
    const { t } = useTranslation();
    const { isReadOnly, client, termination } = props;

    const statusOption = [{ key: "", text: t("client:tabAMM:statusOptions") }, ...termination?.map((item) => {
        return {
            key: item.key,
            text: item.title
        };
    })];

    return (
        <React.Fragment>
            <Field
                name="entry.date"
                disabled={isReadOnly}
                label={t("client:tabAMM:entryDate")}
                dateFormat="dd.MM.yyyy"
                selected={new Date(client?.entry?.date)}
                component={DateField}
            />
            <Field
                name="exit.date"
                disabled={isReadOnly}
                label={t("client:tabAMM:exitDate")}
                dateFormat="dd.MM.yyyy"
                selected={new Date(client?.exit?.date)}
                component={DateField}
            />
            <Field
                name="amm.terminationId"
                label={t("client:tabAMM:abandonment")}
                disabled={isReadOnly}
                options={statusOption}
                component={Select}
            />
            <Field
                name="amm.remarks"
                label={t("client:tabAMM:remarks")}
                disabled={isReadOnly}
                component={Textarea}
            />
        </React.Fragment>
    );
};
